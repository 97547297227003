import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import Header from "../Header"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const BlogsContentWriting=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Insu Creation: Affordable & Engaging Video Creation Services for Boosting E-commerce Sales</title>
    <meta name="description" content="Elevate your online presence with Insu Creation's SEO-optimized blog content services. Our expert writers craft engaging, high-quality posts customized to your brand, driving traffic and boosting visibility. Maximize your online impact with Insu Creation today."/>
    <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Insu Creation: Amplify Your Online Presence with Our SEO-Optimized Blog Content Writing Services</h1>
    <p className="transform-para transform-2-para">Unlock the full potential of your online visibility through Insu Creation's specialized SEO-optimized blog content writing services. Our team of expert writers is dedicated to crafting engaging, high-quality content designed to drive traffic and enhance your online presence.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">SEO-Optimized Content: Drive organic traffic with content optimized for search engines.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Engaging and High-Quality Writing: Captivate your audience with compelling and top-notch content.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Customized to Fit Your Brand: Tailored solutions that reflect and reinforce your brand identity.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Consistently Drive Traffic: Attract a steady stream of visitors to your website with our content strategies.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Boosts Online Visibility: Enhance your visibility and stand out in the digital landscape with our blog writing services.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706245299/mq0uge3dv0itexeu2lxi.gif" className="seo-route-img-main social-media-route-img-main video-ads-cont-img blog-media-content-img-mob artile-write-img-vv" alt="seo-route-img-main" />
 </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default BlogsContentWriting