import React from "react"
import { Helmet } from "react-helmet"
import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import AboutUsDetails from "../AboutUsDetails"
import WhatWeDo from "../WhatWeDo"  
import OurVisionAndMission from "../OurVisionAndMission"
import Footer from "../Footer"
import "./index.css"

const AboutUs=()=>{
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    document.title="Insu Creation: Where Digital Dreams Take Flight";
return(
     <>
<Helmet>
<title>Insu Creation: Where Digital Dreams Take Flight</title>
<meta name="description" content="Embark on a journey with Insu Creation, where your digital dreams take flight. We are a passionate team of innovative designers and developers, crafting user-centric websites and mobile apps using cutting-edge technologies. Let us be your partner on the path to digital success." />
<meta name="keywords" content="Insu Creation, Innovative Designers, Developers, User-Centric, Websites, Mobile Apps, Cutting-Edge Technologies, Digital Success, Creative Team, Passionate Professionals, Digital Innovation" />

</Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <AboutUsDetails />
    <WhatWeDo />
    <OurVisionAndMission />
    <Footer />
    </>
)}
export default AboutUs