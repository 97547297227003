import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import Header from "../Header"
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const GoogleAnalytics=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Insu Creation: Expert Solutions for Effective Performance Optimization</title>
    <meta name="Elevate your Insu Creation strategy with our expert solutions for effective performance optimization. Unlock growth potential and stay ahead in your industry with our tailored services."/>


    <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Insu Creation Unleashed: Harness the Power of Google Analytics</h1>
    <p className="transform-para transform-2-para">Gain unparalleled insights into your Insu Creation business with our expert Google Analytics service. Track user behavior, analyze trends, and optimize strategies for sustained growth and competitive advantage in your industry.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Understand user behavior and trends with Google Analytics for Insu Creation.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Identify strengths and areas for improvement to refine your strategy.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Customized tracking and reporting solutions designed specifically for Insu Creation businesses.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Gain a competitive edge by making data-driven decisions and staying ahead of the   curve.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Track progress over time and adapt your approach for sustained growth and success.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"> <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705999016/xsuvnsxebfsmkj8gezpf.gif" className="seo-route-img-main social-media-route-img-main google-analytics-image" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="seo-services-we-provide-timeline-main">
      <h1 className="seo-services-we-provide-heading google-analytices-main-head">WE DELIVER IN-DETAIL ANALYTICS SERVICES CUSTOMIZED FOR YOUR BUSINESS TYPE</h1>
      <div className="seo-new-tools-list-container seo-new-tools-list-container-google-analytics">
      <div className="wordPress-new-tech-con seo-new-tools-list-container-item google-analytics-rote">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main google-analytics-rote">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705999974/fleuxadyoi63ub1mgjiz.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress google-analytics-img" alt="" />
  <div>
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head google-analytics-head">Setup Of Custom Monthly Reports Delivered Via Email</h1>
  </div>
    </div>
    </div>
    <div className="wordPress-new-tech-con seo-new-tools-list-container-item google-analytics-rote ">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main google-analytics-rote">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706000099/iejewplnu2zgjpw5f4xn.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress google-analytics-img" alt="" />
  <div>
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head google-analytics-head">Setup and Analysis of Conversion or Transaction Funnels</h1>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item google-analytics-rote">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main google-analytics-rote">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706000181/uuel7ft76a2isyu3udm4.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress google-analytics-img" alt="" />
  <div>
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head google-analytics-head">Setup of Customized Goals and Events</h1>
 </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item google-analytics-rote">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main google-analytics-rote">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706000275/foiescxmbcp1xq9razis.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress google-analytics-img" alt="" />
  <div>
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head google-analytics-head">Integration With Social Media, eg. Facebook, Twitter, Pinterest</h1>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item google-analytics-rote">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main google-analytics-rote">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706000377/uyg1velc0jnbltvcwosp.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress google-analytics-img" alt="" />
  <div>
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head google-analytics-head">Insights on User Location, or Geo-Profiling</h1>
  </div>
    </div>
    </div>

      </div>
    </div>
 <Footer />
    </>
)}
AOS.init()
export default GoogleAnalytics