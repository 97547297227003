import './indexContent.css'
import {Link} from "react-router-dom"
const Content = () => (
  <div className="content-container">
    <div className='content-head'>
      <h1 className="content-heading">Why should your business go online?</h1>
      <p className="content-paragraph">
      It's not just an option to climb the online ladder; it's a necessity. By having a strong online presence, you not only expand your market but also gain valuable insight into your customers. Our services include web and app development, graphic design, content writing, and digital marketing. Utilize the power of the internet to connect, engage, and transact with customers seamlessly. You can achieve boundless success in the digital age with Insu Creation.
      </p>
      <p className="content-heading two">In todays market, is it required-</p>
      <ul className="content-list-container">
        <li className="content-list-item">
          <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="content-item-paragraph">To beat the Competition.</p>
        </li>
        <li className="content-list-item">
          <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="content-item-paragraph">
            To Gain Leverage in the Market.
          </p>
        </li>
        <li className="content-list-item">
          <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="content-item-paragraph">
            To get exposure and expansion.
          </p>
        </li>
      </ul>
      <Link to="/contactus">
      <button type="button" className="view-portfolio-btn">
        Request a Quote
      </button>
      </Link>
    </div>
    <img
      src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706855261/yih2qdrtmnn0wvtmepys.png"
      className="webingo-content-img"
      alt="webingo-content-img"
    />
  </div>
)

export default Content