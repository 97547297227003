import { Link } from "react-router-dom"
import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import { Helmet } from "react-helmet"
import Navbar from "../Navbar"
import NavbarOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"

import "./styleportfolio.css"

const Portfolio = () => {
  const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' })

  return (
    <>
      <Helmet>
        <title>Insu Creation Portfolio: Explore Our Successful Projects and Solutions</title>
        <meta name="description" content="Discover the diverse and innovative projects showcased in InsuCreation's portfolio. From web development to digital marketing, our portfolio highlights our expertise and successful solutions. Explore our work and see how we can elevate your brand and business" />
        <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

      </Helmet>
      <Header />
      {mediaQueryCondition ? <Navbar /> : <NavbarOtherPage />}
      <DetailsForm />


      <div className="card-portfolio-main-container">
        <span className="our-locations-contact-us our-wk">OUR WORK</span>
        <h1 className="portfolio-head-rote pf-head-mob">PORTFOLIO</h1>
        <p className="portfolio-para-rote new-pf-mob">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>

        <div class="example">
          <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707828845/ewhzu9ssj1mvx1riu11j.jpg" width="100%" className="image-n" alt="house" />
          <div class="fadedbox">
            <div class="title text">
              <h1 className="portfolio-main-page-headings">Website Design</h1>
              <Link to="/webdesigns">
                <button type="button" className="portfolio-main-page-buttons">View More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="portfolio-second-row">
          <div class="example">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707804924/s7igh22adk85ri3dupsl.jpg" width="100%" height="auto" alt="house" />
            <div class="fadedbox fadedbox1">
              <div class="title text">
                <h1 className="portfolio-main-page-headings">Logo Design</h1>
                <Link to="/logodesigns">
                  <button type="button" className="portfolio-main-page-buttons">View More</button>
                </Link>
              </div>
            </div>
          </div>
          <div class="example">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708612351/m6sfvhawb77hufshzazm.jpg" width="100%" height="auto" alt="house" />
            <div class="fadedbox fadedbox1">
              <div class="title text">
                <h1 className="portfolio-main-page-headings">posters</h1>
                <Link to="/posterdesigns">
                  <button type="button" className="portfolio-main-page-buttons">View More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="example">
          <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707838460/wnjwi4weeefk3w7tol6x.jpg" className="image-n image-n-n" width="95%" height="auto" alt="house" />
          <div class="fadedbox">
            <div class="title text">
              <h1 className="portfolio-main-page-headings">SEO</h1>
              <Link to="/seodesigns">
                <button type="button" className="portfolio-main-page-buttons">View More</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="portfolio-second-row">
          <div class="example">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708612492/xoyss7m1wwbiyc4chci3.jpg" width="100%" height="auto" alt="house" />
            <div class="fadedbox fadedbox1">
              <div class="title text">
                <h1 className="portfolio-main-page-headings">Branding collaterals</h1>
                <Link to="/branding-collaterales">
                  <button type="button" className="portfolio-main-page-buttons">View More</button>
                </Link>
              </div>
            </div>
          </div>
          <div class="example">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707804924/s7igh22adk85ri3dupsl.jpg" width="100%" height="auto" alt="house" />
            <div class="fadedbox fadedbox1">
              <div class="title text">
                <h1 className="portfolio-main-page-headings">Product Packaging</h1>
                <Link to="/product-packaging-portfolio">
                  <button type="button" className="portfolio-main-page-buttons">View More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-second-row">
          <div class="example">
            <img src={require('../InsuImages/graphicdesign.jpg')} width="100%" height="auto" alt="house" />
            <div class="fadedbox fadedbox1">
              <div class="title text">
                <h1 className="portfolio-main-page-headings">Graphic Design</h1>
                <Link to="/graphic-portfolio">
                  <button type="button" className="portfolio-main-page-buttons">View More</button>
                </Link>
              </div>
            </div>
          </div>
          <div class="example">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708611669/mia6jiutsmavk1gduuv1.png" width="100%" alt="house" />
            <div class="fadedbox fadedbox1">
              <div class="title text">
                <h1 className="portfolio-main-page-headings">APP</h1>
                <Link to="/app-portfolio">
                  <button type="button" className="portfolio-main-page-buttons">View More</button>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="want-enquire-new-btn port-btn-con-c">
          <p className="want-enquire-new-btn-para port-btn-con">Want to <span className="want-enquire-new-btn-span">Start</span> a New Project</p>

          <Link to="/contactus"> <button type="button" className="new-want-btn new-want-btn-1">Enquire Now</button></Link>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Portfolio