import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import Header from "../Header"
import {Helmet} from "react-helmet"
import { Chrono } from "react-chrono";
import "./index.css"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer";

const items1 = [
  {
    title: "Step 1",
    cardDetailedText: "Initiate a discussion to delve into your project specifics, objectives, and aspirations.",
  },
  {
    title: "Step 2",
    cardDetailedText: "Select from a range of layout options, guided by our expertise to align with your insu creation goals.",
  },
  {
    title: "Step 3",
    cardDetailedText: "Customize your Shopify store to reflect the unique essence of your insu creation brand.",
  },
  {
    title: "Step 4",
    cardDetailedText: "Thorough testing guarantees flawless performance, ironing out any technical glitches.",
  },
  {
    title: "Step 5",
    cardDetailedText: "Prior to launch, review and approve the final site to ensure it aligns with your vision for insu creation.",
  },
  {
    title: "Step 6",
    cardDetailedText: "With your Shopify store optimized and ready, embark on your journey to success in insu creation, attracting and converting customers effortlessly!",
  }
];
const SpotifyDeveloper=()=>{
    useEffect(() => {
        AOS.init({
          
          duration : 2000
        });
      }, []);  
    
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    return(
        <>
        <Helmet>
        <title>InsuCreation: Expert Shopify Design, Customization, and Marketing Services</title>
<meta name="description" content="Transform your online presence with InsuCreation's comprehensive Shopify services. From design and customization to marketing and app development, our team of experts ensures your Shopify store stands out. Get professional support, seamless migration, and top-notch SEO solutions tailored to your needs. Elevate your e-commerce success with InsuCreation today!"/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
         <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div className="animation-route-top-con">
    <h1 className="transform-head transform-2-head" data-aos="fade-left">Your Trusted Insu Creation Shopify Developers</h1>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items" data-aos="fade-up">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para" >Strict Non-Disclosure Agreement</p>
        </div>
      </li>
      <li className="transform-right-list-container-items" data-aos="fade-up">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Agile Development and Outstanding Customer Support</p>
        </div>
      </li>
      <li className="transform-right-list-container-items" data-aos="fade-up">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Quick Onboarding and Flexible Working Hours</p>
        </div>
      </li>
      <li className="transform-right-list-container-items" data-aos="fade-up">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Secured, Reliable, and Quality Assurance Tested</p>
        </div>
      </li>
      <li className="transform-right-list-container-items" data-aos="fade-up">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Visually Appealing Solutions for Insu Creation with Revenue-Boosting Impact</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1712152697/yqgxmznim65fgjz8qjut.jpg" data-aos="zoom-in" className="seo-route-img-main business-pre-img business-pre-img-2 d-main-img-mob spoti-main-im" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="seo-services-we-provide-timeline-main">
  <div className="seo-services-we-provide-timeline">
  <h1 className="seo-services-we-provide-heading" data-aos="fade-left">Hire Innovative Shopify Designers and Marketers for insu creation</h1>
  <p className="seo-services-we-provide-paragraph chrono-above-para-mob" data-aos="fade-right">Our team excels in crafting bespoke Shopify solutions, ensuring optimal outcomes for your insu creation needs. From rapid, SEO-optimized storefronts to seamless user experiences, trust us to elevate your online presence.
</p>
 <Chrono
      items={items1}
      hideControls
      itemWidth={300}
      style={{ title: { background: 'blue' } }}
      mode="VERTICAL_ALTERNATING"
      fontSizes={{
        cardText:30,
        cardSubtitle:"1.5rem",
        title: '15px',

      }}

      theme={
        {
          titleColor: '#313979',
          titleColorActive: 'white',
          secondary:
              'linear-gradient(rgba(162, 96, 192, 0.5), rgba(55, 34, 146, 0.5))',
            primary: '#7f4a97',
        }
      }
    />
    </div>
    </div>
    <div className="spotify-developer-main-con">
      <div className="spotify-developer-main-con-1">
        <div className="spotify-developer-div-con">
          <h1 className="spotify-developer-main-con-1-heading" data-aos="fade-left">Shopify Solutions Tailored to Your Needs</h1>
          <span className="spotify-developer-main-con-1-para" data-aos="fade-right">A ​​one-stop agency for your Shopify needs.</span>
          <p className="spotify-developer-main-con-1-para-2" data-aos="fade-up">As your comprehensive Shopify partner, we ensure your e-commerce venture thrives. Our seasoned Shopify experts are dedicated to crafting high-converting online stores, seamlessly integrating with your brand vision.
</p>
        </div>
        <img src={require("../InsuImages/programmer_2.jpg")} data-aos="zoom-in" className="img-spotify-devel" alt="" />
      </div>
      <div className="spotify-div-con-developer">
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">Shopify Store Setup</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Leverage our expertise to establish a captivating online presence aligned with your business goals. Our adept team offers bespoke Shopify store development services, ensuring a seamless transition to the digital realm.</p>
        </div>
       
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">Custom Theme Design</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Elevate your storefront with unique aesthetics tailored to your brand identity. Our professional Shopify developers specialize in custom theme customization, delivering a visually stunning and user-friendly online experience.</p>
        </div>
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">Bug Fixing and Optimization</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Experience hassle-free performance with our meticulous bug-fixing solutions. Our Shopify experts adeptly resolve any glitches, ensuring optimal functionality and enhanced user experience for your store.</p>
        </div>
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">Migration and Integration</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Transition seamlessly to Shopify with our reliable migration and integration services. We facilitate smooth data transfer from your existing platform, preserving every crucial detail without disruption.</p>
        </div>
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">Support and Maintenance</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Rely on our dedicated support for uninterrupted Shopify operations. Our team provides proactive maintenance, ensuring your store remains robust, secure, and primed for growth.</p>
        </div>
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">SEO and Marketing</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Drive sustainable growth with our comprehensive SEO and marketing solutions. From optimizing your Shopify store for search engines to expertly managing digital marketing campaigns, we enhance your online visibility and drive conversions.
</p>
        </div>
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">App Development</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Unlock the full potential of Shopify with custom app development. Our agile methodology ensures efficient and cost-effective app creation tailored to your unique requirements.</p>
        </div>
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">Dynamic Product Metafields </h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Enhance product visibility and engagement with dynamic metafields. Our solution allows you to enrich product listings with custom fields, capturing essential details and captivating your audience effectively.</p>
        </div> 
        <div className="spotify-div-con-developer-inner" data-aos="fade-up">
          <div className="spotify-div-con-developer-inner-1">
            <h1 className="spotify-inner-heading-dev">PSD/XD/Figma to Shopify Conversion</h1>
          </div>
          <p className="spotify-inner-heading-dev-para">Transform your design concepts into functional Shopify stores seamlessly. Our expert team efficiently converts PSD, XD, or Figma designs into responsive Shopify themes, ensuring a pixel-perfect representation of your vision.</p>
        </div>
      </div>
      
    </div>
    <Footer />
        </>
    )
}

export default SpotifyDeveloper