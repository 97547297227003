import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import AOS from "aos"
import {Link} from "react-router-dom"
import Header from "../Header"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import TestimonialsContainer from "../TestimonalsContainer";
import ListOfTechNames from "../ListOfTechNames";

import Footer from "../Footer"

import "./index.css"

const WhyTrustAndChooseUs=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Insu Creation - Elevating Your Online Success with Expert Support</title>
    <meta name="description" content="Insu Creation offers comprehensive ongoing assistance to ensure your website's success, including SEO, social media marketing, and personalized support. Measure your progress with Google Analytics and prioritize profitability with our dedicated services." />

<meta name="keywords" content="Insu Creation, website support, SEO, social media marketing, Google Analytics, profitability, online success" />

       </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head why-trust-us-mobile-head">We're not chasing universal approval. Our goal is simply to earn your satisfaction.</h1>
    <p className="transform-para transform-2-para">At Insu Creation, our approachability paves the path to outstanding client experiences. With a blend of intelligence, talent, and our extensive experience, selecting us isn't just a choice—it's the ultimate choice.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para why-trust-us-mobile-item-right">Approachability</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para why-trust-us-mobile-item-right">Exceptional Client Experiences</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para why-trust-us-mobile-item-right">Intelligence</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para why-trust-us-mobile-item-right">Talent</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para why-trust-us-mobile-item-right">Extensive Experience</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    {mediaQueryCondition?<Link to="/contactus"><button type="button" className="new-want-btn new-want-btn-1 why-trust-request-a-quote-mob">Request a Quote</button></Link>: <Link to="contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>}
    <Link to="/schedule-meeting"> <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596298/hvzkwfdwwatliebkudht.gif" className="seo-route-img-main business-pre-img" alt="seo-route-img-main" />
 </div>
 </div>
 

 <div className="graphic-route-ty-head-main-container packaging-back-banner-img" data-aos="zoom-in">
    <div className="graphic-route-ty-head-main-container-1 graphic-route-ty-item-con-mobile">
    <span className="our-locations-contact-us our-wk web-work-w passionate-mob-head-col">WE DELIVER OUR BEST</span>
    <h1 className="whytrustandchooseus-head">Providing exceptional service with a personal touch is our goal at Insu Creation.</h1>
    <div className="graphic-route-ty-item-con graphic-route-ty-item-con-mobile">
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596561/wjds5pderfhdna21tqk0.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">We Listen</h1>
                <p>Your success with Insu Creation begins with attentive listening. By understanding your objectives, we tailor solutions to precisely meet your needs.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596656/bmeikdethjqnk6qkmlun.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">We Like to talk</h1>
                <p>Communication is paramount. Our tight-knit team ensures you're updated every step of the way, fostering trust and ensuring a smooth process.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596692/oeeeca1vowwn91fajihn.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">We’ve done it before</h1>
                <p>With over two decades of experience in web-related endeavors, Insu Creation boasts a proven track record. Trust our seasoned professionals to navigate challenges with creativity and expertise.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596726/t1gchwzrimr9dl6eqavl.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">We make it Easy</h1>
                <p>Complexity is our playground. From intricate web development to seamless software integrations, we simplify the process with clear communication and reliable execution. Insu Creation is your trusted partner for stress-free project management.</p>
            </div>
        </div>
    </div>
    </div>
 </div>


 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1 money-back-mobile-con">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706346666/y99oyzem0mtx7rjcenag.gif" className="react-native-mid-img social-media-content-img-n mobile-cost-back-img" alt="" />

    <div className="we-give-money-back-mobile">
        <h1 className="react-native-business-dev we-give-money-back-mobile-head">At Insu Creation, we ensure value for your money:</h1>
        <div className="get-what-you-pay-div">
            <h1 className="get-what-you-pay">1. Tailored Solutions</h1>
            <p className="get-what-you-pay-para">Our bespoke approach means you get precisely what you need without unnecessary add-ons. Whether it's custom-built or ready-made components, we prioritize your requirements.</p>
        </div>
        <div className="get-what-you-pay-div">
            <h1 className="get-what-you-pay">2. Transparent Pricing</h1>
            <p className="get-what-you-pay-para">With Insu Creation, expect clear costs and flexible payment options. Whether you prefer three installments or a 12-month plan, we're adaptable to your needs.</p>
        </div>
        <div className="get-what-you-pay-div">
            <h1 className="get-what-you-pay">3. Commitment to Delivery</h1>
            <p className="get-what-you-pay-para">Communication and transparency are paramount at Insu Creation. Count on us to fulfill our promises—we're dedicated to delivering on time, every time.</p>
        </div>
        <div className="get-what-you-pay-div">
            <h1 className="get-what-you-pay">4. Integrity First</h1>
            <p className="get-what-you-pay-para">Honesty and integrity are the foundation of our business. At Insu Creation, we're open and straightforward about our processes, ensuring you have complete peace of mind.</p>
        </div>
       
        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>


 <div className="graphic-process-bg" data-aos="zoom-in">
  <h1 className="portfolio-head-rote web-dev-how-we web-dev-how-we-2 why-trust-us-webing-mob">What makes Insu Creation an exceptional partner company?</h1>
  <p className="web-dev-how-we-2-para">Insu Creation: Leading with Excellence.</p>
  <div className="why-trust-and-choose-main-container">
    <div className="bulb-how-we-work-con why-trust-and-choose-container">
      <div className="qualified-reso-con">
        <div>
        <h1 className="qualified-resou-head">
        Unmatched Expertise
        </h1>
        <p className="qualified-resou-para">At Insu Creation, we excel in what we do best. With qualified resources boasting extensive knowledge and experience, we deliver projects that drive your success forward.</p>
        </div>
        <div>
        <h1 className="qualified-resou-head">
        Advanced Infrastructure
        </h1>
        <p className="qualified-resou-para">Elevate your business with our secure IT environment designed for peak performance. Our agile infrastructure, including cloud computing and outsourcing models, ensures seamless operations and continuous improvement.</p>
        </div>
      </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706599617/zxqledq2n2cmsdv9rnjh.png" alt="" className="why-choose-us-tr-img qualified-mob-img" />
    </div>
    <div className="bulb-how-we-work-con why-trust-and-choose-container why-trust-and-choose-container-2">
      <div className="qualified-reso-con">
        <div>
        <h1 className="qualified-resou-head">
        Quality Assurance
        </h1>
        <p className="qualified-resou-para">Upholding rigorous quality standards, we prioritize excellence across all processes and deliverables. Our commitment to long-term project success ensures solutions tailored precisely to your needs.</p>
        </div>
        <div>
        <h1 className="qualified-resou-head">
        Confidentiality and Security
        </h1>
        <p className="qualified-resou-para">Your sensitive information is safeguarded through our continuous compliance and security measures. At Insu Creation, we maintain strict confidentiality standards to protect your business interests.</p>
        </div>
      </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706599868/wi6ajcoyjcwvehve3j7a.png" alt="" className="why-choose-us-tr-img why-choose-us-tr-img-2 qualified-mob-img" />
    </div>
    <div className="bulb-how-we-work-con why-trust-and-choose-container">
      <div className="qualified-reso-con">
        <div>
        <h1 className="qualified-resou-head">
        Competitive Pricing
        </h1>
        <p className="qualified-resou-para">Benefit from affordable rates and flexible pricing models aligned with your business objectives. Our transparent pricing strategies ensure value for money without compromising quality.</p>
        </div>
        <div>
        <h1 className="qualified-resou-head">
        Comprehensive Consulting
        </h1>
        <p className="qualified-resou-para">From analysis to implementation, we offer end-to-end IT solutions tailored to your business needs. Our experienced team translates your requirements into effective IT systems, ensuring customer satisfaction.</p>
        </div>
      </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706600186/nvxn4h0ospdkajfrbky2.png" alt="" className="why-choose-us-tr-img qualified-mob-img" />
    </div>
    <div className="bulb-how-we-work-con why-trust-and-choose-container why-trust-and-choose-container-2">
      <div className="qualified-reso-con">
        <div>
        <h1 className="qualified-resou-head">
        Technical Excellence
        </h1>
        <p className="qualified-resou-para">Embrace innovation and business intelligence with our technology consulting and custom solutions. We seamlessly integrate technology to optimize your business processes and maximize performance.</p>
        </div>
        <div>
        <h1 className="qualified-resou-head">
        Flexible Development
        </h1>
        <p className="qualified-resou-para">Tailored to your requirements, our development methods result in high-quality software delivered on time and within budget. We adapt to your specific needs to ensure success.</p>
        </div>
      </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706600359/zkm5mkcotjepkovrh9dr.png" alt="" className="why-choose-us-tr-img why-choose-us-tr-img-2 qualified-mob-img" />
    </div>
    <div className="bulb-how-we-work-con why-trust-and-choose-container">
      <div className="qualified-reso-con">
        <div>
        <h1 className="qualified-resou-head">
        Rapid Delivery
        </h1>
        <p className="qualified-resou-para">Enhance your business performance with our swift integration of information technology. We customize our approach to suit your unique needs, ensuring quick and efficient project delivery.</p>
        </div>
        <div>
        <h1 className="qualified-resou-head">
        Intelligent Support
        </h1>
        <p className="qualified-resou-para">Experience prompt issue resolution and proactive support from our dedicated team. At Insu Creation, we are committed to your success, providing efficient solutions to keep your business running smoothly.</p>
        </div>
      </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706603987/v5gwxcuv0ojxybnhbf1q.png" alt="" className="why-choose-us-tr-img qualified-mob-img" />
    </div>
    <div className="bulb-how-we-work-con why-trust-and-choose-container why-trust-and-choose-container-2">
      <div className="qualified-reso-con">
        <div>
        <h1 className="qualified-resou-head">
        Guided Strategies
        </h1>
        <p className="qualified-resou-para">Collaborate with our skilled developers to create tailored business strategies that exceed expectations. Benefit from direct interaction with our team to ensure your project's success.</p>
        </div>
        <div>
        <h1 className="qualified-resou-head">
        Continuous Innovation
        </h1>
        <p className="qualified-resou-para">Stay ahead of the curve with our future-proof innovation and cutting-edge technologies. Our industry experts leverage advanced solutions and best practices to deliver unparalleled services and support your business growth.</p>
        </div>
      </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706604035/hejeaewu6n1fao9plsix.png" alt="" className="why-choose-us-tr-img why-choose-us-tr-img-2 qualified-mob-img" />
    </div>
  </div>
  
 </div>

 <div className="graphic-route-ty-head-main-container packaging-back-banner-img" data-aos="zoom-in">
    <div className="graphic-route-ty-head-main-container-1">
    <h1 className="graphic-route-ty-head ppt-head passionate-mob-head">At Insu Creation, we're renowned for our exceptional customer support.</h1>
    <div className="graphic-route-ty-item-con">
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596561/wjds5pderfhdna21tqk0.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">Ongoing Assistance</h1>
                <p>Your website's launch is just the beginning. Count on us for continued support with SEO, social media marketing, PPC advertising, and email newsletter assistance to ensure sustained success.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596656/bmeikdethjqnk6qkmlun.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">Success Measurement</h1>
                <p>We'll navigate Google Analytics with you, providing insights into your website's effectiveness. Alternatively, we can craft custom reports tailored to your specific needs, focusing on what matters most to you—like visitor engagement and conversions.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596692/oeeeca1vowwn91fajihn.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">Dedicated Support</h1>
                <p>Have questions about your website or need assistance? Reach out to us anytime via our office contact numbers, social networks, or mobile phones. We're here to help, day or night.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items graphic-route-ty-item-con-mobile-view">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706596726/t1gchwzrimr9dl6eqavl.png" alt="" className="graphic-design-route-logos mobile-why-trust-us-logo" />
            <div className="graphic-design-ty-list-items-div">
                <h1 className="graphic-design-ty-iten-head">Profit-Driven Approach</h1>
                <p>Our business thrives on creating functional websites and ensuring client satisfaction. Your website's success is our top priority, and we're committed to seeing you profit. We're only satisfied when you are.</p>
            </div>
        </div>
    </div>
    </div>
 </div>
 <TestimonialsContainer />
 <ListOfTechNames className="why-trust-us-list-of-te" />
 <Footer />
    </>
)}
AOS.init()
export default WhyTrustAndChooseUs