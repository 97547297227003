import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./logodesignview.css"

const LogoDesignView = () => {
  const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' })
  return (
    <>
      <Header />
      {mediaQueryCondition ? <Navbar /> : <NavbarInOtherPage />}
      <DetailsForm />
      <div className="logo-design-main-page-route">
        <span className="our-locations-contact-us our-wk our-wk-2">WE DELIVER OUR BEST</span>
        <h1 className="portfolio-head-rote mob-web-port-v">Logo Portfolio</h1>
        <p className="portfolio-para-rote mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
        <div className="logo-page-route-list-container">
          <img src={require('../InsuImages/insuprinting.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/newStar.jpg')} className="logo-page-route-imag" alt="an old car" />
          {/* <img src={require('../InsuImages/CareerI.jpg')} className="logo-page-route-imag" alt="an old car" /> */}
          <img src={require('../InsuImages/ASWAMWDH.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/Tagore.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/Joyguru.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/LBC.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/BiryanI.png')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/eparebangla.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('../InsuImages/whisperingoods.jpg')} className="logo-page-route-imag" alt="an old car" />




          <img src={require('./insuLogo/dummy1_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy2_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy3_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy4_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy5_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy6_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy7_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy8_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy9_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy10_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy11_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy12_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy13_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy14_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy15_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy16_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy17_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy18_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy19_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy20_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy21_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy22_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy23_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy24_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy25_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy26_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy27_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy28_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy29_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy30_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy31_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy32_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy33_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy34_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy35_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy36_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy37_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy38_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy39_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy40_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy41_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy42_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy43_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy44_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy45_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy46_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy47_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy48_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy49_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy50_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy51_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy52_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy53_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy54_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy55_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy56_logo.jpg')} className="logo-page-route-imag" alt="an old car" />
          <img src={require('./insuLogo/dummy57_logo.jpg')} className="logo-page-route-imag" alt="an old car" />

        </div>
      </div>

      <Footer />
    </>
  )
}

export default LogoDesignView;





























// import { useMediaQuery } from "react-responsive"
// import React, { useState, useEffect } from "react";
// import Header from "../Header"
// import Navbar from "../Navbar"
// import NavbarInOtherPage from "../NavbarInOtherPage"
// import DetailsForm from "../DetailsForm"
// import Footer from "../Footer"
// import "./logodesignview.css"

// const LogoDesignView = () => {

//   const [projects, setProjects] = useState([]);

//   useEffect(() => {
//     const fetchProjects = async () => {
//       try {
//         const response = await fetch('http://localhost:5000/api/logos');
//         const data = await response.json();
//         setProjects(data);
//       } catch (error) {
//         console.error("Error fetching projects:", error);
//       }
//     };

//     fetchProjects();
//   }, []);




//   const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' })
//   return (
//     <>
//       <Header />
//       {mediaQueryCondition ? <Navbar /> : <NavbarInOtherPage />}
//       <DetailsForm />
//       <div className="logo-design-main-page-route">
//         <span className="our-locations-contact-us our-wk our-wk-2">WE DELIVER OUR BEST</span>
//         <h1 className="portfolio-head-rote mob-web-port-v">Logo Portfolio</h1>
//         <p className="portfolio-para-rote mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
//         <div className="logo-page-route-list-container">

//           {projects.length > 0 ? (
//             projects.map((project) => (
//               <img src={project.imageUrl} key={project._id} className="logo-page-route-imag" alt="an old car" />
//             ))
//           ) : (
//             <p>Loading projects...</p>
//           )}

//           {/* <img src={require('../InsuImages/insuprinting.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/newStar.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/CareerI.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/ASWAMWDH.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/Tagore.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/Joyguru.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/LBC.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/BiryanI.png')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/eparebangla.jpg')} className="logo-page-route-imag" alt="an old car" />
//           <img src={require('../InsuImages/whisperingoods.jpg')} className="logo-page-route-imag" alt="an old car" /> */}
//         </div>
//       </div>

//       <Footer />
//     </>
//   )
// }

// export default LogoDesignView