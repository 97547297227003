import {motion} from "framer-motion"
import AboutClientsCount from "../AboutClientsCount"
import "./index.css"

const DetailsForm=()=>(
<div className="about-route-container">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707572095/hpm79hjdznfnvrhhbyur.png" className="about-route-image" alt="about-route-imag" />
        <motion.div  data-tilt data-tilt-max="6" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp mobile-details-rote-cont" data-wow-delay=".4s">
                  <div class="service-sec-list srvc-bg-nx srcl1 about-count-div">
                     <img src={require('../InsuImages/technology.png')} className="developent-img" alt="service" />
                     <h5 class="about-count-div-head">Crafting Dreams Securely</h5>
                     <AboutClientsCount />
                     <p className="count-about-div-pa">Insu Creation top-tier solutions help you excel in IT. Your trusted IT partner, Insu    Creation. Creating tailored solutions for your unique business needs is what we do. Our expertise covers web development, app development, digital marketing, and graphics design.</p>
                  </div>
               </motion.div>
               <form className="form-container-about-route">
                <h1 className="form-container-head">Get Free Quotation</h1>
                    <input type="text" className="form-container-details" placeholder="Name" />
                    <input type="email" className="form-container-details" placeholder="Email" />
                    <input type="number" className="form-container-details" placeholder="Mobile Number" />
                    <input type="text" className="form-container-details" placeholder="Your City" />
                    <select type="select" name="Domain" className="form-select-op">
                        <option value="" disabled selected hidden>Interested In*</option>
                        <option value="Logo Design" class="select-req-item"><p className="o-para">Frontend Web Development</p></option>
                        <option value="WordPress Web Development" class="select-req-item">WordPress Web Development</option>
                        <option value="Backend Web Development" class="select-req-item">Backend Web Development</option>
                        <option value="SEo" class="select-req-item">SEO</option>
                        <option value="Social Media Marketing" class="select-req-item">Social Media Marketing</option>
                        <option value="Digital Marketing Funnel" class="select-req-item">Digital Marketing Funnel</option>
                        <option value="Local SEO Services" class="select-req-item">Local SEO Services</option>
                        <option value="Technical SEO Services" class="select-req-item">Technical SEO Services</option>
                        <option value="Google Analytics" class="select-req-item">Google Analytics</option>
                        <option value="React Native" class="select-req-item">React Native App Development</option>
                        <option value="Flutter App Development" class="select-req-item">Flutter App Development</option>
                        <option value="Landing Page Development" class="select-req-item">Landing Page Development</option>
                        <option value="Article Writing" class="select-req-item">Article Writing</option>
                        <option value="Social Media Content" class="select-req-item">Social Media Content</option>
                        <option value="Video Ads for Ecommerce" class="select-req-item">Video Ads for Ecommerce</option>
                        <option value="Product Description Writing" class="select-req-item">Product Description Writing</option>
                        <option value="Digital Marketing Funnel" class="select-req-item">Digital Marketing Funnel</option>
                        <option value="Local SEO Services" class="select-req-item">Local SEO Services</option>
                        <option value="Technical SEO Services" class="select-req-item">Technical SEO Services</option>
                        <option value="Blog Content Writing" class="select-req-item">Blog Content Writing</option>
                        <option value="Graphic Design" class="select-req-item">Graphic Design</option>
                        <option value="Complete Branding" class="select-req-item">Complete Branding</option>
                        <option value="Logo Designing" class="select-req-item">Logo Designing</option>
                        <option value="Business Presentation" class="select-req-item">Business Presentation</option>
                        <option value="2D & 3D Animated Videos" class="select-req-item">2D & 3D Animated Videos</option>
                    </select>
                    
                    <button type="submit" className="button-submit-about-route">Submit</button>
            </form>
    </div>
)

export default DetailsForm