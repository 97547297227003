import React,{useEffect} from "react";
import {Link} from "react-router-dom";
import AOS from "aos"
import { FaFacebookF,FaTwitter,FaLinkedin,FaInstagram } from "react-icons/fa";

import "./index.css"

const Footer = () => {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

  return (
    <>
      <footer className="footer-area">
        <div className="container footer-cont-mob-v">
          <div className="row foot">
            <div
              className="col-lg-4 col-md-6 col-sm-6 foot foot-con-mob-v"
              data-aos-delay="100"
              data-aos="fade-in"
              data-aos-duration="1200"
            >
              <div className="single-footer-widget foot-con-mob-v">
                <div>
                  <Link to="/" className="logo foot-logo-2">
                    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705467463/in67tkbdvmzsj3vbqdhp.png" className="particle-nav-container"  alt="" />
                  </Link>
                </div>
                <p className="foot-para">
                With Insu Creation, you can turn your digital dreams into reality with the help of a team of innovative designers and developers. As a passionate team, we design user-centric websites and mobile apps using cutting-edge technologies. On the path to digital success, let us be your partner.
                </p>
              </div>
            </div>



            <div className="company-supp-con">
            <div
              className="col-lg-2 col-md-6 col-sm-6"
              data-aos="fade-in"
              data-aos-delay="200"
              data-aos-duration="1200"
            >
              <div className="single-footer-widget">
                <h3>Company</h3>

                <ul className="services-list">
                  <li className="services-li-con-foot">
                    <Link to="/aboutus">About Us</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/services">Services</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/portfolio">Portfolio</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/career">Careers</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/contactus">Latest News</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-lg-2 col-md-6 col-sm-6"
              data-aos="fade-in"
              data-aos-delay="300"
              data-aos-duration="1200"
            >
              <div className="single-footer-widget">
                <h3>Support</h3>
 
                <ul className="support-list">
                  <li className="services-li-con-foot">
                    <Link to="/faq">FAQ's</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/terms-conditions">Terms & Conditions</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="#">Community</Link>
                  </li>
                  <li className="services-li-con-foot">
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            </div>




            <div
              className="col-lg-4 col-md-6 col-sm-6 foot-con-mob-v"
              data-aos="fade-in"
              data-aos-delay="400"
              data-aos-duration="1200"
            >
              <div className="single-footer-widget new-end-contact-no">
                <h3>Contact Info</h3>

                <ul className="footer-contact-info">
                  <li className="services-li-con-foot-a">
                    Location: <span> Bohichberia Bus Stand,Bohichberia, Tamluk, West Bengal 721649</span>
                  </li>
                  <li className="services-li-con-foot">
                    Email: <span>insucreation@gmail.com</span>
                  </li>
                  <li className="services-li-con-foot">
                    Phone: <span> (+91) 9547361869</span>
                  </li>
                </ul>

                <ul className="social">
                  <li>
                    <a href="https://www.facebook.com/insucreation" target="_blank">
                      <FaFacebookF className="footer-icons" />
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/insucreation" target="_blank">
                      <FaTwitter className="footer-icons" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/insucreation/" target="_blank">
                      <FaLinkedin className="footer-icons" />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/insucreation?igsh=dHBjNnhkOGd6Ymgw" target="_blank">
                     <FaInstagram className="footer-icons" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="copyright-area">
            <p>
              Copyright &copy; {currentYear} Insu Creation. Designed by{" "}
              <a href="http://envytheme.com/" target="_blank">
                Insu Creation
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
