import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Navbar from "../Navbar";
import AOS from "aos";
import Footer from "../Footer"
import Header from "../Header"
import {Helmet} from "react-helmet"
import NavbarInOtherPage from "../NavbarInOtherPage"
import "./index.css"

const RefundPolicy=()=>{
    useEffect(() => {
        AOS.init({
          
          duration : 2000
        });
      }, []);
    
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    
    return(
        <>
        <Helmet>
        <title>Insu Creation Refund Policy: Returns, Exchanges, and Shipping Details</title>
<meta name="description" content="Explore the Insu Creation refund policy for comprehensive guidelines on returns, exchanges, and shipping procedures. Learn about eligibility criteria, refund process, and steps to initiate returns. Shop with confidence knowing your purchases are backed by our customer-friendly policies."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
       <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
        <div className="payment-policy-route-main-con"> 
            <h1 className="payment-policy-route-heading">Refund Policy</h1>
        </div>
        <div className="payment-policy-route-main-con-2">
            <h1 className="payment-policy-route-heading-2">Insu Creation Refund Policy: Returns & Exchanges</h1>
            <p className="payment-policy-route-heading-para-1">Shop with confidence knowing that at Insu Creation, we stand behind our products. Our return policy extends for 30 days from the date of purchase, ensuring your satisfaction. To qualify for a return, your item must remain unused and in its original packaging. Please note that certain items are exempt from return, such as perishable goods, downloadable software, and health or personal care items.</p>

            <div className="payment-policy-route-con-2">
            <h1 className="payment-policy-route-heading-3 privacy-policy-h">Non-returnable items include:</h1>
                <p className="payment-policy-route-heading-para">➢ Gift cards</p>
                <p className="payment-policy-route-heading-para">➢ Downloadable software products</p>
                <p className="payment-policy-route-heading-para">➢ Certain health and personal care items</p>
                <p className="payment-policy-route-heading-para">To process your return, a receipt or proof of purchase is required. Please refrain from sending your purchase back to the manufacturer.</p>
              
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Refunds</h1>

                <p className="payment-policy-route-heading-para">Upon receipt and inspection of your return, we will notify you via email regarding the status of your refund. If approved, the refund will be processed and credited to your original method of payment within a specified timeframe.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Late or missing refunds</h1>

                <p className="payment-policy-route-heading-para">If you haven't received your refund yet, please check your bank account and contact your credit card company or bank. Should further assistance be needed, feel free to reach out to us at sales@insucreation.in.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Sale items (if applicable)</h1>

                <p className="payment-policy-route-heading-para">Please note that only regular priced items are eligible for refunds. Sale items are non-refundable.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Exchanges (if applicable)</h1>

                <p className="payment-policy-route-heading-para">We only replace items if they are defective or damaged. If you need to exchange a product, please email us at sales@insucreation.in for further instructions.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Gifts</h1>

                <p className="payment-policy-route-heading-para">If the item was marked as a gift and shipped directly to you, you'll receive a gift credit upon return. If the item was not marked as a gift, the refund will be issued to the original purchaser.</p>
                
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Shipping</h1>

<p className="payment-policy-route-heading-para">To return your product, please mail it to the address provided. You will be responsible for covering the shipping costs, and please note that shipping costs are non-refundable. Consider using a trackable shipping service or purchasing shipping insurance for items over $75.</p>

<p className="payment-policy-route-heading-para">Thank you for choosing Insu Creation. Your satisfaction is our priority.</p>


            </div>
        </div>
        <Footer />
        </>
    )
}

export default RefundPolicy