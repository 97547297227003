import React, { useState } from "react";
import {Link} from "react-router-dom"
import "./index.css";

const serviceDropdown = [
    {
      id: 1,
      title: "About Us",
      path:"/aboutus"
    },
    {
      id: 1101,
      title: "Our Clients",
      path:"/our-clients"
    },
    {
      id: 2,
      title: "Technologies we Use",
      path:"/technologiesweuse"
    },
    
      {
        id: 8,
        title: "How we work",
        path:"/how-we-work"
      },
      {
        id: 9,
        title: "Why Trust And Choose Us?",
        path:"/why-trust-and-choose-us"
      },
      {
        id: 1102,
        title: "Our Partners",
        path:"/our-partners"
      },
      {
        id: 1103,
        title: "Payment Policy",
        path:"/payment-policy"
      },
      {
        id: 1104,
        title: "Privacy Policy",
        path:"/privacy-policy"
      },
      {
        id: 1105,
        title: "Terms And Conditions",
        path:"/terms-condition"
      },
      {
        id: 1106,
        title: "Refund Policy",
        path:"/refund-policy"
      },
      {
        id: 1109,
        title: "Site Map",
        path:"/site-map"
      },
  ];

const AboutDropdown=(props)=> {
  const [dropdown, setDropdown] = useState(false);
  const {newDr1,newDr2}=props 
  const changeColor2=newDr1?"drop-down-mr":""
  const changeColor3=newDr2?"drop-down-mr":""

  return (
    <>
      <ul
        className={dropdown ? `services-submenu services-submenu-ab clicked ${changeColor2} ${changeColor3}` : `services-submenu services-submenu-ab ${changeColor2} ${changeColor3}`}
        onClick={() => setDropdown(!dropdown)}
      >
        {serviceDropdown.map((item) => {
          return (
            <li key={item.id} className="about-drop-ab">
            <Link
              to={item.path}
              onClick={() => setDropdown(false)}
              className="about-lin-ab"
            >
              {item.title}
            </Link>
          </li>
          );
        })}
      </ul>
    </>
  );
}

export default AboutDropdown;