import "./index.css"

const WhatWeWork=()=>(
 <div className="how-we-work-main-container">
  <h1 className="how-we-work-heading">
    HOW WE WORK
  </h1>
  <div className="how-we-work-container">
  <div className="how-we-work-container-2">
    <h1 className="how-we-work-heading work-heading-2 content-heading custom-head-mob">Dedicated Development Team</h1>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704514018/omttstwnvafronw1q5ni.webp" className="how-we-work-para" alt="how-we-work-para" />
    <button type="button" className="how-we-work-button">Know More</button>
  </div>
  <hr className="how-we-work-horizontal-line" />
  <div className="how-we-work-container-2">
    <h1 className="how-we-work-heading work-heading-2 custom-head-mob">Custom Software Development</h1>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704514056/kt4wlio4depr66eitzqv.webp" className="how-we-work-para" alt="how-we-work-para" />
  <button type="button" className="how-we-work-button">Know More</button>
 </div>
 </div>
 </div>
)
export default WhatWeWork