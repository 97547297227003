import { useMediaQuery } from "react-responsive"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Header from "../Header"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./index.css"

const GraphicPortfolio=()=>{
  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
    <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
   
<div className="container-product-main-con">
<span className="our-locations-contact-us our-wk web-work-w">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Graphic Portfolio</h1>
    <p className="portfolio-para-rote web-des-w-page mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
  <div className="container-product-main">
  <div className="app-portfolio-div">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708618508/g8zz0wdmh3aosvdtsetb.jpg" className="graphic-port-images-p" alt="a lighthouse" />
 
 </div>
 <div className="app-portfolio-div">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708618697/wghogwjpf1d0ddr9sksc.png" className="graphic-port-images-p" alt="a lighthouse" />

 </div>
 <div className="app-portfolio-div">
    <img src="https://media.licdn.com/dms/image/D5612AQGUAQvqzwzuQA/article-cover_image-shrink_720_1280/0/1678557678868?e=2147483647&v=beta&t=9D4t8str-tvc5VoT2-eMHk05ftseMcUhQIdPicBbkkY" className="graphic-port-images-p" alt="a lighthouse" />

 </div>
 <div className="app-portfolio-div">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708618750/r6smhd19fd1wdzsj8wzp.png" className="graphic-port-images-p" alt="a lighthouse" />
 
 </div>
 <div className="app-portfolio-div">
    <img src="https://cdn.dribbble.com/users/2269244/screenshots/6459133/image.png?resize=400x0" className="graphic-port-images-p" alt="a lighthouse" />
 
 </div>
 <div className="app-portfolio-div">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708618632/ooxp1ohl8v9l93hgnkrb.jpg" className="graphic-port-images-p" alt="a lighthouse" />
 
 </div>
</div>
</div>
<Footer />
</>
)
}
export default GraphicPortfolio
