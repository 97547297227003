import { useMediaQuery } from "react-responsive";
import Header from "../Header"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import {Helmet} from "react-helmet"
import { IoIosCall } from "react-icons/io";
import ScheduleMeeting from "../ScheduleMeeting";
import "./index.css"
import Footer from "../Footer"; 

const ScheduleMeetingContainer=()=>{
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    return(
        <>
        <Helmet>
        <title>Book a Meeting with Insu Creation: Unlocking Your Path to Digital Solutions</title>
<meta name="description" content="Schedule a meeting with Insu Creation to explore your path to digital solutions. Discover innovative strategies for insurance services tailored to your needs. Let us guide you towards a seamless digital transformation."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
         <Header />
        {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
         <div className="schedule-metting-route-main-con">
         <h3 class="head-ani-gradient head-ani-gradient-pt">SCHEDULE A MEETING</h3>
         <h1 className="schedule-metting-route-heading">You’re just 1-min away of scheduling a Meeting with Us</h1>
         <p className="schedule-metting-route-paragraph">If you face any issues, please contact us  <span>
            <a href="tel:9547361869" className="phone-call-click-no"><IoIosCall size={20} /> 9547361869</a>
         </span></p>
         
         </div>
        <ScheduleMeeting />
        <Footer />
        </>
    )
}

export default ScheduleMeetingContainer