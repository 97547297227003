import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import Header from "../Header"
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const Canva=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Impressions Matter: Elevate Your Small Business with Insu Creation's Graphic Design Expertise</title>
    <meta name="description" content="Elevate your small business with Insu Creation's expert graphic design services. Make lasting impressions and stand out from competitors with captivating designs tailored to your brand. Connect with your audience and gain a competitive edge today."/>
    <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Insu Creation: Empowering Your Brand with Graphic Design Expertise </h1>
    <p className="transform-para transform-2-para">Graphic design is a dynamic field essential for conveying messages visually. With Insu Creation's expert services, harness the power of visual communication to elevate your brand across various industries, including advertising, marketing, and media.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Our designs establish a distinctive identity for your brand.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">We imbue your brand with character and charisma through our creations.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Make a memorable impact with visually stunning designs.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Reach your desired audience effectively through strategic design.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Keep your customers captivated with engaging visual content.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706248329/xdxtxkkwwzrhgyu25dyx.gif" className="seo-route-img-main canva-design-img graphic-mob-im artile-write-img-vv" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706249204/s7sk4eprtnetnt5ttf02.gif" className="react-native-mid-img graphic-mob-im artile-write-img-vv" alt="" />

    <div>
        <h1 className="react-native-business-dev">Insu Creation: Transforming Brands through Graphic Design Innovation</h1>
        <p className="react-native-business-dev-para">Within the realm of graphic design, professionals have the autonomy to chart their own course, whether it be as self-employed freelancers or ambitious entrepreneurs launching their own design firms.</p>
        <p className="react-native-business-dev-para">By leveraging the potential of graphic design, businesses can immediately project an aura of expertise and reliability, regardless of their tenure in the market. Insu Creation's mastery in design ensures that even burgeoning ventures can establish a strong and credible presence.</p>
        <p className="react-native-business-dev-para">Graphic design serves as a versatile tool for businesses, facilitating brand establishment, market expansion, customer engagement, and action-driven persuasion.</p>
        <p className="react-native-business-dev-para">A robust and consistent brand image not only fosters trust among consumers but also cultivates lasting relationships, driving brand loyalty and advocacy. With Insu Creation as your ally, we're dedicated to elevating your brand's impact through innovative graphic design solutions.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706249638/hqzqq8wxvpoo9dzg1og8.gif" className="react-native-mid-img social-media-content-img-n graphic-mob-im artile-write-img-vv" alt="" />

    <div>
        <h1 className="react-native-business-dev">Insu Creation: Crafting Lasting Impressions for Small Businesses</h1>
        <p className="react-native-business-dev-para">A solid grounding in graphic design not only sets you apart but also provides a discerning eye for distinguishing your brand amidst competitors.</p>
        <p className="react-native-business-dev-para">Transforming your brand from mundane to memorable is within reach. Whether you aim for a friendlier, more humorous appeal, Insu Creation's designs forge connections that resonate with your consumers, granting you a competitive edge.</p>
        <p className="react-native-business-dev-para">Adaptable to various target markets, graphic design offers the flexibility needed to cater to your business's diverse audience. With Insu Creation, your brand's visual identity can evolve to meet the needs and preferences of your desired market segments.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>
 <div className="publish-articles-spec-con-main canva-img-container-items">
    <div className="publish-articles-spec-con canva-img-container-items">
        <h1 className="publish-articles-head">Exploring Graphic Design tools</h1>
        <div className="publish-article-images-container-main canva-img-container-items-1">
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706251139/na5hyxz41bsf5fhr1nb1.jpg" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Canva</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252577/juurhpzbgvmopkz0ylct.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Adobe Illustrator</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252640/s1nldo9doy5dememy6vi.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Adobe Photoshop</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252686/n9q8dllr2tpmcbp8p3ht.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Adobe InDesign</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252763/yhczxzggaegddkurisgc.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">GIMP</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252866/srsvpk4jfagpq6rlbsez.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Sketch</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252908/ndkylsn5cliupepbdimc.svg" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Inkscape</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706252951/zkz9izybd59cbxnxaxgk.jpg" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Affinity Designer</h1>
            </div>
        </div>
    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default Canva