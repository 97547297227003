import React from "react";
import Duevion from "../../assets/Images/Duevion.png";
import MartshipImg from "../../assets/Images/Martship-india.png";
import westInteriorImg from "../../assets/Images/West-interiors.png";
import CamaliaSchoolImg from "../../assets/Images/Camalia-public-school.png";
import NextLifeImg from "../../assets/Images/Next-Life.png";
import EtamplImg from "../../assets/Images/Etampl.png";
import styles from "./Projects.module.css";

// Define an array of project objects
const projects = [
    {
        image: Duevion,
        title: "Duevion",
        link: "https://duevion.com/",
        technologies: ["Node", "Express", "MongoDB", "React"],
    },
    {
        image: MartshipImg,
        title: "MARTSHIP INDIA",
        link: "https://martshipindia.com/",
        technologies: ["React", "Firebase"],
    },
    {
        image: westInteriorImg,
        title: "West Interiors",
        link: "https://www.west-interiors.com/",
        technologies: ["JavaScript", "HTML", "CSS", "React"],
    },
    {
        image: CamaliaSchoolImg,
        title: "Camellia Public School",
        link: "https://cpssantiniketan.com/",
        technologies: ["JavaScript", "HTML", "CSS", "React"],
    },
    {
        image: NextLifeImg,
        title: "Next Life",
        link: "https://nextlifecolleges.com/",
        technologies: ["JavaScript", "HTML", "CSS", "React"],
    },
    {
        image: EtamplImg,
        title: "Etampl",
        link: "https://www.etampl.in/",
        technologies: ["JavaScript", "HTML", "CSS"],
    },
];

const Projects = () => {
    return (
        <div className={`${styles.container} ${styles.project}`}>
            {projects.map((project, index) => (
                <div key={index} className={styles.card}>
                    <div className={styles.cardImage}>
                        <img src={project.image} alt={project.title} />
                    </div>
                    <div className={styles.cardImageOverly}>
                        {project.technologies.map((tech, techIndex) => (
                            <span
                                key={techIndex}
                                className={styles.cardDetailBadge}
                            >
                                {tech}
                            </span>
                        ))}
                    </div>
                    <div className={styles.cardBody}>
                        <div className={styles.adTitle}>
                            <h5 className={styles.textUpperCase}>
                                {project.title}
                            </h5>
                        </div>
                        <div>
                            <a
                                className={styles.adBtn}
                                href={project.link}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                view
                            </a>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Projects;
