import React, { useEffect } from "react";
import {motion} from "framer-motion"
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet"
import Footer from "../Footer"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import "./index.css"

const PatnerWithUs=()=>{
    useEffect(() => {
        AOS.init({
          
          duration : 2000
        });
      }, []);
    
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    return(
        <>
        <Helmet>
        <title>Insu Creation Partnership: Empowering Growth with Innovative Solutions</title>
<meta name="description" content="Explore Insu Creation partnership opportunities for innovative IT solutions. Benefit from our expertise, cost-effective models, and global network to propel your business growth. Partner with us for quality, reliability, and success."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
         <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <div className="career-rote-container">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711979908/lqs6toytdtovgwvefmnb.png" alt="" className="careers-rote-img careers-rote-img-partners" />
        <div className="career-mob-con career-mob-con-partners">
            <span className="career-rote-head">Partner With Us.</span>
            <p className="career-rote-para">Let us create a win-win situation where all of us can grow together.</p>
            <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Contact Us<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
        </div>
    </div>

    <div className="partner-with-us-route-main-con">
      <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con">
          <h1 className="partner-route-about-head" data-aos="fade-right">About Partnership</h1>
          <p data-aos="fade-left">At Insu Creation, we believe in fostering long-term relationships and offer various partnership models tailored to different groups and individuals. We understand the importance of finding a partner who comprehensively understands your challenges and responsibly crafts intelligent IT solutions. Whether it involves your customers, suppliers, partners, or employees, we recognize your goal of simplifying their lives, and we aim to empower you to do so effectively.</p>
        <Link to="contactus"> <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link> 
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711980196/ctvsrolaqjsbl0hzbshr.png" data-aos="zoom-in" alt="" className="about-partner-img" />
      </div>
    </div>
    <div className="partner-with-us-route-main-con partner-with-us-route-main-con-back">
    <h3 class="head-ani-gradient head-ani-gradient-pt" data-aos="fade-left">WE DELIVER OUR BEST</h3>

      <h1 className="partner-insu-head-c partner-insu-head-c-ne-n" data-aos="fade-left">Partnering Insu Creation</h1>
    <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con">
          <p data-aos="fade-right">Partnering with Insu Creation means accessing our expertise in internet and mobile technology, benefiting from high-quality and innovative solutions, and achieving your business objectives within your timeline, quality standards, and budget constraints. We acknowledge the significance of rapid scaling while maintaining operational excellence and reducing liabilities for our partners in a constantly evolving global market. Our goal is to facilitate your expansion to seize opportunities and gain competitive advantages efficiently.</p>
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711980659/ux8t4ozeb7x93vzwyi7y.png" data-aos="zoom-in" alt="" className="about-partner-img " />
      </div>
      </div>

      <div className="partner-with-us-route-main-con">
      <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con partner-route-con-2">
          <h1 className="partner-route-about-head partner-route-about-head-2">As a Insu Creation Partner, you gain access to:</h1>
          <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Depth and breadth of our service and solution portfolio</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Technical support and full assistance</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Referencing through our website</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Cost differentials in offshore development.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Quality solutions developed with industry standards.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Higher productivity and return on investment</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">A trained, high-quality global IT workforce with diverse technology skills</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Enhanced market competitiveness by focusing on core business functions</p>
        </div>
      </li>
     
    </ul>
        
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711981343/zhzdwptmjq34afz0b2sl.png" data-aos="zoom-in" alt="" className="about-partner-img about-partner-img-2" />
      </div>
    </div>


    <div className="partner-with-us-route-main-con partner-with-us-route-main-con-pty">
    <h1 className="partner-insu-head-c">Types of Partnership</h1>
    <div className="container-route-partners">
      <div className="container-route-partners-item" data-aos="fade-up">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711982467/oqqau8hdn4kbmzstumha.png" className="image-route-partner" alt="" />
        <h1 className="partners-outsourc-head">Outsourcing Partnership</h1>
      </div>
      <div className="container-route-partners-item" data-aos="fade-up">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983890/kgpufmolxtxwf9hkvmco.png" className="image-route-partner" alt="" />
        <h1 className="partners-outsourc-head">Referral Partnership</h1>
      </div>
      <div className="container-route-partners-item" data-aos="fade-up">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983951/v8ypcl9dnxwgt3dksnlw.png" className="image-route-partner" alt="" />
        <h1 className="partners-outsourc-head">Domain Partnership</h1>
      </div>
      <div className="container-route-partners-item" data-aos="fade-up">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983983/o59vrh6dx6y08z9jbohc.png" className="image-route-partner" alt="" />
        <h1 className="partners-outsourc-head">Geo Partner</h1>
      </div>
    </div>
    </div>


    <div className="partner-with-us-route-main-con partner-with-us-route-main-con-nn">
      <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con">
          <h1 className="partner-route-about-head" data-aos="fade-left">Outsourcing Partnership</h1>
          <p data-aos="fade-right">Extend your office to an offshore location in India. Enjoy cost-cutting up to 50% by extending your office to India. Stop worrying about staff entitlements, personnel management, pensions, safety and regulatory measures. You no longer need to recruit, train and update skills when scaling or rescaling or shifting technology domains. Stop worrying about technology turns, equipment upgrades, software licenses, and software upgrades, instead, simply make Invoices your outsourcing Partner and start gaining full advantage from day one.</p>
        
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983504/mihe4wjq8xy4l1ngdxp7.png" data-aos="zoom-in" alt="" className="about-partner-img" />
      </div>
    </div>

    <div className="partner-with-us-route-main-con">
      <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con">
          <h1 className="partner-route-about-head" data-aos="fade-left">Referral Partnership</h1>
          <p data-aos="fade-right">Promote our services within your network, and upon successful referrals, receive a referral fee. Our Business Development Team will provide detailed partnership initiation information.</p>
        
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983660/w7ttqwwet0fy6c7hlmrd.png" data-aos="zoom-in" alt="" className="about-partner-img about-partner-img-ref-ow" />
      </div>
    </div>


    <div className="partner-with-us-route-main-con">
      <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con">
          <h1 className="partner-route-about-head" data-aos="fade-left">Domain Partnership</h1>
          <p data-aos="fade-right">Collaborate with us as an independent business consultant and domain expert. Leverage our expertise and services to explore customer bases and generate leads, with full support provided throughout the software development phases.</p>
        
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983821/og72x2pgmpl2gun8dyi6.png" data-aos="zoom-in" alt="" className="about-partner-img" />
      </div>
    </div>

    <div className="partner-with-us-route-main-con">
      <div className="partner-with-us-route-main-con-1">
        <div className="partner-route-con">
          <h1 className="partner-route-about-head" data-aos="fade-left">Geo Partner</h1>
          <p data-aos="fade-right">Represent Insu Creation and its services in your specific location. Handle client interactions locally while our offshore team executes projects. Receive a predetermined commission percentage for successful projects.</p>
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711983788/mv8fxydvhzszza3qcm5d.png" data-aos="zoom-in" alt="" className="about-partner-img about-partner-img-ref-ow" />
      </div>
    </div>


    <div className="why-choose-us-container">
    <h3 class="head-ani-gradient head-ani-gradient-pt head-ani-gradient-pt-cus">WE DELIVER OUR BEST</h3>

    <p className="why-we-deliver why-we-deliver-mob-view">Who Can Be a Partner?</p>
    <ul className="why-choose-us-list-container why-choose-us-list-container-mob-view why-choose-us-list-container-mob-view-ul-l">
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view services-container-mob-view-partner-route">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711984571/gy6adhvnarwz1ehxdqpf.png"
          className="services-img services-img-partne"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Educational Qualification</h1>
          <p className="services-paragraph">
          Minimum educational qualification: Class 12 passed out
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view services-container-mob-view-partner-route">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711984745/wdgwmhubpahsumtsczru.png"
          className="services-img services-img-partne"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Entrepreneur Mindset</h1>
          <p className="services-paragraph">
          Entrepreneurial mindset for growth
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view services-container-mob-view-partner-route">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711984984/dqkfcwm7yehql5gwzyec.png"
          className="services-img services-img-partne"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Knowledge about Business</h1>
          <p className="services-paragraph">
          Knowledge about business operations and management
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view services-container-mob-view-partner-route">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711985132/rnxukfvxgfcmastv1kmq.png"
          className="services-img services-img-partne"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Network</h1>
          <p className="services-paragraph">
          Extensive network with businessmen and entrepreneurs
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view services-container-mob-view-partner-route">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711985167/a2xdxxzdpzbwovur3mqr.png"
          className="services-img services-img-partne"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Strategy Skills</h1>
          <p className="services-paragraph">
          Strategic skills for sales and management
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view services-container-mob-view-partner-route">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711985205/ds4q75lwf1kzvt28ghwd.png"
          className="services-img services-img-partne"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Communication Skills</h1>
          <p className="services-paragraph">
          Effective communication skills
          </p>
        </div>
      </motion.li>
     
    </ul>
    <div className="want-enquire-new-btn">
      <p className="want-enquire-new-btn-para new-insu-btn-want-makes">Want to <span className="want-enquire-new-btn-span">Start</span> a New Project</p>
  
      <Link to="contactus">  <button type="button" className="new-want-btn new-want-btn-1">Enquire Now</button></Link>
    </div>
  </div>


  <div className="partner-with-us-route-main-con">
    <h1 className="partner-insu-head-c">Benefits of Partnership with Us</h1>
    <div className="container-route-partners">
     <div className="benefits-partner-con benefits-partner-con-m benefits-partner-con-m-color" data-aos="fade-up">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711985804/xtaggqdczwapijbvcgym.png" className="image-partner-benefit image-partner-benefit-m" alt="" />
     <div>
      <h1 className="showcase-partner-head showcase-partner-head-m">Showcase our Profile</h1>
      <p className="showcase-partner-para">Showcase our profile with recognitions and certifications</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-m benefits-partner-con-m-color" data-aos="fade-up">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711986257/rfiiuiuw7pqp8rrwtxoi.png" className="image-partner-benefit image-partner-benefit-m" alt="" />
     <div>
      <h1 className="showcase-partner-head showcase-partner-head-m">Monetary Benefits</h1>
      <p className="showcase-partner-para">Monetary benefits through project referrals</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-m benefits-partner-con-m-color" data-aos="fade-up">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711986303/l9pw0a3bvhhgi3yitvzg.png" className="image-partner-benefit image-partner-benefit-m" alt="" />
     <div>
      <h1 className="showcase-partner-head showcase-partner-head-m">Exposure</h1>
      <p className="showcase-partner-para">Exposure through our network and promotion</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-m benefits-partner-con-m-color" data-aos="fade-up">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711986333/iue2rjqpzgy82y4w6fvu.png" className="image-partner-benefit image-partner-benefit-m" alt="" />
     <div>
      <h1 className="showcase-partner-head showcase-partner-head-m">Start-Up Help</h1>
      <p className="showcase-partner-para">Start-up consultancy and related assistance</p>
     </div>
     </div>

    </div>
    </div>



    <div className="partner-with-us-route-main-con partner-with-us-route-main-con-back">
    <h1 className="partner-insu-head-c partner-insu-head-c-mob">Gift vouchers for services and products
</h1>
    <div className="container-route-partners container-route-partners-ps-a">
     <div className="benefits-partner-con benefits-partner-con-2 benefits-partner-con-2-mob">
      <div className="image-con-blue-col">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711986682/gwjoqjoodfno6dti2gbc.png" className="image-partner-benefit image-partner-benefit-trns" alt="" />
      </div>
     <div>
      
      <h1 className="showcase-partner-head">Gift Vouchers</h1>
      <p className="showcase-partner-para">One of the perks after partnership, are gift vouchers to avail from our wide variety of services and products. You can use them for your benefits and technical strength.</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-2 benefits-partner-con-2-mob">
     <div className="image-con-blue-col">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711987076/hxoa1heuvbsai2j5r6kt.png" className="image-partner-benefit image-partner-benefit-trns" alt="" />
      </div>
     <div>
      <h1 className="showcase-partner-head">One-to-One Coffee</h1>
      <p className="showcase-partner-para">We would love to have you with a one-to-one coffee to discuss and get know more about each other’s business, ideas, future prospects, and much more.</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-2 benefits-partner-con-2-mob">
     <div className="image-con-blue-col">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711987117/ubhvrfcvtf33xv2o4zjz.png" className="image-partner-benefit image-partner-benefit-trns" alt="" />
      </div>
     <div>
      <h1 className="showcase-partner-head">Find Potential Customers</h1>
      <p className="showcase-partner-para">With our Networks, our resources, partners and friends, we can assure you that whenever we find someone in need of your services/ products, we will promote you.</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-2 benefits-partner-con-2-mob">
     <div className="image-con-blue-col">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711987183/a2giguqqvg4al6hr8inm.png" className="image-partner-benefit image-partner-benefit-trns" alt="" />
      </div>
     <div>
      <h1 className="showcase-partner-head">Exposure</h1>
      <p className="showcase-partner-para">With our Network, you can expect exposure. We can promote you in front of our partners and friends and get you clients who need your product/ service.</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-2 benefits-partner-con-2-mob">
     <div className="image-con-blue-col">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711987297/bup566szvmc43xlfq86q.png" className="image-partner-benefit image-partner-benefit-trns" alt="" />
      </div>
     <div>
      <h1 className="showcase-partner-head">Together we work</h1>
      <p className="showcase-partner-para">Whenever we find a client, or you find a client, who needs services that fall in our domain one after the another, we can always try to refer the client to each other for services.</p>
     </div>
     </div>
     <div className="benefits-partner-con benefits-partner-con-2 benefits-partner-con-2-mob">
     <div className="image-con-blue-col">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711987327/njubvivqbjhakuejjbzh.png" className="image-partner-benefit image-partner-benefit-trns" alt="" />
      </div>
     <div>
      <h1 className="showcase-partner-head">Network Building</h1>
      <p className="showcase-partner-para">With our partnership, comes perks like exposure, growth, and network building. When we refer you to someone, we help you build a network. Be assured of basic network building help.</p>
     </div>
     </div>
    </div>
    </div>



    <div className="partner-with-us-route-main-con">
    <h3 class="head-ani-gradient head-ani-gradient-pt">OUR HAPPY PARTNERS</h3>

<h1 className="partner-insu-head-c partner-insu-head-c-2">Our partners</h1>
     <div className="partners-client-images-con">
     <div className="image-para-route-partn">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711987983/xafvwuwybzv1tucyswqv.png" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">kolkata-buzz, India</p>
     </div>
     <div className="image-para-route-partn">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711988499/fieb90n3ehmsaq0cfkbq.png" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">Kolkata Ventures, India</p>
     </div>
     <div className="image-para-route-partn">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711988561/mw2dqtrheiciy6ljk5db.png" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">Legal Salah, India</p>
     </div>
     <div className="image-para-route-partn">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711988593/wg8b2uouyxvpsduyaixg.png" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">Legal Wazir, India</p>
     </div>

     </div>
     <div className="partners-client-images-con">
     <div className="image-para-route-partn image-para-route-partn-black">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711988752/ofurqhadoeu0oljiwswo.png" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">collab, India</p>
     </div>
     <div className="image-para-route-partn">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711988850/jif3utu2ddhkevx86vbb.webp" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">Seven Boats, India</p>
     </div>
    
     <div className="image-para-route-partn">
      <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1711988883/ufh6kvkul1cyf1e9emxe.png" className="para-partners-route-img" alt="" />
      <p className="para-partners-route">Beyond Digital, India</p>
     </div>

     </div>
    </div>
    <Footer />
        </>  
    )
}


export default PatnerWithUs