import React, { useState } from "react";
import {Link} from "react-router-dom"
import "./index.css";

const DropDownContactList = [
    {
      id: 11111,
      title: "Contact Us",
      path:"/contactus"
    },
    {
      id: 11112,
      title: "Partners With Us",
      path:"/partner-with-us"
    },
    {
      id: 11113,
      title: "Schedule a Meeting",
      path:"/schedule-meeting"
    },
    
  ];

const DropDownContact=(props)=> {
  const [dropdown, setDropdown] = useState(false);
  const {newDr1,newDr2}=props 
  const changeColor2=newDr1?"drop-down-mr":""
  const changeColor3=newDr2?"drop-down-mr":""

  return (
    <>
      <ul
        className={dropdown ? `services-submenu services-submenu-ab clicked newCon-in ${changeColor2} ${changeColor3}` : `services-submenu services-submenu-ab newCon-in ${changeColor2} ${changeColor3}`}
        onClick={() => setDropdown(!dropdown)}
      >
        {DropDownContactList.map((item) => {
          return (
            <li key={item.id} className="about-drop-ab">
            <Link
              to={item.path}
              onClick={() => setDropdown(false)}
              className="about-lin-ab"
            >
              {item.title}
            </Link>
          </li>
          );
        })}
      </ul>
    </>
  );
}

export default DropDownContact;