import AOS from "aos";
import {Link} from "react-router-dom"
import Navbar from '../Navbar';
import BannerImage from "../InsuImages/banner.webp"
import "./index.css"

const MainBanner=()=>(
 <div className="bg-container">
 <Navbar />
  <div className="home-main-container-1">
    <div className="home-main-container">
      <div className='home-creativity-head'>
        <h1 className="home-page-main-heading" data-aos="fade-up">Creativity Enrich Your Productivity</h1>
        <p className="home-page-main-paragraph" data-aos="fade-left">Insu Creation: Your Digital Solution Partner for all your digital needs. Using extraordinary technical talent, we deliver high-quality web and app development, graphics design, content writing, and digital marketing solutions all over the world. We provide agile methodology along with technical expertise in the following areas: PHP, Python, Kotlin, NodeJS & ReactJS etc.</p>
        <Link to="/contactus"><button type="button" className="button-home-main" data-aos="fade-up">Craft Your Digital Success with Us!</button></Link>
      </div>

      <img src={BannerImage} className="home-main-container-image" alt="home-main-container-1" />
    </div>
  </div>

  
  

 </div>
)
AOS.init()
export default MainBanner







