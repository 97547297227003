import React from 'react';
import CountTwo from "../CountTwo"
import "./index.css"

const dataw= [
        {
            "id": "005",
            "number": "5", 
            "label":"Years Experience",
            "duration": "2"
        },
        {
            "id": "006",
            "number": "10",
            "label":"Org Recogination",
            "duration": "2"
        },
        {
            "id": "007",
            "number": "900",
            "label":"Happy Clients",
            "duration": "2"
        },
    ]

console.log(dataw)
const AboutClientsCount = () => (       
    <div className='about-clients-count-container'>
      {dataw.map((eachItem)=><CountTwo eachItem={eachItem} key={eachItem.id} />
      )}
    </div>
  );

  
export default AboutClientsCount;