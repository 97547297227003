import { useMediaQuery } from 'react-responsive';
import Navbar from '../Navbar';
import Header from "../Header"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"

import "./index.css"

const SeoDesign=()=>{
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
    <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}

    <DetailsForm />
    <div className='poster-design-main-container-route'>
    <span className="our-locations-contact-us our-wk poster-des-span">OUR Work</span>
    <h1 className="portfolio-head-rote mob-web-port-v">SEO PORTFOLIO</h1>
    <p className="portfolio-para-rote poster-des-para mob-web-port-v-para">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
    <div className="seo-img-portfolio">
        <div className="seo-img-portfolio-container">
       <p className="seo-img-portfolio-container-heading">Project : Anand Flour Mill</p>
       <p className="seo-img-portfolio-container-heading">Keywords : no 1 flour mill in Asansol</p>
       <p className="seo-img-portfolio-container-heading">Rank : 1</p>
       <p className="seo-img-portfolio-container-heading">Duration : 6 months</p>
       </div>
    </div>
    <div className="seo-img-portfolio seo-img-portfolio-2">
        <div className="seo-img-portfolio-container">
       <p className="seo-img-portfolio-container-heading">Project : Confidental</p>
       <p className="seo-img-portfolio-container-heading">Keywords : Best Dental Clinic in Paschim Burdwan</p>
       <p className="seo-img-portfolio-container-heading">Rank : 1</p>
       <p className="seo-img-portfolio-container-heading">Duration : </p>
       </div>
    </div>
    <div className="seo-img-portfolio">
        <div className="seo-img-portfolio-container">
       <p className="seo-img-portfolio-container-heading">Project : Anand Flour Mill</p>
       <p className="seo-img-portfolio-container-heading">Keywords : no 1 flour mill in Asansol</p>
       <p className="seo-img-portfolio-container-heading">Rank : 1</p>
       <p className="seo-img-portfolio-container-heading">Duration : 6 months</p>
       </div>
    </div>
    <div className="seo-img-portfolio">
        <div className="seo-img-portfolio-container">
       <p className="seo-img-portfolio-container-heading">Project : Anand Flour Mill</p>
       <p className="seo-img-portfolio-container-heading">Keywords : no 1 flour mill in Asansol</p>
       <p className="seo-img-portfolio-container-heading">Rank : 1</p>
       <p className="seo-img-portfolio-container-heading">Duration : 6 months</p>
       </div>
    </div>
    <div className="seo-img-portfolio">
        <div className="seo-img-portfolio-container">
       <p className="seo-img-portfolio-container-heading">Project : Anand Flour Mill</p>
       <p className="seo-img-portfolio-container-heading">Keywords : no 1 flour mill in Asansol</p>
       <p className="seo-img-portfolio-container-heading">Rank : 1</p>
       <p className="seo-img-portfolio-container-heading">Duration : 6 months</p>
       </div>
    </div>
    <div className="seo-img-portfolio">
        <div className="seo-img-portfolio-container">
       <p className="seo-img-portfolio-container-heading">Project : Anand Flour Mill</p>
       <p className="seo-img-portfolio-container-heading">Keywords : no 1 flour mill in Asansol</p>
       <p className="seo-img-portfolio-container-heading">Rank : 1</p>
       <p className="seo-img-portfolio-container-heading">Duration : 6 months</p>
       </div>
    </div>
    </div>
    <Footer />
    </>
)
}
export default SeoDesign