import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Navbar from "../Navbar";
import AOS from "aos";
import Footer from "../Footer"
import Header from "../Header"
import NavbarInOtherPage from "../NavbarInOtherPage"
import "./index.css"

const PaymentPolicy=()=>{
    useEffect(() => {
        AOS.init({
          
          duration : 2000
        });
      }, []);
    
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    
    return(
        <>
      
       <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
        <div className="payment-policy-route-main-con">
            <h1 className="payment-policy-route-heading">Payment/Refund Terms</h1>
        </div>
        <div className="payment-policy-route-main-con-2">
            <h1 className="payment-policy-route-heading-2">Payment/Refund Terms</h1>
            <div className="payment-policy-route-con-2">
                <h1 className="payment-policy-route-heading-3">Payment/Refund Terms</h1>
                <p className="payment-policy-route-heading-para">➢ All prices are quoted in Australian dollars and are inclusive of GST unless otherwise specified.</p>
                <p className="payment-policy-route-heading-para">➢ Invoices are to be settled according to the agreed-upon schedule using your preferred payment method.</p>
                <p className="payment-policy-route-heading-para">➢ A 4% surcharge (covering banking fees) is applied to card payments.</p>
                <p className="payment-policy-route-heading-para">➢ Milestone payments are due within 5 working days of each milestone/schedule, unless mutually agreed otherwise. Final payment is required before the website/application/project goes live.</p>
                <p className="payment-policy-route-heading-para">➢ Payment receipt signifies acceptance of the quote, milestone, delivery, project, and terms & conditions.</p>
                <p className="payment-policy-route-heading-para">➢ Insu Creation reserves the right to negotiate and refund a suitable portion of the amount paid by the customer for the requested service. If an order is canceled before work commences, a refund of 75% of the payment will be issued. If canceled after work has begun, a maximum refund or charge of 50% of the invoice applies. No refund or adjustment is available if cancellation occurs after more than 50% of the work is completed. Cancellations must be made in writing via regular mail, email, or fax. Telephone requests for cancellations will not be accepted. For minor or one-off tasks, payments must be made in advance or as per the terms outlined in the invoice/proposal. Any changes to terms must be communicated in writing (emails accepted) within two working days of receipt.</p>
                <p className="payment-policy-route-heading-para">➢ Late fees and charges: All overdue invoices will incur a late payment fee of 10% of the outstanding amount and an administration fee of Rs. 500/month, calculated from the due date.</p>
                <p className="payment-policy-route-heading-para"><span className="due-dste-span">Due date:</span> The date on which payment is due as per the terms stated on the invoice/proposal.</p>
                <p className="payment-policy-route-heading-para"><span className="due-dste-span">Outstanding Invoice:</span> An invoice is considered outstanding if payment is still due after 14 days past the due date.</p>
                <p className="payment-policy-route-heading-para"><span className="due-dste-span">Debt Collection:</span> Following application of the late payment fee, the client has 7 additional days to settle the invoice. Failure to do so will result in the outstanding amount being referred to a debt collector. Insu Creation is not liable for any costs associated with recovering the outstanding amount. Any charges incurred (debt collectors, administration fees, legal costs) will be passed on to the client for processing.</p>
                <p className="payment-policy-route-heading-para">➢ If payment is delayed or if there are any questions regarding the invoice, please contact the Accounts Manager immediately upon receipt of the invoice or reminder emails.</p>
                <p className="payment-policy-route-heading-para">➢ Communication/correspondence is primarily conducted via email. It is the client’s responsibility to keep us informed of their relevant email addresses.</p>
                <p className="payment-policy-route-heading-para">➢ Payments related to domain name registration renewal, hosting, or any 3rd party products/services purchased on behalf of the client must be paid in full and are non-refundable. For renewal cancellations, we require notification at least 30 days before the renewal date.</p>
                <p className="payment-policy-route-heading-para">➢ Insu Creation typically builds and tests websites/applications on our servers or hosted domains. Transfer to a nominated 3rd party server occurs upon full payment of all invoices and dues.</p>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default PaymentPolicy