import React from 'react';
import Count from "../Count";

import './index.css';

const data= [
        {
            "id": "001",
			"image":"https://res.cloudinary.com/dlj7gj1po/image/upload/v1704709388/isoqvfw6lswvoij1qmjc.svg",
            "number": "900",
			"label":"Happy Clients",
            "duration": "2"
        },
        {
            "id": "002",
			"image":"https://res.cloudinary.com/dlj7gj1po/image/upload/v1704709458/opnpn4tsqs345yzcja25.svg",
            "number": "1000",
			"label":"Projects Done",
            "duration": "2"
        },
        {
            "id": "003",
			"image":"https://res.cloudinary.com/dlj7gj1po/image/upload/v1704709513/buolmjxkrhkprpqdfaby.svg",
            "number": "5",
			"label":"Years Served",
            "duration": "2"
        },
        {
            "id": "004",
			"image":"https://res.cloudinary.com/dlj7gj1po/image/upload/v1704709558/zbiox29gzwrp6eeczwig.svg",
            "number": "5",
			"label":"Foreign Projects",
            "duration": "2"
        }
    ]



const HappyClientsComponent = () => 
   (
    <div className="happy-clients-container">
      {data.map(count => <Count key={count.id} data={count}/>)}
    </div>
  );


export default HappyClientsComponent;