import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import { Helmet } from "react-helmet";
import {Link} from "react-router-dom"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const SocialMediaContent=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Elevate Your Brand: Insu Creation's Expert Social Media Content Creation</title>
  <meta name="description" content="At Insu Creation, we specialize in crafting professional, on-brand social media content tailored to your business goals. Increase visibility, save time and money, and boost SERP rankings with our SEO-friendly strategies. Elevate your brand today."/>
  <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
  </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Why Insu Creation Stands Out in Social Media Content Creation?</h1>
    <p className="transform-para transform-2-para">Elevate Your Brand with Professional, On-Brand Content: Insu Creation's adept team crafts custom, high-quality content that embodies your brand's voice seamlessly.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Our solutions are customized to align precisely with your unique business objectives, ensuring optimal resonance and impact.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Experience enhanced visibility and a growing follower base with our expertly crafted content strategies.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Save time and resources by entrusting your social media content creation to Insu Creation.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Our content is optimized for search engines, enhancing your brand's online visibility and reach.</p>
        </div>
      </li>
      
    </ul>
    <div className="no-transform-button-container">
    <Link to="contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
     <Link to="/schedule-meeting"> <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706177588/tkmdorpbqttf40qgbmvb.gif" className="seo-route-img-main social-media-route-img-main react-native-dev-image social-media-content-img-mob artile-write-img" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706177757/b1y6prb5snvpxmosl0wf.gif" className="react-native-mid-img social-media-content-img-mob artile-write-img" alt="" />

    <div>
        <h1 className="react-native-business-dev">Insu Creation: Crafting Visually Stunning Images, GIFs, Animations, and Videos</h1>
        <p className="react-native-business-dev-para">Insu Creation recognizes the significance of visually appealing and top-quality assets in social media content. With our dedicated team of skilled designers and creatives, we specialize in producing professional-grade images, GIFs, animations, and videos that effectively showcase our services and captivate our audience.</p>
        <p className="react-native-business-dev-para">From meticulously crafted visuals to engaging animations and captivating videos, we endeavor to create memorable assets that leave a lasting impression. Harness the power of visually striking and high-quality content for your social media endeavors with Insu Creation.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706177930/vxusddwd1x823festmr4.gif" className="react-native-mid-img social-media-content-img-n social-media-content-img-mob artile-write-img" alt="" />

    <div>
        <h1 className="react-native-business-dev">Insu Creation: Prioritizing Engaging and Informative Content Creation</h1>
        <p className="react-native-business-dev-para">Insu Creation places a strong emphasis on crafting engaging and informative content tailored to your services and audience's needs. Our approach involves crafting captivating captions, incorporating relevant hashtags for enhanced discoverability, and including compelling calls to action to drive engagement.</p>
        <p className="react-native-business-dev-para">By prioritizing these elements, we aim to deliver high-quality social media content that effectively resonates with our audience and promotes our services seamlessly.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>
 <div className="publish-articles-spec-con-main">
    <div className="publish-articles-spec-con">
        <h1 className="publish-articles-head">SOCIAL MEDIA CONTENT SYSTEM</h1>
        <div className="publish-article-images-container-main">
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706174752/pyngnn0wwctr6uwmfjdh.webp" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Search Engine Friendly Content</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706175357/dbr7y9oilsxxogb0zlrf.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Expert Written Content</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706175399/s4ly68csw2uhunikpfe6.webp" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">24/7 Service</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706175433/jiqslsyqowjxwddnkrvc.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Unique Content</h1>
            </div>
        </div>
    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default SocialMediaContent