import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import AOS from "aos"
import DetailsForm from "../DetailsForm";
import Header from "../Header"
import {Link} from "react-router-dom"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import Footer from "../Footer"

import "./index.css"

const WordPressDevelopment=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
  return(
    <>
    <Helmet>
    <title>Crafting Your Digital Masterpiece: Insu Creation's Artful WordPress Development</title>
    <meta name="description" content="Transform your online presence with Insu Creation's bespoke WordPress development solutions. From PSD to WordPress conversion to plugin development and theme customization, our expert team ensures SEO optimization and seamless functionality, empowering your website to stand out and succeed in the digital landscape." />
    <meta name="keywords" content="Insu Creation, website support, SEO, social media marketing, Custom Themes, Responsive Design, Plugin Development, eCommerce Solutions, Expert Optimization., online success" />
       </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head wp-new-head">Elevate Your Online Presence with Custom WordPress Development</h1>
    <p className="transform-para transform-2-para">Elevate Your Online Presence with Insu Creation's Custom WordPress Development
Transform your digital footprint with our tailored WordPress development services. Our seasoned developers specialize in crafting bespoke, intuitive, and visually stunning websites that resonate with your brand identity and spotlight your offerings.
</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para wp-new-li-right">Proven track record of delivering top-tier, successful projects.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Diverse portfolio showcasing our versatility across various industries.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para wp-new-li-right">Responsive, reliable, and transparent communication throughout the development journey.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para wp-new-li-right">Commitment to excellence ensuring every project aspect meets the highest standards.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Flexibility to adapt and accommodate changes seamlessly.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">  <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">  <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <div>
        <video
          style={{  height: "400px", margin: "0 auto",border:"none",background:"#ffffff" }}
          playsInline
          loop
          muted
          alt="All the devices"
          src="https://res.cloudinary.com/dlj7gj1po/video/upload/v1705646501/uval2cwpmn0qx0ck4n8i.mp4"
          ref={videoEl}
        />
      </div>
 </div>
 </div>
 <div className="word-press-dev-main-container">
    <div className="word-press-dev-inner-container">
        <h1 className="our-wordpress-development">Our WordPress Development Services</h1>
        <div>
            <div>
                <div className="wordpress-our-serv-container">
                    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705648537/gl4atfd7bacrtvhwm9qh.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">PSD TO WORDPRESS CONVERSION</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Transform your designs into functional WordPress themes with Insu Creation. Our expert team specializes in converting PSD files into ultra-responsive, SEO-optimized WordPress themes, ensuring your website ranks high on search engines.</p>
  </div>
    </div>
    </div>
    
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705649347/jv1ikojk6s6wndqithxg.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">WP THEME CUSTOMIZATION</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Customize your website with Insu Creation's stylish and user-friendly WordPress themes. Choose from our extensive collection of responsive themes and let us tailor them to match your unique design preferences.</p>
  </div>
    </div>
    </div>

     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705649632/idz3nk0biyv6xohuijz1.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">WORDPRESS PLUGIN DEVELOPMENT</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Unlock the full potential of your WordPress site with custom plugins developed by Insu Creation. Our experienced team creates bespoke plugins to meet your specific business requirements, catering to both small and large enterprises.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705648537/gl4atfd7bacrtvhwm9qh.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">WP MIGRATION AND MAINTENANCE</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Transition seamlessly to WordPress with Insu Creation's migration and maintenance services. Our comprehensive package includes plugin installation, backup services, and ongoing support to ensure a smooth migration process without data loss.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705649733/js8haj6v8ccmkxpowife.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">CUSTOM WORDPRESS DEVELOPMENT</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Stand out online with Insu Creation's creative custom WordPress development services. From design to implementation, we'll help you create a memorable web presence that resonates with your audience and drives business growth.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705649776/ysgozntcfyjjcrcjuwpk.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">CMS INSTALLATION | CONFIGURATION</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Integrate your development seamlessly with your website through Insu Creation's WordPress expertise. Our team specializes in WordPress web design and development, offering free and paid theme configuration services for a cohesive online presence.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705649811/djwogbxglxvpgcbkmtfs.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">RESPONSIVE WORDPRESS THEME DEVELOPMENT</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Stay ahead of the curve with Insu Creation's responsive WordPress theme development services. We create highly responsive websites tailored to your individual needs, ensuring optimal performance across all devices.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705649849/b4u2b2ba12j5zuwzw1sc.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">PSD TO WORDPRESS &CONVERSION</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Explore the potential of WooCommerce with Insu Creation's custom WordPress eCommerce solutions. We develop fully customized WooCommerce websites that are high-end, high-performance, and feature-rich, providing your customers with an unparalleled shopping experience.</p>
  </div>
    </div>
    </div>

         </div>
            </div>
        </div>
    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default WordPressDevelopment