import "./index.css"

const WhatWeDo=()=>(
   <div className="what-we-do-component-container-main">
    <div className="what-we-do-component-container-about">
        <img src={require('../InsuImages/image5.jpg')} className="what-we-do-img" alt="what-we-do-img" />
    <div className="what-we-do-component-container-details">
        <h1 className="what-we-do-component-heading what-we-do-component-heading-mob">What We Do?</h1>
        <p className="what-we-do-component-paragraph">As a digital marketing agency, Insu Creation excels in a variety of digital services aimed at boosting your online presence and elevating your brand. Our expertise includes web development, app development, graphics design, digital marketing, and content writing. Our team of experienced professionals provides customized solutions that connect with your target audience, so your path to digital success is smooth and rewarding.</p>
    <div>
        <p className="what-we-do-component-paragraph hr-pg">We Build Custom Web Applications And Websites</p>
        <hr className="horizontal-line-what-we-do" />
        <p className="what-we-do-component-paragraph hr-pg">We Provide Digital Marketing Services Like Search Engine Optimization, Search Engine Marketing And Social Media Marketing</p>
        <hr className="horizontal-line-what-we-do" />
        <p className="what-we-do-component-paragraph hr-pg">We Develop Offline And Online Softwares</p>
        <hr className="horizontal-line-what-we-do" />
        <p className="what-we-do-component-paragraph hr-pg">We Develop Mobile Applications, Android Applications, Native Application And Hybrid Applications</p>
        <hr className="horizontal-line-what-we-do" />
        <p className="what-we-do-component-paragraph hr-pg">We Provide Domain Registartion, Hosting, SSl Certifications, etc</p>
        <hr className="horizontal-line-what-we-do" />
    </div>
    </div>
    </div>
   </div>
)

export default WhatWeDo