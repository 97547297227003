import { useMediaQuery } from "react-responsive"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Header from "../Header"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./index.css"

const AppPortfolio=()=>{
  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
    <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
   
<div className="container-product-main-con">
<span className="our-locations-contact-us our-wk web-work-w">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Mobile App Portfolio</h1>
    <p className="portfolio-para-rote web-des-w-page mob-web-port-v-para">Experience the attractive mobile application with Insu Creation and explore your business in all the wide ranges.</p>
    <div className="container-product-main-con">
  <div className="container-product-main">
    <div className="app-portfolio-div">
 <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708608189/nlwhcjgfoixkcd5dcej5.png" alt="" className="app-portfolio-mobiles" />
 <h1 className="app-portfolio-div-head">Payment App</h1>
 </div>
 <div className="app-portfolio-div">
 <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708609606/c2rmkkfycrpgvg0ydo5d.jpg" alt="" className="app-portfolio-mobiles" />
 <h1 className="app-portfolio-div-head">GPS Tracking</h1>
 </div>
 <div className="app-portfolio-div">
 <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708609732/vzcf0l2ckywdtfmhhkzt.png" alt="" className="app-portfolio-mobiles" />
 <h1 className="app-portfolio-div-head">Music App</h1>
 </div>
 <div className="app-portfolio-div">
 <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708609815/zrmtimpn6rwivgldmydi.png" alt="" className="app-portfolio-mobiles" />
 <h1 className="app-portfolio-div-head">Pet Service</h1>
 </div>
 <div className="app-portfolio-div">
 <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708609950/oyovdh8dzq06rveh0vog.png" alt="" className="app-portfolio-mobiles" />
 <h1 className="app-portfolio-div-head">Discover</h1>
 </div>
 
</div>
</div>
</div>
<Footer />
</>
)
}
export default AppPortfolio
