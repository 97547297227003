import React from "react"
import { useMediaQuery } from "react-responsive"
import AOS from "aos"
import Header from "../Header"
import Navbar from "../Navbar"
import {Helmet} from "react-helmet"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Footer from "../Footer"
import "./index.css"

const Career=()=>{
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })

    return (
    <> 
    <Helmet>
    <title>”Join the Team: Explore Exciting Career Opportunities at InsuCreation"</title>
<meta name="description" content="Discover rewarding career opportunities at InsuCreation. Explore our diverse range of roles and join a dynamic team committed to innovation and excellence in the insurance industry. Start your journey with us today!"/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <div className="career-rote-container">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706764109/vkge8qcwnh1wrph15i03.png" alt="" className="careers-rote-img" />
        <div className="career-mob-con">
            <span className="career-rote-head">Career</span>
            <p className="career-rote-para">Build your Career in our Sector. Work with Us. Grow with Us.</p>
            <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Apply Now <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
        </div>
    </div>
    <div className="career-main-container-form">
    <span className="our-locations-contact-us our-wk web-work-w">Careers</span>
    <h1 className="graphic-route-ty-head ppt-head cc-head">Job Portal</h1>
    <p className="graphic-route-ty-para career-p-m"> We follow an extremely detailed process to serve you with your needs and deliver the best.</p>
            <div className="career-img-form-cont">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706765164/s51jxce0zxmeftuluzdn.png" alt="" className="career-rote-detail-img" />
                <form className="career-inner-form-div-main" onSubmit={(event)=>event.preventDefault()}>
                    <div className="career-inner-form-div">
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="career-head">Name<span className="career-name-star-form">*</span></label>
                            <input type="text" id="career-head" className="career-input-text" placeholder="Enter Your Name" />
                        </div>
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="Email">Email<span className="career-name-star-form">*</span></label>
                            <input type="email" id="Email" className="career-input-text" placeholder="Enter Your Email" />
                        </div>
                    </div>
                    <div className="career-inner-form-div">
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="number">Phone<span className="career-name-star-form">*</span></label>
                            <input type="number" id="number" className="career-input-text" placeholder="Enter Your Phone no" />
                        </div>
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="career-qualification">Qualification<span className="career-name-star-form">*</span></label>
                            <input type="text" id="career-qualification" className="career-input-text" placeholder="Enter Your Qualification" />
                        </div>
                    </div>
                    <div className="career-inner-form-div">
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="github-profile">Github Profile<span className="career-name-star-form">*</span></label>
                            <input type="text" id="github-profile" className="career-input-text" placeholder="Github Link" />
                        </div>
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="experience">Experience<span className="career-name-star-form">*</span></label>
                            <input type="text" id="experience" className="career-input-text" placeholder="Enter Your Experience" />
                        </div>
                    </div>
                    <div className="career-inner-form-div">
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="city">City<span className="career-name-star-form">*</span></label>
                            <input type="text" id="city" className="career-input-text" placeholder="Enter Your City" />
                        </div>
                        <div className="career-name-form-cont">
                            <label className="career-name-form" for="state">State<span className="career-name-star-form">*</span></label>
                            <input type="text" id="state" className="career-input-text" placeholder="Enter Your State" />
                        </div>
                    </div>
                    <div className="career-name-form-cont career-name-form-cont-2">
                            <label className="career-name-form" for="zip">Zip<span className="career-name-star-form">*</span></label>
                            <input type="text" id="zip" className="career-input-text" placeholder="Enter Your Zip" />
                        </div>

                        <div className="career-inner-form-div">
                        <div className="career-name-form-cont">
                        <label className="career-name-form career-apply-job" for="select-1">Apply Job Type<span className="career-name-star-form">*</span></label>
                        <select className="career-input-text career-input-text-select" id="select-1">
                           <option disabled selected>Choose</option>
                            <option value="op1">Part Time</option>
                            <option value="op2">Full Time</option>
                             <option value="op3">Internship</option>
                         </select>
                        </div>
                        <div className="career-name-form-cont">
                        <label className="career-name-form career-apply-job" for="select-2">Apply For<span className="career-name-star-form">*</span></label>
                        <select className="career-input-text career-input-text-select" id="select-2">
                           <option disabled selected>Choose</option>
                            <option value="op4">MERN Developer(Full Stack)</option>
                            <option value="op5">Full Stack Developer(Laravel)</option>
                             <option value="op6">Flutter Developer(Full Stack)</option>
                            <option value="op6">Graphics Designer</option>
                            <option value="op7">Digital Marketer</option>
                             <option value="op8">Customer Relationship Manager</option>
                             <option value="op9">Core PHP Developer(Full Stack)</option>
                         </select>
                        </div>
                        </div>
                        <div className="submit-career-details">
                        <button type="Submit" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Submit <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
                    </button>
                    </div>
                </form>
        </div>
    </div>
    <Footer />
    </>
)}
AOS.init()
export default Career