import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Navbar from "../Navbar";
import AOS from "aos";
import Footer from "../Footer"
import {Helmet} from "react-helmet"
import Header from "../Header"
import NavbarInOtherPage from "../NavbarInOtherPage"
import "./index.css"

const PrivacyPolicy=()=>{
    useEffect(() => {
        AOS.init({
          
          duration : 2000
        });
      }, []);
    
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    
    return(
        <>
        <Helmet>
        <title>Insu Creation: Safeguarding Your Privacy and Defining Service Terms</title>
<meta name="description" content="Explore Insu Creation's comprehensive Privacy Policy and Terms of Service. Learn how we prioritize your privacy and outline the terms governing our services. Your trust and satisfaction are our top priorities."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
       <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
        <div className="payment-policy-route-main-con"> 
            <h1 className="payment-policy-route-heading">PRIVACY POLICY</h1>
        </div>
        <div className="payment-policy-route-main-con-2">
            <h1 className="payment-policy-route-heading-2">PRIVACY POLICY AND TERMS OF SERVICE</h1>
            <p className="payment-policy-route-heading-para-1">Welcome to the website "www.insucreation.in," referred to as "Insu Creation" or "this Website."</p>

            <div className="payment-policy-route-con-2">
            <h1 className="payment-policy-route-heading-3 privacy-policy-h">Ownership</h1>
                <p className="payment-policy-route-heading-para">➢ This site is owned by Insu Creation., referred to as "Insu Creation" We aim to protect the information you provide us. This Privacy Policy outlines our practices regarding collection, use, and disclosure of information you may provide via this site. Please read this Privacy Policy thoroughly before using or submitting information to this site. Your use of this site implies consent to our Privacy Policy.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Intellectual copyright</h1>

                <p className="payment-policy-route-heading-para">➢ All information on www.insucreation.in is protected by copyright and other intellectual property laws. You may not modify, publish, distribute, repost, perform, display, or commercially exploit any content on this website under any circumstances.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Your Consent</h1>

                <p className="payment-policy-route-heading-para">➢ By using this site, you agree to the terms of this Privacy Policy. When you submit information via this site, you consent to its collection, use, and disclosure in accordance with this Privacy Policy.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Active Information Collection</h1>

                <p className="payment-policy-route-heading-para">➢ This site actively collects information from visitors through email and feedback forms. Some information may be personally identifiable (e.g., full name, address) and is collected for specific features (e.g., newsletter subscriptions). You will be informed at each collection point what information is required and optional.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Passive Information Collection</h1>

                <p className="payment-policy-route-heading-para">➢ As you navigate this site, certain information may be collected passively, such as navigational data. This includes the use of Internet Protocol (IP) addresses for server diagnosis and site administration.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Use and Disclosure of Information</h1>

                <p className="payment-policy-route-heading-para">➢ We use collected information to improve site content, customize preferences, and communicate with users. Personally identifiable information may be combined with actively collected information, and reasonable measures are taken to prevent such combination unless otherwise consented.</p>
                
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Links from Other Websites</h1>

<p className="payment-policy-route-heading-para">➢ This site may contain links to third-party websites with separate data protection policies. It is advised to review these policies before using those sites.</p>
<h1 className="payment-policy-route-heading-3 privacy-policy-h">Security</h1>

<p className="payment-policy-route-heading-para">➢ Efforts are made to protect transmitted information from loss, misuse, and unauthorized access. However, internet data transmissions are not guaranteed to be 100% secure.</p>
<h1 className="payment-policy-route-heading-3 privacy-policy-h">Disclaimer</h1>

<p className="payment-policy-route-heading-para">➢ This site may contain inaccuracies and typographical errors. WebInGo® Infotech does not warrant the accuracy or completeness of materials on this site. The site and its content are provided "As Is" without warranty of any kind.</p>
<h1 className="payment-policy-route-heading-3 privacy-policy-h">Other Terms</h1>

<p className="payment-policy-route-heading-para">➢ Credit card orders commence upon authorization. Graphic designs and websites created by Insu Creation are sent to clients via email or FTP upload. Orders once placed cannot be terminated or transferred.</p>
<h1 className="payment-policy-route-heading-3 privacy-policy-h">How to Contact Us?</h1>
<p className="payment-policy-route-heading-para">➢ For inquiries about this Privacy Policy or site information practices, please use the "contact us" link on this Website.</p>
<h1 className="payment-policy-route-heading-3 privacy-policy-h">Changes to This Privacy Policy</h1>
<p className="payment-policy-route-heading-para">➢ Revised policies will be posted on this site. Please check periodically, especially before providing any personally identifiable information.</p>

            </div>
        </div>
        <Footer />
        </>
    )
}

export default PrivacyPolicy