import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import {Helmet} from "react-helmet"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const SocialMediaMarketing=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
     <Helmet>
     <title>Spark Social Magic: Insu Creation's Expertise Unleashed for Transformative Social Media Marketing</title>
     <meta name="description" content="Experience unparalleled social media magic with Insu Creation. Our expert team crafts captivating content, drives engagement, and boosts your brand's online presence, ensuring you stand out in the digital crowd."/>
     <meta name="keywords" content="Insu Creation, SEO, 
social media marketing, expert social media services, social media management, digital marketing agency, social media strategy, content creation, online branding, engagement optimization, social media growth, audience targeting, influencer collaboration, viral content creation" />


     </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Transform and Amplify Your Social Media Presence with the Expertise of Insu Creation's Professional Content Creation Services.</h1>
    <p className="transform-para transform-2-para">Are you finding it challenging to keep pace with the relentless demand for fresh and captivating content across social media platforms? Look no further than Insu Creation's team of professional content creators to alleviate this burden. With our specialized expertise, we excel in curating high-quality, visually appealing content that not only resonates with your target audience but also sets you apart in the competitive social media landscape. Let us empower your brand to stand out and engage effectively with your audience.
</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">We provide premium, visually stunning content creation services tailored to your brand.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Our array of content creation services caters to diverse needs and preferences.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para"> Insu Creation keeps abreast of current social media trends and algorithms to ensure optimal performance.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Our adept team of content creators possesses the expertise to develop compelling and impactful content for your brand.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">At Insu Creation, we prioritize understanding your business and its distinct requirements to deliver bespoke content solutions.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705908064/fifyug3vbhbtjavmz3kk.gif" className="seo-route-img-main social-media-route-img-main social-media-route-img-main-mobile" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="word-press-dev-main-container">
    <div className="word-press-dev-inner-container">
        <h1 className="our-wordpress-development">why social media is important for your business?</h1>
        <div>
            <div>
                <div className="wordpress-our-serv-container">
                    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909075/drwf0fhlj4lvkisjrvtx.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Builds Your Network And Brand</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">As one of the best social media marketing entrepreneurs, we make it easy to get people to care about you, your business, or your social cause. We build, manage, and grow your network of subscribers so you can focus on running your business or nonprofit.</p>
  </div>
    </div>
    </div>
    
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909130/ket3sfindntz4wkcxl7y.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Engage Potential Customers</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Working with Social Media Marketing Services will help you engage potential customers with top-notch content that converts. Engage potential customers through a powerful combination of Keywords, Media, and Hashtags that truly matters</p>
  </div>
    </div>
    </div>

     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909167/hisvh39bbknwzghlexcs.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">SMM Improves Your Online Image</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Most businesses understand that social media marketing is the new frontier of SEO. But many don’t realize that the first step to effective SMM is a strong social presence. Improve your ranking, earn more fans and inspire others to join you.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909240/zla2tagsc5sj2jy9108b.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Always In Touch With Your Audience</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">From Facebook to Instagram, LinkedIn to Twitter, and everything in between, social media help businesses get in touch with their audiences. Small businesses and entrepreneurs who are just getting started can use social media to boost their businesses.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909314/muyic13wpdsmkvzu83ew.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Grow Your Business Brand & Reputation</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Social Media is a great way to reach new clients and bring current clients back into your business.
Today's businesses need a strong social media presence to succeed. Let's make your business social!</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909347/rqz1itsaaafuxvf67c33.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Get Greater Visibility At Little Or No Cost</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">You should consider incorporating social media into your marketing plan. Social media is a great way to reach out and connect with new audiences and increase brand awareness, customer loyalty, and sales - with a little upfront cost.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909457/m3i6c3slv2kjxnbnjs2b.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Reach Out To Customers Directly</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">The best part about social media is that it allows you to reach out to customers directly using Facebook, Twitter, Instagram, and other channels. Now, you don't have to depend on advertisements to get your business noticed.</p>
  </div>
    </div>
    </div>
     
    <div className="wordPress-new-tech-con">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705909505/c8efeoano2ycezuddubw.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress" alt="" />
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head">Stay Ahead Of Your Competitors</h1>
  <div className="wordpress-para-container-main">
    <p className="wordpress-para-container">Your competitors are already on Social Media.If you want to be successful in your industry, you need to start using Social Media Marketing Services to reach out to your target customers.</p>
  </div>
    </div>
    </div>

         </div>
            </div>
        </div>
    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default SocialMediaMarketing