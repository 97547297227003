import { useState } from "react";
import {Link} from "react-router-dom"
import { Icon } from '@iconify/react';
import AboutDropdown from '../AboutDropdown';
import ServicesDropdown from "../ServicesDropdown"

import "./index.css"
import DropDownContact from "../DropDownContact";

const Navbar=()=>{
    const [dropdown,setDropdown]=useState(false)
    const [dropdown2,setDropdown2]=useState(false)
    const [color,setColor]=useState(false)
    const [dropdownContact,setDropdownContact]=useState(false)


    const changeColor=()=>{
        window.scrollY>=80 ? setColor(true) : setColor(false);
    }
    
    window.addEventListener("scroll",changeColor)

    return  (
        <nav>
    <div className={color?"nav-main-container nav-main-container-ot-2":"nav-main-container"}>
    <div className="nav-container">
      <Link to="/">
      <div className="nav-logo-container">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705467975/dq6fffxfngksbidhqcz5.png" className="particle-nav-container" alt="particle-nav-container" />
        <h1 className="kreeti-technologies-heading other-page-color other-page-color-2 nav-color-link">Insu Creation</h1>
      </div>
      </Link>
      <ul className="list-container-navbar">
      <li className="nav-link-list-item other-page-color">
      <Link to="/" className="nav-ther-page-link-about-us">
        <div className='nav-link-item-container portfolio-nav'>
        HOME
        </div>
        </Link>
        </li>
      <li className="nav-link-list-item about-ot" onMouseEnter={()=>setDropdown(true)} onMouseLeave={()=>setDropdown(false)}>
        <Link to="/aboutus" className="nav-link-about-us other-page-color">
        <div className='nav-link-item-container portfolio-nav'>
        ABOUT US<Icon icon="bx:chevron-down" className="arrow-down-icon" />
        </div>
        {dropdown&&<AboutDropdown newDr2={color} />}
        </Link>
        </li>
        <li className="nav-link-list-item about-ot other-page-color" onMouseEnter={()=>setDropdown2(true)} onMouseLeave={()=>setDropdown2(false)}> 
        <div className='nav-link-item-container portfolio-nav'>
        SERVICES<Icon icon="bx:chevron-down" className="arrow-down-icon" />
        </div>
        {dropdown2 && <ServicesDropdown newDr3={color} />}
        </li>
        <li className="nav-link-list-item other-page-color">
        <Link to="/portfolio" className="nav-ther-page-link-about-us">
        <div className='nav-link-item-container portfolio-nav'>
        PORTFOLIO
        </div>
        </Link>
        </li>
        <li className="nav-link-list-item portfolio-nav other-page-color">
        <Link to="/career" className="nav-link-about-us other-page-color">
        <div className='nav-link-item-container'>
        CAREERS
        </div>
        </Link>
        </li>
        <li className="nav-link-list-item about-ot" onMouseEnter={()=>setDropdownContact(true)} onMouseLeave={()=>setDropdownContact(false)}>
        <Link to="/aboutus" className="nav-link-about-us other-page-color">
        <div className='nav-link-item-container portfolio-nav'>
        CONTACT US<Icon icon="bx:chevron-down" className="arrow-down-icon" />
        </div>
        {dropdownContact&&<DropDownContact newDr2={color} />}
        </Link>
        </li>
      </ul>
    </div>
  </div>
    </nav>
)
}
export default Navbar