import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import Header from "../Header"
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const BusinessPresentation=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Unlocking Insu Creation: The Power of Graphic Design for Small Businesses</title>
<meta name="description" content="Discover how strategic graphic design in insu creation can elevate your small business, fostering lasting impressions and competitive advantage. Learn how to transform your brand, captivate consumers, and stand out amidst competitors."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
</Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">In insu creation, prioritizing customer satisfaction emerges as the paramount business strategy.</h1>
    <p className="transform-para transform-2-para">It's not merely about how your company is acknowledged; it's about crafting a memorable presence that resonates.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Cultivate positive impressions to sculpt a resilient brand image.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Forge enduring relationships to foster deeper connections.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Articulate messages effectively to captivate and persuade your audience.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Drive desired outcomes, whether in sales, services, or employee motivation.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Demonstrate expertise and fortify core values pivotal to your company culture.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706346488/q7u2oerdczuemy2r9d9s.gif" className="seo-route-img-main business-pre-img business-mob-pre-img artile-write-img-vv-1" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706346605/hrbpsya2cd7mqsq7q0uq.gif" className="react-native-mid-img business-mob-pre-img artile-write-img-vv-1" alt="" />

    <div>
        <h1 className="react-native-business-dev">In insu creation, take into account the visual presentation of your work.</h1>
        <p className="react-native-business-dev-para">Begin by understanding your audience - knowing who will engage with your creation is crucial for delivering relevant content, stories, and visuals.</p>
        <p className="react-native-business-dev-para">Stay abreast of current trends in your field through diligent research.</p>
        <p className="react-native-business-dev-para">Developing an outline can assist in organizing your ideas and maintaining focus on pertinent material throughout your presentation.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>
    </div>
 </div>

<div className="complete-brand-con-main" data-aos="zoom-in">
  <div className="complete-brand-con">
    <div className="complete-brand-benefits-con">
      <h1 className="branding-benefits">Powerful Insu Creation: Leveraging Professionally Designed PowerPoint Presentations</h1>
      <p className="branding-benefits-para">A professionally designed PowerPoint deck not only bolsters credibility and authority but also enhances brand impact, leaving a lasting first impression and instilling confidence. Most importantly, it shapes audience perception, influencing their view of your expertise and credibility, ultimately impacting their decision to engage with your business.</p>
      <ul>
        <li className="branding-benefits-list-item"># Engaging designs that captivate.</li>
        <li className="branding-benefits-list-item"># Informative content that educates.</li>
        <li className="branding-benefits-list-item"># Seamless flow for enhanced understanding.</li>
        <li className="branding-benefits-list-item"># Contact Us for More Information</li>

      </ul>
    <Link to="/contactus">  <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Enquire Now <i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    </div>
    <div>
      <h1 className="branding-benefits branding-benefits-2">Advantages of PowerPoint Presentations in Insu Creation</h1>
      <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para"> Simplifies complex information.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Positions you as a thought leader.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Establishes a strong business identity.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Adds greater value to your company.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Sets you apart from competitors.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Ensures clarity and comprehension of concepts.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Demonstrates your company's professionalism.</p>
        </div>
      </li>
    </ul>
    </div>
  </div>
</div>


 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706346666/y99oyzem0mtx7rjcenag.gif" className="react-native-mid-img social-media-content-img-n business-mob-pre-img" alt="" />

    <div>
        <h1 className="react-native-business-dev">In insu creation, adhere to the fundamental principle: Understand your customer's perspective.</h1>
        <p className="react-native-business-dev-para">Business presentations serve as vital tools to convey crucial ideas and steer outcomes, whether it involves pitching a product, motivating employees, or showcasing expertise integral to your company culture.</p>
        <p className="react-native-business-dev-para">To excel as a presenter, thorough preparation is key. Demonstrate a deep understanding of your topic, establish clear objectives for your presentation, and anticipate potential audience inquiries.</p>
        <p className="react-native-business-dev-para">On presentation day, ensure your slides are well-prepared, verify you have all necessary equipment, and confirm the time and venue. Organization is paramount, enabling you to adeptly address any unforeseen issues that may arise.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>

 <div className="graphic-route-ty-head-main-container packaging-back-banner-img" data-aos="zoom-in">
    <div className="graphic-route-ty-head-main-container-1">
    <span className="our-locations-contact-us our-wk web-work-w">WE DELIVER OUR BEST</span>
    <h1 className="graphic-route-ty-head ppt-head ppt-head-mob">We provide a wide array of Presentation Solution according to your needs</h1>
    <p className="graphic-route-ty-para business-mobile-para-n">Get your Customized Presentation based on your audience and your business.</p>
    <div className="graphic-route-ty-item-con">
        <div className="graphic-design-ty-list-items mobile-logo-desi-con mobile-logo-desi-con-bp">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706347673/ytreangh0dcganiderbf.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Training & Workshop Presentation</h1>
                <p>A great presentation describing the values you provide helps you bring in more learners.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con mobile-logo-desi-con-bp">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706347813/ang0zgbsdlv6nwqt9zc9.jpg" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Corporate Presentations</h1>
                <p>An attractive and informative presentation is all you need to build your team’s trust in you.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con mobile-logo-desi-con-bp">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706347864/pdx1qbyne4lbcmataixr.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Sales Pitch Decks</h1>
                <p>Get a sales presentation so alluring that it confirms your sales in one go.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con mobile-logo-desi-con-bp">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348032/pwlvkmzdmqcagfoxdmdu.jpg" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Event & Exhibition</h1>
                <p>Turn heads around with our presentations that they believe more in you and your services.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con mobile-logo-desi-con-bp">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348094/l88ovxch5dqxip1dhutk.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Product Presentations</h1>
                <p>With a great presentation, make sure you have provided everything you and your products are about to gain trust in your product.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con mobile-logo-desi-con-bp">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348142/tzqnfhxv22rry6aprfll.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Investment Deck Presentation</h1>
                <p>Convincing Investors is never an easy job. But make it less difficult with your presentation and build more trust in your business and idea.</p>
            </div>
        </div>
    </div>
    </div>
 </div>

 <div className="web-des-w">
    <span className="our-locations-contact-us our-wk web-work-w">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote bp-types">Business Presentation</h1>
    <p className="portfolio-para-rote web-des-w-page business-mobile-para-n">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
        <div className="new-anima-con">
<div class="wrapper-w website-design-w card-mobile-view-ani-1">
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348403/hvbb0jimgnuhwdco8qv3.jpg" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Comming Soon</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348523/h3jyjzrcvfrbfa1bkrb9.jpg" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Comming Soon</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348403/hvbb0jimgnuhwdco8qv3.jpg" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Comming Soon</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348523/h3jyjzrcvfrbfa1bkrb9.jpg" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Comming Soon</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348403/hvbb0jimgnuhwdco8qv3.jpg" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Comming Soon</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706348523/h3jyjzrcvfrbfa1bkrb9.jpg" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Comming Soon</h1>
      <p>Website</p>
    </div>
  </div>

</div>
</div>
</div>



 <Footer />
    </>
)}
AOS.init()
export default BusinessPresentation