import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet"
import AOS from "aos"
import Header from "../Header"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const ArticleWriting=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);


  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
     <Helmet>
     <title>Unlock Success: Elevate Your Content with Insu Creation's Expert Article Writing Services</title>
<meta name="description" content="Transform your online presence with Insu Creation's expert article writing services. Our seasoned team crafts engaging, high-quality articles tailored to showcase your expertise and captivate your audience. Experience swift turnaround times, affordable rates, and content that reflects your brand's identity. Elevate your content game today!"/>


<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
</Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Elevate Your Content with Insu Creation's Expert Article Writing Services</h1>
    <p className="transform-para transform-2-para">Distinguish yourself from competitors with Insu Creation's professional article writing services. Our seasoned team of writers is dedicated to crafting captivating, high-quality articles that highlight your expertise and resonate with your target audience. Benefit from affordable rates while enjoying:</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Experienced writers at your service</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Engaging, thoroughly researched articles</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Swift turnaround times</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Budget-friendly rates</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">High-quality writing that mirrors your brand's identity</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706173806/eaha6gw69xbw5putvh3q.gif" className="seo-route-img-main social-media-route-img-main react-native-dev-image artile-write-img" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706174191/n36nyxgwllrqrfh44eg8.gif" className="react-native-mid-img artile-write-img" alt="" />

    <div>
        <h1 className="react-native-business-dev">Why Opt for Insu Creation's Expert Article Writing Service?</h1>
        <p className="react-native-business-dev-para">1. Rely on our seasoned team's extensive experience in crafting articles for businesses. With Insu Creation, you gain access to a wealth of knowledge and expertise, ensuring your content is in capable hands.</p>
        <p className="react-native-business-dev-para">2. Experience top-notch quality with Insu Creation's articles. Our commitment to excellence has garnered accolades and satisfied clients, making us a trusted choice for businesses looking for premium content.</p>
        <p className="react-native-business-dev-para">3. Enhance your online visibility with SEO-optimized articles from Insu Creation. We understand the significance of search engine rankings and tailor our writing to boost your website's traffic and attract potential customers effectively.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>
 <div className="publish-articles-spec-con-main">
    <div className="publish-articles-spec-con">
        <h1 className="publish-articles-head">PUBLISH ARTICLES THAT CAPTURES ATTENTION</h1>
        <p className="publish-articles-para">Writng contents that rank high is tough and making a content fruitful enough for search engines to discover at display at the first page is even togher. You need very high knowledge on SEO to do it, yet it is very competitive to do so. Leave these for our experts to do it easily, effectively and within less time and take care of your Search Engine Ranking deeply.</p>
    </div>
    <div className="publish-articles-spec-con">
        <h1 className="publish-articles-head">Article Related Information</h1>
        <div className="publish-article-images-container-main">
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706174752/pyngnn0wwctr6uwmfjdh.webp" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Search Engine Friendly Content</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706175357/dbr7y9oilsxxogb0zlrf.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Expert Written Content</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706175399/s4ly68csw2uhunikpfe6.webp" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">24/7 Service</h1>
            </div>
            <div className="publish-article-images-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706175433/jiqslsyqowjxwddnkrvc.png" className="article-writing-sm-img" alt="" />
                <h1 className="search-engine-friendly-content">Unique Content</h1>
            </div>
        </div>
    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default ArticleWriting