import {Link} from "react-router-dom"
import "./index.css"

const AboutUsDetails=()=>(
    <div className="about-component-main-details">
        <div className="about-component-main-details-1">
        <div>
            <h1 className="about-us-component-heading">About Us</h1>
            <p className="about-us-component-paragraph">With Insu Creation, you can turn your digital dreams into reality with the help of a team of innovative designers and developers. As a passionate team, we design user-centric websites and mobile apps using cutting-edge technologies. On the path to digital success, let us be your partner.</p>
           <Link to="/contactus"> <button type="button" className="about-us-component-button">CONTACT US</button></Link>
        </div>
        <img src={require('../InsuImages/image4.png')} className="about-component-img" alt="about-component-img" />
    </div>
    </div>
)

export default AboutUsDetails