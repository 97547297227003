import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import Navbar from "../Navbar";
import {Link} from "react-router-dom"
import { Helmet } from "react-helmet";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const TechnicalSeoServices=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);


  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
     <Helmet>
     <title>Technical SEO Mastery: Enhance Your Insu Creation Performance Today</title>
    <meta name="description" content="Unlock the full potential of your Insu Creation business with our expert technical SEO solutions. From optimizing website structure to enhancing page speed and beyond, we ensure your online presence is primed for success. Elevate your Insu Creation strategy with our proven expertise."/>

    <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Top-tier Insu Creation Agency in India for Hire</h1>
    <p className="transform-para transform-2-para">Elevate your business with our elite team of IT and outsourcing professionals specializing in Insu Creation. Whether you require web application development, e-commerce solutions, content creation, or digital marketing services, we have the expertise you need.
</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Innovative web and e-commerce development solutions tailored for Insu Creation.
</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Results-oriented digital marketing strategies crafted specifically for Insu Creation businesses.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Premier SEO experts in India dedicated to optimizing your Insu Creation presence.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Swift Onboarding and Flexible Work Hours to accommodate your needs.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Agile Development methodologies and Exceptional Customer Support for a seamless experience.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
     <Link to="/schedule-meeting"> <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705990424/cnkrwnlvh00dk9gnd9w4.gif" className="seo-route-img-main social-media-route-img-main local-seo-mobile-img social-media-route-img-main-mobile" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="seo-services-we-provide-timeline-main">
      <h1 className="seo-services-we-provide-heading">Technical SEO ALL TOOLS</h1>
      <div className="seo-new-tools-list-container">
      <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705991139/jpie2qkf4mp1glqodxar.webp" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Screaming Frog</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Screaming Frog is a search engine optimization (SEO) tool that can crawl websites to analyze their structure and identify technical issues. Screaming Frog can be used on both small and large websites</p>
  </div>
    </div>
    </div>
    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705991382/d0mp2ns6ceaumxjqnkew.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">GTmetrix</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">GTmetrix is a free tool that analyzes a website's speed and performance. It evaluates a site's load time, and then generates a score with recommendations for improvement. GTmetrix also offers actionable insights to optimize a site's performance.</p>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705991652/ikyljmid90oqgnq4pgyu.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Botify</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Botify is a global enterprise software company that helps brands use organic search as a performance marketing channel. Botify's platform and full-funnel methodology help brands generate sustainable traffic and revenue growth from organic search.  </p>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705991760/wh50hzvjobalktfaifni.jpg" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">BackLinks</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Backlinks are an important part of SEO campaigns because they help improve a site's authority and trust, which can lead to higher rankings. The free backlink checker tool makes it easy to check the backlinks referring to your website.</p>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705991911/jnfjl9ys8imp2xsjwicn.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">SEOquake</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">SEOquake is a free browser plugin that provides SEO metrics for a specific page. It also includes other tools, such as SEO Audit. SEOquake is a free plugin for browsers that provides you with key SEO metrics for a specific page.</p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705992063/e8h51xalb2p5j7gajei2.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Mozbar</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">MozBar is a free Chrome extension and Firefox add-on that provides SEO metrics for websites and their link profiles. It displays link metrics for pages and domains as you search, and number of backlinks for sites. </p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705992267/dinf3emxy4fvacebixj0.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Barracuda</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Barracuda Panguin is a free SEO tool that helps agencies understand how Google algorithm updates affect website performance. It's considered a vital tool for agencies.</p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705992503/zytqhbmkmlfwluhyt375.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Majestic</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Majestic SEO is an SEO software tool that specializes in link analysis. So unlike Ahrefs, SEMrush or Moz Pro, Majestic is all about backlinks. . It crawls the web for links to build a view of a website's backlinks profile.

 </p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705992642/f30qxigmlv16x68ihgj6.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Deepcrawl</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">DeepCrawl enables you to visualize your site from different angles. That will help you identify opportunities for improvement. The tool also provides a complete view of your site architecture.

</p>
  </div>
    </div>
    </div>


      </div>
    </div>
 <Footer />
    </>
)}
AOS.init()
export default TechnicalSeoServices