import React,{useEffect} from "react"
import Aos from "aos"
import "aos/dist/aos.css"
import {motion} from "framer-motion"
import {Link} from "react-router-dom"
import "./style.css"


const OurServices=()=>{
   useEffect(()=>{
      Aos.init({duration:2000})
   },[]);
   return (
    <div>
    <section class="about-sec-rpb pad-tb col-our-ser-cng our-services-bg">
         <div class="container">
            <div class="row justify-content-center text-center">
               <div class="col-lg-10"> 
                  <div class="common-heading mobile-innovate-our-div">
                     <h3 class="head-ani-gradient">WE ARE INNOVATIVE SOLUTION PROVIDERS</h3>
                     <h1 class="mb30 top-rated-container-head mobile-innovate-our-head">Company offering <span class="text-second top-rated-container-head-span">top-of-the-line</span> IT solutions</h1>
                     <div class="our-ser-para-container">
                     <p class="our-ser-para our-ser-para-col">Insu Creation <span class="text-radius text-light text-animation bg-b">top-tier solutions</span> help you excel in IT. Your trusted IT partner, Insu Creation. Creating tailored solutions for your unique business needs is what we do. Our expertise covers web development, app development, digital marketing, and graphics design. Integrate seamlessly, receive 24/7 support, and enjoy unmatched reliability. Get in touch with us to learn how we've helped clients elevate their businesses. Bring success to your IT infrastructure by transforming it. Explore our digital solutions to get started on your digital journey.
                     </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section class="service-section-prb pad-tb">
         <div class="container cont-2">
            <div class="row upset cont-2">
               <motion.div whileHover={{scale:1.09}} data-aos="fade-up"  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus wow fadeInUp integrated-con-mobile-view" data-wow-delay=".2s">
                  <div class="service-sec-brp srvc-bg-nx bg-gradient13 text-w integrated-container-chng-back">
                     <h4 class="mb10">INTEGRATED SERVICES</h4>
                     <p>Insu Creation has diligently integrated a comprehensive range of services under one roof, sparing you the hassle of searching door-to-door for top-notch quality.</p>
                     <a href="javascript:void(0)" class="mt20 link-prb">Learn More <i class="fas fa-chevron-right fa-icon"></i></a>
                  </div>
               </motion.div>
               <motion.div className="integrated-con-mobile-view" whileHover={{scale:1.09}} data-aos="fade-up"  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp integrated-con-mobile-view" data-wow-delay=".4s">
                  <div class="service-sec-list srvc-bg-nx srcl1 integrated-con-mobile-view-inner">
                     <img src={require('../InsuImages/appdevelopment.png')} className="developent-img" alt="service" />
                     <h5 class="mb10">Web Development</h5>
                     <ul class="-service-list">
                        <li> <a href="#">React.JS </a> </li>
                        <li> <a href="#">Node.JS </a> </li>
                        <li> <a href="#"> Angular.JS </a></li>
                     </ul>
                     <p className="integrated-con-mobile-view-inner-para">At Insu Creation, we sculpt and nurture websites to epitomize excellence, offering aesthetically pleasing designs and flawlessly operational interfaces for an unmatched user journey.</p>
                  </div>
               </motion.div>
               <motion.div className="integrated-con-mobile-view" whileHover={{scale:1.09}} data-aos="fade-up" data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus wow fadeInUp integrated-con-mobile-view" data-wow-delay=".6s">
                  <div class="service-sec-list srvc-bg-nx srcl2 integrated-con-mobile-view-inner">
                     <img src={require('../InsuImages/graphicdesign.png')} className="developent-img" alt="service" />
                     <h5 class="mb10">Graphics Design</h5>
                     <ul class="-service-list">
                        <li> <a href="#">Canva </a> </li>
                        <li> <a href="#">Figma</a> </li>
                        <li> <a href="#">Sketch </a> </li>
                        <li> <a href="#">Photoshop</a></li>
                     </ul>
                     <p className="integrated-con-mobile-view-inner-para">At Insu Creation, we excel in the art of visual communication and problem-solving, leveraging typography, photography, illustrations, and more to craft captivating and innovative styles.</p>
                  </div>
               </motion.div>
               <motion.div className="integrated-con-mobile-view" whileHover={{scale:1.09}} data-aos="fade-up"  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp integrated-con-mobile-view" data-wow-delay=".8s">
                  <div class="service-sec-list srvc-bg-nx srcl3 integrated-con-mobile-view-inner">
                     <img src={require('../InsuImages/appdevelopment1.png')} className="developent-img" alt="service" />
                     <h5 class="mb10">Mobile App Development</h5>
                     <ul class="-service-list">
                        <li> <a href="#">iPhone </a> </li>
                        <li> <a href="#">Android</a> </li>
                        <li> <a href="#">Cross Platform </a></li>
                     </ul>
                     <p className="integrated-con-mobile-view-inner-para">At Insu Creation, we craft cutting-edge mobile software applications that seamlessly integrate with remote computing resources, delivering unparalleled performance and user satisfaction.</p>
                  </div>
               </motion.div>
               <motion.div className="integrated-con-mobile-view" whileHover={{scale:1.09}} data-aos="fade-up"  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-3-cus mt30- wow fadeInUp integrated-con-mobile-view" data-wow-delay="1s">
                  <div class="service-sec-list srvc-bg-nx srcl4 integrated-con-mobile-view-inner">
                     <img src={require('../InsuImages/search-engine-optimization.png')} className="developent-img" alt="service" />
                     <h5 class="mb10">SEO</h5>
                     <ul class="-service-list">
                     <li> <a href="#">Semrush</a> </li>  
                        <li> <a href="#"><strong>.</strong>Ahrefs</a> </li>
                        <li> <a href="#">MozBar</a> </li>
                        <li> <a href="#">SEOquake</a></li>
                     </ul>
                     <p className="integrated-con-mobile-view-inner-para">At Insu Creation, we specialize in enhancing website traffic quality and quantity through strategic search engine optimization (SEO) techniques, ensuring heightened visibility and engagement.</p>
                  </div>
               </motion.div>
               <motion.div className="integrated-con-mobile-view" whileHover={{scale:1.09}} data-aos="fade-up"  data-tilt data-tilt-max="5" data-tilt-speed="1000" class="col-lg-6-cus mt30- wow fadeInUp integrated-con-mobile-view" data-wow-delay="1.2s">
                  <div class="service-sec-list srvc-bg-nx srcl5 integrated-con-mobile-view-inner">
                     <img src={require('../InsuImages/socialmedia1.png')} className="developent-img" alt="service" />
                     <h5 class="mb10">Digital Marketing</h5>
                     <ul class="-service-list">
                        <li> <a href="#">SEO </a> </li>
                        <li> <a href="#">SMO </a> </li>
                        <li> <a href="#">PPC </a></li>
                        <li> <a href="#">PPC </a></li>
                     </ul>
                     <p className="integrated-con-mobile-view-inner-para">At Insu Creation, we harness the power of the internet and digital technologies to effectively promote and sell individuals, products, businesses, and more, maximizing online visibility and driving success.</p>
                  </div>
               </motion.div>
            </div>
            <div className="button-enquire-now-container-n">
            <div className="button-enquire-now-container">
      <p className="enquire-now-text enquire-now-text-2 enquire-now-text-2-mob-view">
        Want to<span className="start-span"> start a </span> new project?
      </p>
     <Link to="/contactus"> <button type="button" className="our-technologies-stack-btn mob-view-button-hire"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m mob-view-btn-pa">Hire Now<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
                </button></Link>
    </div>
    </div>
         </div>
      </section>


      </div>
      
)
   }
export default OurServices