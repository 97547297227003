import { useCallback } from "react";
import { Helmet } from "react-helmet";
import Particles from "react-tsparticles";
//import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import Header from "../Header";
import MainBanner from "../MainBanner";
import Content from "../Content";
import OurServices from "../OurServices"
import WhatWeWork from "../WhatWeWork"
import ListOfTechNames from "../ListOfTechNames"
import TechnologiesContainer from "../TechnologiesContainer"
import WhyChooseUs from "../WhyChooseUs"
import TestimonialsContainer from "../TestimonalsContainer"
import WeWorkWith from "../WeWorkWith"
import OurClientsComponent from "../OurClientsComponent"
import Footer from "../Footer";
import "./index.css"
import HappyClientsComponent from "../HappyClientsComponent";

const Home = () => {
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
        return <h1>jdnrfytguhjkl</h1>;
    }, []);

    return (
      <>
       <Helmet>
       <meta charSet="utf-8" />
            <title>Enrich Your Productivity with Insu Creation's Creativity: Elite Web/App Development, Graphic Design, and Innovative Digital Marketing | Expert in PHP, Python, Kotlin, NodeJS, ReactJS</title>
            <meta name="title" content="Default Title" data-react-helmet="true" />

            <meta name="description" content="Enrich Your Productivity with Insu Creation's Creativity - Offering Elite Web/App Development, Graphic Design, Content Writing, and Innovative Digital Marketing. Expertise in PHP, Python, Kotlin, NodeJS, ReactJS. Your one-stop digital solution partner globally." />
            <meta name="keywords" content="Insu Creation, Web Development, App Development, Graphic Design, Content Writing, Digital Marketing, PHP, Python, Kotlin, NodeJS, ReactJS, Innovative Solutions, Global Partner, Creative Services" />
        </Helmet>
      <Header />
      <MainBanner />
      <Content />
      <div className="our-services-main-1">
      <OurServices />
      </div>
      <WhatWeWork />
      <div className="list-of-tech-names-con">
      <ListOfTechNames />
      </div>
      <div className="list-of-tech-names-con">
      <TechnologiesContainer />
      </div>
      <WhyChooseUs />
      <WeWorkWith />
      <TestimonialsContainer />
     
       <HappyClientsComponent />
      <OurClientsComponent />
      <Footer />
      <div className="container-1" >
        <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              "fullScreen":{
                "enable":true,
                "zIndex":-1,
              },
              collisions: {
                enable: false,
            },
                background: {
                    color: {
                        value: "#0d47a1",
                    },
                },
                fpsLimit: 120,
                interactivity: {
                    events: {
                        onClick: {
                            enable: true,
                            mode: "push",
                        },
                        onHover: {
                            enable: true,
                            mode: "repulse",
                        },
                        resize: true,
                    },
                    modes: {
                        push: {
                            quantity: 2,
                        },
                        repulse: {
                            distance: 200,
                            duration: 0.4,
                        },
                    },
                },
                particles: {
                    color: {
                        value: "#ffffff",
                    },
                    links: {
                        color: "#ffffff",
                        distance: 150,
                        enable: true,
                        opacity: 0.5,
                        width: 1,
                        
                    },
                    move: {
                        direction: "none",
                        enable: true,
                        outModes: {
                            default: "bounce",
                        },
                        random: false,
                        speed: 4,
                        straight: false,
                    },
                    number: {
                        density: {
                            enable: true,
                            area: 800,
                        },
                        value: 80,
                    },
                    opacity: {
                        value: 0.5,
                    },
                    shape: {
                        type: "circle",
                    },
                    size: {
                        value: { min: 1, max: 5 },
                    },
                },
                detectRetina: true,
            }}
            
        />
        </div>
        </>
    );
};

export default Home