import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import DetailsForm from "../DetailsForm";
import Navbar from "../Navbar";
import {Helmet} from "react-helmet";
import Header from "../Header"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Footer from "../Footer"

import "./index.css"

const LandingPageDevelopment=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };  

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
      <Helmet>
      <title>Crafting Success: Elevate Your Brand with Expert Landing Page Development</title>
<meta name="description" content="Transform your digital presence with Insu Creation's expert landing page development services. Our tailored solutions combine creativity and functionality to captivate your audience and drive conversions. Elevate your brand with our experienced team today."/>


<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
</Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Elevate Your Online Presence with Insu Creation's Superior Landing Page Design Solutions</h1>
    <p className="transform-para transform-2-para">Achieve remarkable results online with our high-quality landing page design services at Insu Creation. Enhance your conversion rates with our expertly crafted designs. Our seasoned team will collaborate with you to create a mobile-friendly and high-converting landing page tailored to your specific needs and goals.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Customized design to fit your unique requirements</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Mobile-friendly layout for seamless user experience</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">High-converting design strategies to maximize results</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Affordable rates without compromising quality</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Fast turnaround times to meet your deadlines</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706165707/yav0qrciuzkmjnirlngt.gif" className="seo-route-img-main social-media-route-img-main landing-page-ban-rou" alt="seo-route-img-main" />

 </div>
 </div>

 <div className="landing-pg-develop-cont-1">
    <div className="landing-pg-develop-cont">
        <h1 className="landing-pg-develop-head">At Insu Creation, We Craft Compelling Landing Pages that Drive Conversions</h1>
        <p className="landing-pg-business-dev-para">Experience a fresh approach to landing page development with Insu Creation. Our expertise lies in creating captivating, high-converting pages tailored to your brand story. As a specialized digital agency, we're committed to delivering top-notch landing pages that captivate and convert, propelling your business to new heights.</p>
<p className="landing-pg-business-dev-para">Whether you aim to expand your email list or boost product sales, our customized solutions have you covered. We collaborate closely with entrepreneurs and startups to generate more leads and close more sales, ensuring tangible results for your business.</p>
<p className="landing-pg-business-dev-para">A well-designed landing page is paramount for increasing traffic, leads, and conversion rates, ultimately boosting revenue for your business.</p>

<button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>
 </div>

<div className="landing-pg-develop-cont-1">
<div className="landing-pg-develop-cont-1-2">
 <div className="landing-pg-develop-cont-1 landing-pg-develop-cont-1-img">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706169467/jj1vc4k6hjkniva39eny.gif" className="landing-img-2-benefits" alt="" />
    <div className="landing-pg-develop-cont landing-pg-develop-cont-2">
        <h1 className="landing-pg-develop-head">Discover the Benefits for Your Business:</h1>
<p className="landing-pg-business-dev-para landing-pg-business-dev-para-2">By prioritizing lead conversion, our landing pages are strategically engineered to drive traffic and transform it into sales, leads, or loyal customers. Each landing page seamlessly aligns with the corresponding advertisement, presenting only essential information to maintain user focus. An effective landing page serves as a comprehensive resource, providing users with all the necessary details to take action, whether it's making a purchase, subscribing to a newsletter, or reaching out to your company directly.
</p>
</div>
    </div>
 </div>
 </div>

 <div className="landing-pg-develop-cont-1">
    <div className="elements-successful-landing-pg">
        <h1 className="core-elements-landing-pg">The Core Elements of a Successful Landing Page</h1>
        <div className="core-elements-landing-pg-container-main">
        
            <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">1</h1>
                <div>
                    <h1 className="create-your-att-head">Create your Attractive Headline</h1>
                    <p className="create-your-att-para">Your headline is the first thing visitors will see, and it will determine whether they stick around to read the rest of your message or bounce away in search of something else.</p>
                </div>
            </div>
            
        

       
            <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">2</h1>
                <div>
                    <h1 className="create-your-att-head">Write persuasive copy</h1>
                    <p className="create-your-att-para">In order to have a successful landing page, you need to be able to persuade your visitors. This is why the first thing that you need to focus on when writing your landing page is the copy.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">3</h1>
                <div>
                    <h1 className="create-your-att-head">Deliver a unique value proposition</h1>
                    <p className="create-your-att-para">If you sell Japanese knives, for example, you might have a UVP that says "our knives are made in Japan by master craftsmen using ancient methods." A well-crafted UVP is what helps turn a browser into a customer.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">4</h1>
                <div>
                    <h1 className="create-your-att-head">Focus on one offer</h1>
                    <p className="create-your-att-para">One of the biggest mistakes businesses make is trying to pack their landing pages with too much information. Your landing page should have only one goal: to help whoever visits it get started with your company.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">5</h1>
                <div>
                    <h1 className="create-your-att-head">Don't be a pushy salesperson</h1>
                    <p className="create-your-att-para">Landing pages are all about conversion, but you can't just throw up a page that reads like a sales pitch and hope for the best. Avoid the "hard sell" approach. It doesn't work as well as a soft sell, which is much more effective in converting viewers into customers.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">6</h1>
                <div>
                    <h1 className="create-your-att-head">Show value, not price</h1>
                    <p className="create-your-att-para">In many cases, consumers are more concerned with perceived value than with price alone. A good way to demonstrate this is by showing how much money can be saved with your product or service.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">7</h1>
                <div>
                    <h1 className="create-your-att-head">Offer a free opt-in</h1>
                    <p className="create-your-att-para">Even if your product is paid, you can still offer something for free — an e-book, guide, webinar, assessment, or subscription. This lets you collect information from the user and begin building a relationship before asking for the sale.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">8</h1>
                <div>
                    <h1 className="create-your-att-head">Testimonials and a call to action</h1>
                    <p className="create-your-att-para">The most important element of any successful landing page is the call to action (CTA). Your CTA should stand out from the rest of the page, so it's easy for visitors to find and click. landing pages should include testimonials from satisfied customers.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">9</h1>
                <div>
                    <h1 className="create-your-att-head">Keep it simple and clean</h1>
                    <p className="create-your-att-para">A long-form landing page doesn’t have to be intimidating. Just keep it simple and clean. You can accomplish this by using plenty of white space, clustering your content into digestible chunks, and using a clear layout to highlight key information.</p>
            </div>
        </div>

        <div className="core-elements-landing-pg-container-items">
                <h1 className="core-inner-no-head">10</h1>
                <div>
                    <h1 className="create-your-att-head">It should be mobile friendly & Speedy</h1>
                    <p className="create-your-att-para">Responsive design is an absolute must for any business that wants to convert visitors into leads and customers. A slow-loading page will cause visitors to bounce away from your landing page, so make sure of its speed & responsiveness.</p>
            </div>
        </div>
        </div>

    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default LandingPageDevelopment