import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import Header from "../Header";
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage";
import DetailsForm from "../DetailsForm";
import Footer from "../Footer";
import "./index.css";

// Predefined client images
const staticClientImages = [
    { id: 1, image: "Prospect_Logo.png" },
    { id: 2, image: "BUILDNSPACE CONSTRUCTION_FF2.png" },
    { id: 3, image: "Cha_Ta_Logo_white.png" },
    { id: 4, image: "CAMELIA_GROUP_OF_SCHOOL_LOGO_REDESIGN-1_prev_ui.png" },
    { id: 5, image: "Cloudempire_Logo_prev_ui.png" },
    { id: 6, image: "ACHARJYA PHARMACY_logo_prev_ui.png" },
    { id: 7, image: "Tamrolipto_College_Of_Medical_Sciencee_Logo_WHITE_prev_ui.png" },
    { id: 8, image: "Spot Boyz_Logo_recolor_prev_ui (1).png" },
    { id: 9, image: "LBC LOGO_JPG_prev_ui.png" },
    { id: 10, image: "Gift studio_Logo_prev_ui.png" },
    { id: 11, image: "Dr Bedanka Roy_logo_prev_ui.png" },
    { id: 12, image: "Career_30Logo_white_prev_ui.png" },
];

const dynamicClientImages = [
    { id: 100, image: "flutter.png" },
    { id: 101, image: "flutter.png" },
    { id: 102, image: "flutter.png" },
    { id: 104, image: "flutter.png" },
    { id: 105, image: "flutter.png" },
    { id: 106, image: "flutter.png" },
    { id: 107, image: "flutter.png" },
    { id: 108, image: "flutter.png" },
    { id: 109, image: "flutter.png" },
    { id: 110, image: "flutter.png" },
    { id: 111, image: "flutter.png" },
    { id: 112, image: "flutter.png" },
];

const OurClients = () => {
    const [loadClient, setLoadClient] = useState(0);
    const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' });

    return (
        <>
            <Header />
            {mediaQueryCondition ? <Navbar /> : <NavbarInOtherPage />}
            <DetailsForm />
            <div className="our-clients-route-container-main">
                <div className="our-clients-route-container">
                    <div className="our-clients-route-container-inner">
                        <h1 className="our-clients-route-heading">To provide high-quality construction services that exceed our clients' expectations.</h1>
                        <p className="our-clients-route-para">
                            We focus on providing our clients with the best possible service. We use the latest technology and equipment to ensure that our projects are completed on time and within budget. We also have a team of highly skilled and experienced professionals who are dedicated to providing our clients with the best possible service.
                        </p>
                    </div>
                    <img src={require('../InsuImages/our-client.png')} className="our-client-route-image" alt="Our Clients" />
                </div>
            </div>
            <div className="our-clients-route-list-main-container">
                <h1 className="our-clients-route-head">Our Clients</h1>
                <p className="our-clients-route-para-2">We focus on providing our clients with the best possible service.</p>
                <div className="our-clients-route-list-container">
                    {[...staticClientImages, ...dynamicClientImages.slice(0, loadClient)].map((client) => (
                        <div key={client.id} className="our-clients-route-list-container-item">
                            <img src={require(`../InsuImages/${client.image}`)} className="our-clients-route-list-image" alt={`Client ${client.id}`} />
                        </div>
                    ))}
                </div>
                {loadClient < dynamicClientImages.length && (
                    <button type="button" className="see-more-button" onClick={() => setLoadClient(loadClient + 6)}>
                        VIEW MORE
                    </button>
                )}
            </div>
            <Footer />
        </>
    );
};

export default OurClients;
