import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import AOS from "aos"
import Header from "../Header"
import {Link} from "react-router-dom"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer";
import "./index.css"
const Technologies = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
 <>
  <Helmet>
  <title>Revolutionize Your Website: Insu Creation's Tailored Frontend Web Development Services</title>
  <meta name="description" content="Discover Insu Creation's cutting-edge frontend web development solutions tailored to elevate your online presence. From PSD to HTML conversion to responsive design integration and advanced technology like React.js and Vue.js, we cater to every aspect of your website's functionality and aesthetics. With our expertise, we ensure your website stands out, captivates users, and drives results. Trust Insu Creation to revolutionize your online experience." />
  <meta name="keywords" content="Insu Creation, website support, Frontend development services, Responsive web design, HTML5/CSS3 development, JavaScript frameworks, React.js/Vue.js expertise, User interface design, Cross-browser compatibility optimization,React.js, Vue.js, Web development services
, Web design optimization, online success" />


    </Helmet>
 <Header />
 {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
 <DetailsForm />
 <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div className="responsive-con-frontend">
    <h1 className="transform-head">Transform Your Website with Insu Creation's Top-Notch Frontend Development Services</h1>
    <p className="transform-para">Elevate your online presence with our professional frontend development services. Our skilled team specializes in crafting visually captivating and user-friendly websites that captivate and convert your audience.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-para-m"> Experienced frontend developers proficient in Insu creation technologies.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-para-m">Proven track record of delivering exceptional frontend development solutions.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-para-m">Tailored, customized solutions aligned with your unique objectives.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-para-m">Dedicated customer service and support at every step of the development journey.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-para-m">Competitive pricing for top-quality frontend development services.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">  <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>

       <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1707916576/tmpgxcyygpnetnnwbeui.gif" className="new-image-frontend" alt="" />

 </div>
 </div>
 <div>
  <div className="transform-para-tech-c">
    <p className="transform-para-tech transform-para-tech-mobile new-transform-insu">At Insu Creation, we offer comprehensive Front End Development services, encompassing PSD to HTML conversion, PSD to Code implementation, Responsive Design integration, Micro Segmentation, and expertise in HTML5, CSS3, React.js, Vue.js, and more. Our solutions cater to every aspect of your website's appearance, functionality, and content. We tailor our front-end solutions to your specific needs, whether it's enhancing design aesthetics, improving user experience, or optimizing site speed. Trust Insu Creation to handle your entire product line with dedicated support and expertise, ensuring your online presence exceeds expectations.</p>
    <div className="transform-tech-skills-container-main">
      <div className="transform-tech-skills-co">
      <h1 className="transform-best-frontend">Our best frontend web development framework choice</h1>
      <hr className="hr-line-transform" />
      <div className="transform-tech-skills-container">
      <div className="tech-use-img-container-transform frontend-route-chg-image">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705640487/hxohqdper80tskujmwv3.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">React.js Development</h1>
    </div>
    <div data-aos="fade-up" className="tech-use-img-container-transform frontend-route-chg-image">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705641516/ypdbu8ze6hysytnt6ydp.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">Angular.js Development</h1>
</div>
<div data-aos="fade-up" className="tech-use-img-container-transform frontend-route-chg-image">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705641560/irxrqv2uznag0fyi8h62.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">Vue.js Development</h1>
</div>
<div data-aos="fade-up" className="tech-use-img-container-transform frontend-route-chg-image">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705641591/xztljwhidvblfkxppcqn.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">Bootstrap.js Development</h1>
</div>
      </div>
    </div>
  </div>
  <div className="transform-tech-skills-container-main transform-tech-skills-container-main-2">
      <div className="transform-tech-skills-co">
      <h1 className="transform-best-frontend">Our PSD/XD/Figma to Code conversion services</h1>
      <hr className="hr-line-transform" />
      <div>
      <div className="transform-tech-skills-container">
      <div data-aos="fade-up" className="tech-use-img-container tech-use-img-container-transform">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705642161/sqmpyh6h4kzvkrbyq45d.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">PSD to HTML/Code</h1>
    </div>
    <div data-aos="fade-up" className="tech-use-img-container tech-use-img-container-transform">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705642218/b6mzlwtmivjlxghlqwl8.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">PSD to WordPress</h1>
</div>
<div  className="tech-use-img-container tech-use-img-container-transform">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705642269/s4helphnmd3oki5v2q38.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">PSD to Shopify</h1>
</div>
<div  className="tech-use-img-container tech-use-img-container-transform">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705642317/s9fs6q2wvk82qrsk8cng.webp" className="tech-use-img tech-use-img-trransform" alt="" />
  <h1 className="tech-use-img-container-transform-head">PSD to Bootstrap</h1>
</div>
      </div>
    </div>
  </div>
 </div>
 </div>
 </div>
 <Footer />
 
 </>
)}
AOS.init()
export default Technologies