import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Tilt from 'react-parallax-tilt';
import DetailsForm from '../DetailsForm';
import Header from "../Header";
import Navbar from '../Navbar';
import NavbarInOtherPage from "../NavbarInOtherPage";
import Footer from '../Footer';
import "./index.css";

// Import images statically
import biryaniImg from '../InsuImages/biryani.jpg';
import momsImg from '../InsuImages/moms.jpg';
import sanakaImg from '../InsuImages/Sanaka.jpg';
import insulImg from '../InsuImages/Insul.jpg';
import thechuImg from '../InsuImages/thechu.jpg';
import aswamedhImg from '../InsuImages/aswamedh.png';
import chunwWahImg from '../InsuImages/Chunw_wah.jpg';
import hotelDcImg from '../InsuImages/Hotel_DC.jpg';



// Import posters up to 57
import poster1Img from './insuPoster/poster1.jpg';
import poster2Img from './insuPoster/poster2.jpg';
import poster3Img from './insuPoster/poster3.jpg';
import poster4Img from './insuPoster/poster4.jpg';
import poster5Img from './insuPoster/poster5.jpg';
import poster6Img from './insuPoster/poster6.jpg';
import poster7Img from './insuPoster/poster7.jpg';
import poster8Img from './insuPoster/poster8.jpg';
import poster9Img from './insuPoster/poster9.jpg';
import poster10Img from './insuPoster/poster10.jpg';
import poster11Img from './insuPoster/poster11.jpg';
import poster12Img from './insuPoster/poster12.jpg';
import poster13Img from './insuPoster/poster13.jpg';
import poster14Img from './insuPoster/poster14.jpg';
import poster15Img from './insuPoster/poster15.jpg';
import poster16Img from './insuPoster/poster16.jpg';
import poster17Img from './insuPoster/poster17.jpg';
import poster18Img from './insuPoster/poster18.jpg';
import poster19Img from './insuPoster/poster19.jpg';
import poster20Img from './insuPoster/poster20.jpg';
import poster21Img from './insuPoster/poster21.jpg';
import poster22Img from './insuPoster/poster22.jpg';
import poster23Img from './insuPoster/poster23.jpg';
import poster24Img from './insuPoster/poster24.jpg';
import poster25Img from './insuPoster/poster25.jpg';
import poster26Img from './insuPoster/poster26.jpg';
import poster27Img from './insuPoster/poster27.jpg';
import poster28Img from './insuPoster/poster28.jpg';
import poster29Img from './insuPoster/poster29.jpg';
import poster30Img from './insuPoster/poster30.jpg';
import poster31Img from './insuPoster/poster31.jpg';
import poster32Img from './insuPoster/poster32.jpg';
import poster33Img from './insuPoster/poster33.jpg';
import poster34Img from './insuPoster/poster34.jpg';
import poster35Img from './insuPoster/poster35.jpg';
import poster36Img from './insuPoster/poster36.jpg';
import poster37Img from './insuPoster/poster37.jpg';
import poster38Img from './insuPoster/poster38.jpg';
import poster39Img from './insuPoster/poster39.jpg';
import poster40Img from './insuPoster/poster40.jpg';
import poster41Img from './insuPoster/poster41.jpg';
import poster42Img from './insuPoster/poster42.jpg';
import poster43Img from './insuPoster/poster43.jpg';
import poster44Img from './insuPoster/poster44.jpg';
import poster45Img from './insuPoster/poster45.jpg';
import poster46Img from './insuPoster/poster46.jpg';
import poster47Img from './insuPoster/poster47.jpg';
import poster48Img from './insuPoster/poster48.jpg';
import poster49Img from './insuPoster/poster49.jpg';
import poster50Img from './insuPoster/poster50.jpg';
import poster51Img from './insuPoster/poster51.jpg';
import poster52Img from './insuPoster/poster52.jpg';
import poster53Img from './insuPoster/poster53.jpg';
import poster54Img from './insuPoster/poster54.jpg';
import poster55Img from './insuPoster/poster55.jpg';
import poster56Img from './insuPoster/poster56.jpg';
import poster57Img from './insuPoster/poster57.jpg';


const portfolioList = [
    { id: "0001", image: "Chunw" },
    { id: "0003", image: "Chung" },
    { id: "0004", image: "as" },
    { id: "0005", image: "KIS" },
    { id: "0006", image: "cafecheers" },
    { id: "0007", image: "Ivory_Dental" },
    { id: "0008", image: "Mom_Chocolate" },
    { id: "0009", image: "Sai_Dental" }
];

// Use static imports for images
const staticImages = [
    { src: biryaniImg, alt: 'biryani' },
    { src: momsImg, alt: 'moms' },
    { src: sanakaImg, alt: 'Sanaka' },
    { src: insulImg, alt: 'Insul' },
    { src: thechuImg, alt: 'thechu' },
    { src: aswamedhImg, alt: 'aswamedh' },
    { src: chunwWahImg, alt: 'Chunw_wah' },
    { src: hotelDcImg, alt: 'Hotel_DC' },
    { src: poster1Img, alt: 'poster1' },
    { src: poster2Img, alt: 'poster2' },
    { src: poster3Img, alt: 'poster3' },
    { src: poster4Img, alt: 'poster4' },
    { src: poster5Img, alt: 'poster5' },
    { src: poster6Img, alt: 'poster6' },
    { src: poster7Img, alt: 'poster7' },
    { src: poster8Img, alt: 'poster8' },
    { src: poster9Img, alt: 'poster9' },
    { src: poster10Img, alt: 'poster10' },
    { src: poster11Img, alt: 'poster11' },
    { src: poster12Img, alt: 'poster12' },
    { src: poster13Img, alt: 'poster13' },
    { src: poster14Img, alt: 'poster14' },
    { src: poster15Img, alt: 'poster15' },
    { src: poster16Img, alt: 'poster16' },
    { src: poster17Img, alt: 'poster17' },
    { src: poster18Img, alt: 'poster18' },
    { src: poster19Img, alt: 'poster19' },
    { src: poster20Img, alt: 'poster20' },
    { src: poster21Img, alt: 'poster21' },
    { src: poster22Img, alt: 'poster22' },
    { src: poster23Img, alt: 'poster23' },
    { src: poster24Img, alt: 'poster24' },
    { src: poster25Img, alt: 'poster25' },
    { src: poster26Img, alt: 'poster26' },
    { src: poster27Img, alt: 'poster27' },
    { src: poster28Img, alt: 'poster28' },
    { src: poster29Img, alt: 'poster29' },
    { src: poster30Img, alt: 'poster30' },
    { src: poster31Img, alt: 'poster31' },
    { src: poster32Img, alt: 'poster32' },
    { src: poster33Img, alt: 'poster33' },
    { src: poster34Img, alt: 'poster34' },
    { src: poster35Img, alt: 'poster35' },
    { src: poster36Img, alt: 'poster36' },
    { src: poster37Img, alt: 'poster37' },
    { src: poster38Img, alt: 'poster38' },
    { src: poster39Img, alt: 'poster39' },
    { src: poster40Img, alt: 'poster40' },
    { src: poster41Img, alt: 'poster41' },
    { src: poster42Img, alt: 'poster42' },
    { src: poster43Img, alt: 'poster43' },
    { src: poster44Img, alt: 'poster44' },
    { src: poster45Img, alt: 'poster45' },
    { src: poster46Img, alt: 'poster46' },
    { src: poster47Img, alt: 'poster47' },
    { src: poster48Img, alt: 'poster48' },
    { src: poster49Img, alt: 'poster49' },
    { src: poster50Img, alt: 'poster50' },
    { src: poster51Img, alt: 'poster51' },
    { src: poster52Img, alt: 'poster52' },
    { src: poster53Img, alt: 'poster53' },
    { src: poster54Img, alt: 'poster54' },
    { src: poster55Img, alt: 'poster55' },
    { src: poster56Img, alt: 'poster56' },
    { src: poster57Img, alt: 'poster57' },
];

const PortfolioPosterDesign = () => {
    const [loadPoster, setLoadPoster] = useState(0);
    const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' });

    return (
        <>
            <Header />
            {mediaQueryCondition ? <Navbar /> : <NavbarInOtherPage />}
            <DetailsForm />
            <div className='poster-design-main-container-route'>
                <span className="our-locations-contact-us our-wk poster-des-span">WE DELIVER OUR BEST</span>
                <h1 className="portfolio-head-rote mob-web-port-v">Poster Portfolio</h1>
                <p className="portfolio-para-rote poster-des-para mob-web-port-v-para">
                    There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.
                </p>
                <div className='posters-main-con'>
                    {staticImages.map((img, index) => (
                        <Tilt key={index} className='tilt-co mob-web-port-v-con-pp'>
                            <img src={img.src} className='poster-des' alt={img.alt} />
                        </Tilt>
                    ))}
                    {portfolioList.slice(0, loadPoster).map((eachIt) => (
                        <Tilt key={eachIt.id} className='tilt-co mob-web-port-v-con-pp'>
                            <img src={require(`../InsuImages/${eachIt.image}.png`)} className='poster-des' alt={eachIt.id} />
                        </Tilt>
                    ))}
                </div>
                <button type="button" className='click-to-load-more' onClick={() => setLoadPoster(loadPoster + 4)}>Click to load more</button>
            </div>
            <Footer />
        </>
    );
}

export default PortfolioPosterDesign;
