import "./index.css"

const OurVisionAndMission=()=>(
   <div className="vision-and-mission-container">
    <div className="vision-and-mission-container-details">
        <h1 className="vision-and-mission-heading">Our Vision And Mission</h1>
        <p className="vision-and-mission-paragraph">We aspire to be a trusted partner with those seeking to thrive in an ever-evolving digital landscape. Our vision is to be at the forefront of digital excellence, constantly pushing boundaries and setting new industry standards.</p>
        <p className="vision-and-mission-paragraph">Insu Creation's mission is to empower businesses and individuals in the digital world by providing innovative, high-quality, and tailored digital solutions that drive success and growth.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704953099/h6net3y0ib2qgkpxiayk.png" className="vision-and-mission-img" alt="vision-and-mission-img" />
   </div>
)

export default OurVisionAndMission