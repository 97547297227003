import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import Navbar from "../Navbar";
import { Helmet } from "react-helmet";
import NavbarInOtherPage from "../NavbarInOtherPage"
import {Link} from "react-router-dom"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const VideoAdsContent=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Insu Creation: Affordable & Engaging Video Creation Services for Boosting E-commerce Sales</title>
  <meta name="description" content="Discover Insu Creation's affordable and engaging video creation services designed to elevate e-commerce sales. Our professional-grade videos are tailored to capture your audience's attention and increase your return on investment. Stand out in the competitive marketplace and boost your visibility today."/>
  <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
</Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Insu Creation: Affordable and Engaging Video Creation Services</h1>
    <p className="transform-para transform-2-para">In search of an affordable solution to elevate your e-commerce sales and captivate your audience? Discover the power of Insu Creation's video creation services. Designed to be cost-effective yet sales-driven, our videos are crafted to seize your audience's attention and amplify your return on investment (ROI).</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">High-Quality: Professional-grade video content tailored to your needs</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Engaging: Captivate your audience with compelling visuals</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Customizable: Tailored solutions to suit your business requirements</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Increase ROI: Cost-effective strategy to boost sales and revenue</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Boost Visibility: Reach wider audiences and stand out in the competitive marketplace</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
   <Link to="/contactus"> <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
      <Link to="/schedule-meeting"><button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706244500/dfjhe2utcxd7wsgfxeuj.gif" className="seo-route-img-main social-media-route-img-main video-ads-cont-img video-ads-cont-img-mob artile-write-img-vv" alt="seo-route-img-main" />
 </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default VideoAdsContent