import React, { useEffect, useState } from 'react';

//styling
import './index.css';

const CountTwo = props => {
  // label of counter
  // number to increment to
  // duration of count in seconds
  const {eachItem}=props
  const {label, number, duration } = eachItem

  // number displayed by component
  const [count1, setCount1] = useState("0")

  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseInt(number.substring(0,3))
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter 
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount1(String(start) + number.substring(3))
      if (start === end) clearInterval(timer)       
    }, incrementTime);

    // dependency array
  }, [number, duration]);

  return (
    <div>
        <h1 className='count-two-head-c'>{count1}+ <br /><span className='count-two-para-c'>{label}</span></h1>
    </div>
  );
}

export default CountTwo;