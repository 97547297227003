import { Chrono } from "react-chrono";
import {Helmet} from "react-helmet"
import {Link} from "react-router-dom"
import { useMediaQuery } from "react-responsive";
import Navbar from "../Navbar";
import Header from "../Header"
import NabarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";
import Footer from "../Footer"

import "./index.css"

const items = [
  {
    title: "Step 1",
    cardDetailedText: "Insu Creation's SEO team prioritizes optimizing your on-page content, recognizing its pivotal role in search engine success. We ensure your message resonates for maximum conversion potential.",
  },
  {
    title: "Step 2",
    cardDetailedText: "Our seasoned reviewers excel at analyzing off-page SEO dynamics crucial to your business. Expect comprehensive insights enabling enhanced rankings in today's competitive search landscape with Insu Creation.",
  },
  {
    title: "Step 3",
    cardDetailedText: "Unveil the secrets of SEO with Insu Creation. Illuminate your website's potential by optimizing it for targeted keywords, ensuring heightened visibility and competitiveness in search engine rankings.",
  },
  {
    title: "Step 4",
    cardDetailedText: "Harness the power of Google Analytics with Insu Creation. Gain invaluable insights into your website's performance, enhance conversion rates, and maximize ROI with targeted optimization strategies.",
  }
];

const Seo=()=>{
  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return (
    <>
     <Helmet>
     <title>Insu Creation: Maximizing Your Application's Potential with Tailored Backend Development Services</title>
     <meta name="description" content="Unlock the potential of your online presence with Insu Creation's expert SEO strategies. Drive targeted traffic, boost visibility, and maximize your website's impact with our tailored optimization solutions."/>
     <meta name="keywords" content="Insu Creation, SEO, search engine optimization, targeted traffic, online visibility, website ranking, digital marketing, keyword research, content optimization, link building, organic search, website traffic, website optimization, SEO strategies, website performance, search engine ranking, website analysis, SEO tools, SEO techniques, website promotion." />


     </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NabarInOtherPage />}
    <DetailsForm />
<div className='transform-main-cont-2 seo-rote-main'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Supercharge Your Online Presence with Our Cutting-Edge SEO Solutions Today!</h1>
    <p className="transform-para transform-2-para">We steer quality traffic to your site, boost SEO rankings, and elevate your online presence through effective strategies like keyword research, on-page optimization, and link building. Experience a surge in organic traffic, leads, and revenue with our expert assistance.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">We pinpoint the perfect keywords and seamlessly weave them into your site's content.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">We guarantee your website is organized, user-friendly, and loads swiftly.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">We ensure your website is well-structured, easy to navigate, and loads promptly.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Furnishing you with valuable, informative, and pertinent content to boost visibility and garner additional backlinks.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Our emphasis lies in constructing top-notch, relevant backlinks for your website, prioritizing quality over quantity.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
   
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
 <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705740133/fz1gqpzvyfzfchdnqrwd.gif" className="new-image-frontend new-image-backend" alt="" />

 </div>
 </div>
 <div>
  <div className="seo-services-we-provide-timeline-main">
  <div className="seo-services-we-provide-timeline">
  <h1 className="seo-services-we-provide-heading">SEO SERVICES WE PROVIDE</h1>
  <p className="seo-services-we-provide-paragraph chrono-above-para-mob">At Insu Creation, we specialize in digital solutions, collaborating closely with clients to deliver desired outcomes. Let our Seo experts craft your high-converting online store, optimized for speed, SEO, responsiveness, and ease of maintenance.
</p>
 <Chrono
      items={items}
      hideControls
      itemWidth={300}
      style={{ title: { background: 'blue' } }}
      mode="VERTICAL_ALTERNATING"
      fontSizes={{
        cardText:30,
        cardSubtitle:"1.5rem",
        title: '15px',

      }}

      theme={
        {
          titleColor: '#313979',
          titleColorActive: 'white',
          secondary:
              'linear-gradient(rgba(162, 96, 192, 0.5), rgba(55, 34, 146, 0.5))',
            primary: '#7f4a97',
        }
      }
    />
    </div>
    </div>
    </div>
    <div className="seo-services-we-provide-timeline-main">
      <h1 className="seo-services-we-provide-heading">SEO ALL TOOLS</h1>
      <div className="seo-new-tools-list-container seo-new-con-m">
      <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705901866/t1bdi0ygtbi4qmbjp0k1.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">seoClarity</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">In a sea of niche SEO products, seoClarity is about as close to an all-in-one tool as it gets. With a wide range of both general SEO and content optimization features and an AI assistant, it can likely replace several other tools for many users.</p>
  </div>
    </div>
    </div>
    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705903201/wbsnqcjisfbayvip4gyy.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Surfer</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Surfer is one of the more distinctive tools on this list. It's primarily an SEO auditing tool, but its depth of insights makes it very useful for keyword research, too. But the big draw for many users will be its compelling AI features.</p>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705903323/m3fsxdz63rx8i6amerpa.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Semrush</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Semrush is basically synonymous with SEO. This rank tracker's insights and features are robust and scalable enough to handle virtually all performance monitoring needs for SMBs and enterprises.</p>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705903445/suyspxe2bchhnitizcgr.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Seoptimer</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">SEOptimer specializes in diagnosing SEO-related performance issues with a website or individual webpage that comes with a few basic tools for helping address them.</p>
  </div>
    </div>
    </div>

    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705904469/gbfmloje9pk3ln8zmpfc.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Ahrefs</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Ahrefs has many of the same features as Semrush. You can monitor specific webpages or domains to see how they rank for keywords over time, perform site audits. It's also got some appealing bonus features, which shows you ranking statistics for pages across the web for given keywords. </p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705904753/wq8jj3qmwyieh8qodc5b.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">ClearScope</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Clearscope's dashboard is organized a bit differently than most products. Its primary asset isn't keywords or links, but reports. You start by feeding a keyword into a report, which brings up the content optimization interface.</p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705905027/wvfxzkc0eymss8fvyrjv.png" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">Letterdrop</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">Letterdrop is kind of in a category of its own. Part content management solution, part SEO auditor, part keyword research tool, it's a unique product that does a little bit of a few different things. </p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705905207/c8e3azz8saa2o3rj42bd.jpg" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img seo-rote-img-img-2 tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">BuzzSumo</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">BuzzSumo is a keyword research tool that offers better search trend reporting, topic idea generation, and search engine results page (SERP) monitoring than most all-in-one products with keyword research functionality. </p>
  </div>
    </div>
    </div>


    <div className="wordPress-new-tech-con seo-new-tools-list-container-item new-mob-seo-t">
                <div className="tech-use-img-container wordpress-our-serv-con seo-rote-main-div seo-rote-main-div-main design-mobile-view-ne">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705905325/mhlz6xiuhskregvg2ass.jpg" className="tech-use-img tech-use-img-trransform tech-use-img-wordpress seo-rote-img-img tools-all-detail-con-img" alt="" />
  <div className="tools-all-detail-con">
  <h1 className="tech-use-img-container-transform-head tech-use-img-container-wordpress-head tech-use-seo-rote">AnswerThePublic</h1>
    <p className="wordpress-para-container tech-use-seo-rote-para">The self-proclaimed tool for discovering what people are talking about, AnswerThePublic (ATP) gives users access to uniquely useful search insights for a fraction of the cost of high-end keyword tools.</p>
  </div>
    </div>
    </div>


      </div>
    </div>
 <Footer />
 </>
)}

export default Seo