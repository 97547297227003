import { useMediaQuery } from "react-responsive";
import Header from "../Header"
import Navbar from "../Navbar";
import {Link} from "react-router-dom"
import NavbarInOtherPage from "../NavbarInOtherPage"

import "./index.css"
import Footer from "../Footer";

const SiteMap=()=>{
    const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    return(
        <>
          <Header />
        {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
       <div className="container-site-map-main">
       </div>
       <div className="container-site-map-main-1">
        <h1 className="site-map-main-heading">IT Solutions</h1>
        <div className="container-site-map-main-2">
          <div className="transform-right-list-container-site-map">
        <ul className="transform-right-list-container">
            <h1 className="heading-site-map-ul">Graphic Designing</h1>
            <Link to="/complete-branding" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Complete Branding</p>
        </div>
      </li>
      </Link>
      <Link to="/logo-designing" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Logo Designing</p>
        </div>
      </li>
      </Link>
      <Link to="/graphic-design" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Graphic Designs</p>
        </div>
      </li>
      </Link>
      <Link to="/product-packaging" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para"> Product Packaging Designs</p>
        </div>
      </li>
      </Link>
      <Link to="/business-presentation" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Business presentation</p>
        </div>
      </li>
      </Link>
      <Link to="/animation-vedio" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">2D & 3D animated videos</p>
        </div>
      </li>
      </Link>
    </ul>
    </div>
    <div className="conatiner-2-site-ul">
    <div>
    <ul className="transform-right-list-container transform-right-list-container-site-map-2">
            <h1 className="heading-site-map-ul">Web Development</h1>
            <Link to="/frontend" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Frontend Development</p>
        </div>
      </li>
      </Link>
      <Link to="/backend" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Backend Development</p>
        </div>
      </li>
      </Link>
      <Link to="/wordpress" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">WordPress Development</p>
        </div>
      </li>
      </Link>
    
    </ul>
    <ul className="transform-right-list-container transform-right-list-container-site-map-2">
    <h1 className="heading-site-map-ul">Mobile App Development</h1>
            <Link to="/react-native-development" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">React Native App Development</p>
        </div>
      </li>
      </Link>
      <Link to="/flutter-development" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Flutter Development</p>
        </div>
      </li>
      </Link>
    </ul>
    </div>
    </div>
    <div className="transform-right-list-container-site-map">
    <ul className="transform-right-list-containerp">
            <h1 className="heading-site-map-ul">Content Development</h1>
            <Link to="/landing-page-development" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Landing Page Development</p>
        </div>
      </li>
      </Link>
      <Link to="/article-writing" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Article Writing</p>
        </div>
      </li>
      </Link>
      <Link to="/social-media-content" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Social Media Content</p>
        </div>
      </li>
      </Link>
      <Link to="/video-ads-for-ecommerce" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Video Ads for E-commerce</p>
        </div>
      </li>
      </Link>
      <Link to="/product-description-writing" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Projuct Descrition Writing</p>
        </div>
      </li>
      </Link>
      <Link to="/blog-content-writing" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Blog Content Writing</p>
        </div>
      </li>
      </Link>
    
    </ul>
    </div>
    <div className="transform-right-list-container-site-map">
    <ul className="transform-right-list-container">
            <h1 className="heading-site-map-ul">Digital Marketing</h1>
            <Link to="/seo" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">SEO</p>
        </div>
      </li>
      </Link>
      <Link to="/social-media-marketing" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Social Media Marketing</p>
        </div>
      </li>
      </Link>
      <Link to="/digital-marketing" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Digital Marketing Funnel</p>
        </div>
      </li>
      </Link>
      <Link to="/local-seo-services" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Local SEO Services</p>
        </div>
      </li>
      </Link>
      <Link to="/technical-seo-services" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Technical SEO Services</p>
        </div>
      </li>
      </Link>
      <Link to="/google-analytics" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Google Analytics</p>
        </div>
      </li>
      </Link>
    </ul>
    </div>
        </div>
       </div>
       <div className="container-site-map-main-2-main-main">
       <div className="container-site-map-main-2 container-site-map-main-2-main">
       <div className="transform-right-list-container-site-map">
        <ul className="transform-right-list-container">
            <h1 className="heading-site-map-ul">Portfolio</h1>
            <Link to="/webdesigns" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Website Design</p>
        </div>
      </li>
      </Link>
      <Link to="/logodesigns" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Logo Design</p>
        </div>
      </li>
      </Link>
      <Link to="/posterdesigns" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Posters</p>
        </div>
      </li>
      </Link>
      <Link to="/seodesigns" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">SEO</p>
        </div>
      </li>
      </Link>
      <Link to="/branding-collaterales" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Branding Collaterales</p>
        </div>
      </li>
      </Link>
      <Link to="/product-packaging-portfolio" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Product Packaging</p>
        </div>
      </li>
      </Link>
      <Link to="/graphic-portfolio" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">Graphic Design</p>
        </div>
      </li>
      </Link>
      <Link to="/app-portfolio" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="ransform-right-2-para">APP</p>
        </div>
      </li>
      </Link>
    </ul>
    </div>
    <div className="transform-right-list-container-site-map">
    <ul className="transform-right-list-container">
            <h1 className="heading-site-map-ul">Contact Us</h1>
            <Link to="/contactus" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Contact Us</p>
        </div>
      </li>
      </Link>
      <Link to="/career" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Career</p>
        </div>
      </li>
      </Link>
      <Link to="/schedule-meeting" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Schedule a Meeting</p>
        </div>
      </li>
      </Link>
      <Link to="/partner-with-us" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Partner with us</p>
        </div>
      </li>
      </Link>
    </ul>
    </div>
    <div className="transform-right-list-container-site-map">
    <ul className="transform-right-list-container">
            <h1 className="heading-site-map-ul">Reach Us</h1>
            <Link to="/how-we-work" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">How we work</p>
        </div> 
      </li>
      </Link>
      <Link to="/why-trust-and-choose-us" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Why Trust and Choose Us?</p>
        </div>
      </li>
      </Link>
      <Link to="/technologiesweuse" className="link-site-map-route">
      <li className="transform-right-list-container-items-2">
        <div className="list-transform list-transform-brand">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-2-para">Technology We Use</p>
        </div>
      </li>
      </Link>

    </ul>
    </div>
            </div>
       </div>
       <Footer />
        </>
    )
}

export default SiteMap