import { useMediaQuery } from "react-responsive"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Header from "../Header"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./index.css"

const BrandingCollaterales=()=>{
  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
    <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    
    <div className="web-des-w">
    <span className="our-locations-contact-us our-wk web-work-w">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Branding Collaterales</h1>
    <p className="portfolio-para-rote web-des-w-page mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
    <ul class="img-list-bd">
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480368/uigesicedmnk9juag6qv.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480396/q7qpjhynkhh20f6w455h.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480420/pl1rggit3thkp7ibvja2.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480447/tjzk955wauqvjjds4bwf.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480476/dcqpggjhpv297togheaj.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480519/vufpfkm1yvc5qikqm5lj.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480547/kejjcqcoueb4cbpdgydv.jpg" className="img-list-img" alt="" /></li>
  <li className="img-list-li"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708480571/yesb2wsohnkfvkfrsvaf.jpg" className="img-list-img" alt="" /></li>

</ul>
</div>
<Footer />
</>
)
}
export default BrandingCollaterales
