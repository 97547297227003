import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import { Helmet } from "react-helmet";
import Navbar from "../Navbar";
import {Link} from "react-router-dom"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const ProductDescriptionWriting=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
     <Helmet>
     <title>Insu Creation: Crafting Engaging & Informative Social Media Content</title>
    <meta name="description" content="Elevate your online presence with Insu Creation's expertly crafted social media content. Our focus on engaging and informative posts tailored to your audience ensures effective promotion of your services. Discover the power of compelling content today."/>
    <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head prod-des-writing-head">Insu Creation: Elevating Online Presence with SEO-Optimized Blog Content Writing</h1>
    <p className="transform-para transform-2-para">Unleash the potential of your online presence with Insu Creation's SEO-optimized blog content writing services. Specializing in crafting engaging and search engine-friendly content, our expert writers collaborate closely with you to deliver high-quality, customized blog posts that resonate with your brand's voice and message.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">SEO-Optimized Content: Drive traffic with content that ranks high on search engines.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Engaging and High-Quality Writing: Captivate your audience with compelling content.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Customized to Fit Your Brand: Tailored solutions that reflect your brand's identity.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Consistently Drive Traffic: Increase visibility and attract more visitors to your website.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Boosts Online Visibility: Enhance your online presence and stand out in the digital landscape with our blog writing services.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">  <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
     <Link to="/schedule-meeting"> <button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706246704/mwtordynmuzul8pa4hsq.gif" className="seo-route-img-main social-media-route-img-main video-ads-cont-img social-media-content-img-mob artile-write-img-vv" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706177930/vxusddwd1x823festmr4.gif" className="react-native-mid-img social-media-content-img-mob artile-write-img-vv" alt="" />

    <div>
        <h1 className="react-native-business-dev">Insu Creation: Prioritizing Engaging and Informative Content Creation</h1>
        <p className="react-native-business-dev-para">At Insu Creation, we prioritize the creation of engaging and informative content that directly relates to your services and addresses the needs of your target audience.</p>
        <p className="react-native-business-dev-para">This involves crafting captivating captions that pique interest, leveraging relevant hashtags to enhance content discoverability, and incorporating compelling calls to action to encourage audience interaction.</p>
        <p className="react-native-business-dev-para">By emphasizing these key elements, we aim to deliver high-quality social media content that resonates with our audience, effectively promotes our services, and drives meaningful engagement.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default ProductDescriptionWriting