import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import {Link} from "react-router-dom"
import AOS from "aos"
import Header from "../Header"
import Navbar from "../Navbar";
import {Helmet} from "react-helmet"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const AnimationVedio=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);  

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
     <Helmet>
     <title>Mastering Animation in Insu Creation: Traditional vs. CGI Techniques</title>
    <meta name="description" content="Dive into the world of animation in Insu Creation, comparing traditional methods to modern CGI techniques. Learn how to excel as a presenter and explore the intricacies of character movement. Discover the artistry behind creating captivating animations for your projects."/>
    <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div className="animation-route-top-con">
    <h1 className="transform-head transform-2-head">In the realm of insu creation, why is it gaining popularity?</h1>
    <p className="transform-para transform-2-para">It's not just about how your company is perceived; it's about crafting a memorable presence that resonates.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Cultivate positive impressions to build a robust brand image.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Forge lasting relationships to foster deeper connections.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Articulate messages effectively to captivate and persuade your audience.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Drive desired outcomes, whether in sales, services, or employee motivation.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Demonstrate expertise and fortify core values pivotal to your company culture.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706349406/gqdcsvgiyj35d4aaxput.gif" className="seo-route-img-main business-pre-img business-pre-img-2 d-main-img-mob artile-write-img-vv-1" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706349571/adf1ksuyz9k89y1xe9tn.gif" className="react-native-mid-img business-pre-img-2 d-main-img-mob-2 artile-write-img-vv-1" alt="" />

    <div className="animation-route-top-con">
        <h1 className="react-native-business-dev">In insu creation, animation transcends mere moving drawings; it's about crafting lifelike movements through artistry.</h1>
        <p className="react-native-business-dev-para">Utilizing 3D animation, you can seamlessly integrate your 3D rigged model into digital environments and manipulate it with precision. By setting keyframes for essential actions, animation software like Maya fills in the intermediary frames, streamlining the process.</p>
        <p className="react-native-business-dev-para">Digital rigging empowers animators to construct characters with ease. This involves creating a virtual "skeleton" for the character, directing the computer on which body parts to animate. This innovative technique saves significant time, sparing animators from redrawing characters frame by frame.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>
    </div>
 </div>


 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706351097/nwr4jd9e2s1bkfswiij7.jpg" className="react-native-mid-img social-media-content-img-n d-main-img-mob" alt="" />

    <div>
        <h1 className="react-native-business-dev">In insu creation, anime can be seen as a subset of traditional animation.</h1>
        <p className="react-native-business-dev-para">Traditional animation involves drawing or painting images on transparent celluloid sheets for photography. While early cartoons exemplify this method, contemporary animated films predominantly employ computer-generated imagery or CGI.</p>
        <p className="react-native-business-dev-para">To excel as a presenter in insu creation, demonstrate a thorough grasp of your subject matter. Establish clear presentation goals and anticipate potential audience inquiries.</p>
        <p className="react-native-business-dev-para">In animation, characters typically move "on twos," where each image is displayed for two frames, resulting in 12 drawings per second.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>

 <div className="graphic-route-ty-head-main-container packaging-back-banner-img" data-aos="zoom-in">
    <div className="graphic-route-ty-head-main-container-1">
    <span className="our-locations-contact-us our-wk web-work-w">SERVICE WE PROVIDE</span>
    <h1 className="graphic-route-ty-head ppt-head ppt-head-mob">Type of Animation Services</h1>
    <div className="graphic-route-ty-item-con">
        <div className="graphic-design-ty-list-items mobile-logo-desi-con av-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706349738/xre2tj9j4fzpbhrauuxu.jpg" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Stop Motion Animation</h1>
                <p>Each object is physically manipulated in incredibly small increments and photographed one frame at a time between every single movement.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con av-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706349962/xjnmtt7odvgossj24xi0.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">2D Animation</h1>
                <p>2D animation is one of the most popular and widely used types of animation in marketing. This speeds up the production process considerably.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con av-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350087/atuxcibr2p1l7wn9g18h.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">3D Animation</h1>
                <p>This style’s particular aesthetic provides an immersive experience, as the animation can rotate around for a clearer understanding of how something works.</p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con av-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350223/umkqo4lvqpzgj02eechr.png" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">HUD Animation</h1>
                <p>HUD stands for “Head-Up Display,” and if you’re an avid gamer, you’re probably quite familiar with this animated style. </p>
            </div>
        </div>
        <div className="graphic-design-ty-list-items mobile-logo-desi-con av-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350331/qcyfh2hryx7frsnfevj7.jpg" alt="" className="bp-design-route-logos" />
            <div className="graphic-design-ty-list-items-div logo-design-inner-new-con">
                <h1 className="graphic-design-ty-iten-head bp-head">Plexus Animation</h1>
                <p>With a great presentation, make sure you have provided everything you and your products are about to gain trust in your product.</p>
            </div>
        </div>
    </div>
    </div>
 </div>

 <div className="web-des-w">
    <span className="our-locations-contact-us our-wk web-work-w">OUR PROJECTS</span>
    <h1 className="portfolio-head-rote">2D & 3D Animation</h1>
    <p className="portfolio-para-rote web-des-w-page business-mobile-para-n">We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
        <div className="new-anima-con">
<div class="wrapper-w website-design-w card-mobile-view-ani-1">
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350696/gz95xfuchweweyjghx1v.gif" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Men 3D</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350928/vxqdybfqdupmcmq76sce.gif" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Men 3D</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350696/gz95xfuchweweyjghx1v.gif" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Men Head</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350928/vxqdybfqdupmcmq76sce.gif" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Men Head</h1>
      <p>Website</p>
=    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350696/gz95xfuchweweyjghx1v.gif" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Men 3D</h1>
      <p>Website</p>
    </div>
  </div>
  <div class="card-w card-mobile-view-ani"><img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706350928/vxqdybfqdupmcmq76sce.gif" alt="web-img" />
    <div class="info-w">
      <h1 className="mountain-head">Men 3D</h1>
      <p>Website</p>
    </div>
  </div>

</div>
</div>
</div>

 <Footer />
    </>
)}
AOS.init()
export default AnimationVedio