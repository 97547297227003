import { IoIosMail, IoIosCall } from "react-icons/io";
import { FaFacebookF, FaWhatsapp } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import "./index.css";

const OfficeLocation = ({ imgSrc, location, description, email, phone }) => (
    <div className="contact-us-office-locate-container">
        <img src={imgSrc} className="our-offices-img" alt={`${location}-office-img`} />
        <h1 className="office-location-head">{location}</h1>
        <p className="office-location-para">{description}</p>
        <div className="io-mail-contact-us">
            <IoIosMail className="contact-us-office-main-icon" />
            <p className="info-mail-para">{email}</p>
        </div>
        <div className="io-mail-contact-us">
            <IoIosCall className="contact-us-office-main-icon" />
            <p className="info-mail-para">{phone}</p>
        </div>
        <div className="contact-us-btn-ic">
            <button type="button" className="contact-us-office-main-icon-btn">
                <FaFacebookF className="contact-us-office-main-icon btn-cont-2" />
            </button>
            <button type="button" className="contact-us-office-main-icon-btn">
                <FaWhatsapp className="contact-us-office-main-icon btn-cont-2" />
            </button>
            <button type="button" className="contact-us-office-main-icon-btn">
                <IoIosMail className="contact-us-office-main-icon btn-cont-2 btn-cont-3" />
            </button>
            <button type="button" className="contact-us-office-main-icon-btn">
                <FaLocationDot className="contact-us-office-main-icon btn-cont-2" />
            </button>
        </div>
    </div>
);

const ContactUsLocation = () => {
    const offices = [
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705035343/qhjbpiwy0jhodc9k4nwg.png",
            location: "Bolpur",
            description: "Headquarter",
            email: "info@insucreation.in",
            phone: "+91 9679985972",
        },
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038567/xvyeqpzabttlx1xo67ga.png",
            location: "Tamluk",
            description: "Registered Office",
            email: "info@insucreation.in",
            phone: "+91 9547361869",
        },
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038617/vvevsslz3xr3ymd9ajud.png",
            location: "Bardhaman",
            description: "Branch Office",
            email: "info@insucreation.in",
            phone: "+91 9547361869",
        },
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038567/xvyeqpzabttlx1xo67ga.png",
            location: "Surya Sen Nager Badu Road",
            description: "Madhyamgram Near Seva Medical 700129",
            email: "info@insucreation.in",
            phone: "+91 9547361869",
        },
    ];

    return (
        <div className="contact-us-office-main-container">
            <span className="our-locations-contact-us">OUR LOCATIONS</span>
            <h1 className="our-offices-contact-us">OUR OFFICES</h1>
            <div className="contact-us-office-main-container-2">
                {offices.map((office) => (
                    <OfficeLocation key={office.location} {...office} />
                ))}
            </div>
        </div>
    );
};

export default ContactUsLocation;
