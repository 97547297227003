import { useMediaQuery } from "react-responsive"
import Header from "../Header"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import { Helmet } from "react-helmet"
import { IoIosMail, IoIosCall } from "react-icons/io"
import { FaFacebookF, FaWhatsapp } from "react-icons/fa"
import { FaLocationDot } from "react-icons/fa6"
import Footer from "../Footer"
import "./index.css"

const OfficeLocation = ({ imgSrc, location, description, email, phone }) => (
    <div className="contact-us-office-locate-container">
        <img src={imgSrc} className="our-offices-img" alt={`${location}-office-img`} />
        <h1 className="office-location-head">{location}</h1>
        <p className="office-location-para">{description}</p>
        <div className="io-mail-contact-us">
            <IoIosMail className="contact-us-office-main-icon" />
            <p className="info-mail-para">{email}</p>
        </div>
        <div className="io-mail-contact-us">
            <IoIosCall className="contact-us-office-main-icon" />
            <p className="info-mail-para">{phone}</p>
        </div>
        <div className="contact-us-btn-ic">
            <button type="button" className="contact-us-office-main-icon-btn">
                <FaFacebookF className="contact-us-office-main-icon btn-cont-2" />
            </button>
            <button type="button" className="contact-us-office-main-icon-btn">
                <FaWhatsapp className="contact-us-office-main-icon btn-cont-2" />
            </button>
            <button type="button" className="contact-us-office-main-icon-btn">
                <IoIosMail className="contact-us-office-main-icon btn-cont-2 btn-cont-3" />
            </button>
            <button type="button" className="contact-us-office-main-icon-btn">
                <FaLocationDot className="contact-us-office-main-icon btn-cont-2" />
            </button>
        </div>
    </div>
);

const OurBranches = () => {
    const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' })
    const offices = [
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705035343/qhjbpiwy0jhodc9k4nwg.png",
            location: "Bolpur",
            description: "Headquarter",
            email: "info@insucreation.in",
            phone: "+91 9679985972",
        },
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038567/xvyeqpzabttlx1xo67ga.png",
            location: "Tamluk",
            description: "Registered Office",
            email: "info@insucreation.in",
            phone: "+91 9547361869",
        },
        {
            imgSrc:
                "https://res.cloudinary.com/dlj7gj1po/image/upload/v1705038617/vvevsslz3xr3ymd9ajud.png",
            location: "Bardhaman",
            description: "Branch Office",
            email: "info@insucreation.in",
            phone: "+91 9547361869",
        },

    ];

    return (
        <div>
            <Helmet>
                <title>Connect with InsuCreation: Reach Out for Professional Guidance and Support</title>
                <meta name="description" content="Contact InsuCreation today for expert assistance and personalized solutions. Our team is ready to address your inquiries, provide professional guidance, and offer support tailored to your needs. Reach out now to start the conversation." />
                <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />
            </Helmet>
            <Header />
            {mediaQueryCondition ? <Navbar /> : <NavbarInOtherPage className="navbar-in-contact" />}
            <div className="contact-us-office-main-container">
                <span className="our-locations-contact-us">OUR LOCATIONS</span>
                <h1 className="our-offices-contact-us">OUR BRANCHES</h1>
                <div className="contact-us-office-main-container-2">
                    {offices.map((office) => (
                        <OfficeLocation key={office.location} {...office} />
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default OurBranches;
