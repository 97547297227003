import { motion } from "framer-motion"
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'


import "./index.css";

const OurClientsComponent = () => {

	const settings = {
		dots: false,
		speed: 500,
		arrows: false,
		autoplay: true,

		slidesToShow: 4,
		slidesToScroll: 1,
		transition: 0.5
	}
	const settingsMobileView = {
		dots: false,
		speed: 500,
		arrows: false,
		autoplay: true,

		slidesToShow: 2,
		slidesToScroll: 1,
		transition: 0.5
	}
	const mediaQueryCondition = useMediaQuery({ query: '(max-width:767px)' })
	const mediaQueryConditionTr = mediaQueryCondition ? settingsMobileView : settings
	return (
		<div className='our-clients-main-container'>
			<h1 className='our-clients-main-head'>Our Clients</h1>
			<div className='our-clients-slider-container'>
				<div className='our-clients-slider-container-1'>
					<Slider {...mediaQueryConditionTr}>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/Dr Bedanka Roy_logo.jpg')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>Dr Bedanka Roy</h1>
							</div>
						</motion.div>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/ourClientslogo2.png')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>BITM Santiniketan</h1>
							</div>
						</motion.div>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/CAMELIA_GROUP_OF_SCHOOL_LOGO_REDESIGN-1.jpg')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>Camellia Group of School</h1>
							</div>
						</motion.div>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/Career_30Logo_white.jpg')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>Career 30</h1>
							</div>
						</motion.div>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/Cha_Ta_Logo_white.jpg')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>Chata</h1>
							</div>
						</motion.div>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/Dristikon_Logo_WHITE.jpg')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>Dristikon</h1>
							</div>
						</motion.div>
						<motion.div className='our-clients-image-container-2'>
							<div className='our-clients-image-container-2'>
								<div className='our-clients-image-container'>
									<img src={require('../InsuImages/Cloudempire_Logo.jpg')} className='our-clients-img-s' alt="our-clients-img-s" />
								</div>
								<h1 className='our-clients-company-name'>Cloud Empire</h1>
							</div>
						</motion.div>
					</Slider>
				</div>
			</div>
		</div>
	)
}

export default OurClientsComponent