import {motion} from "framer-motion"
import "./index.css"

const ListOfTechNames=()=>(
  <div className="mob-view-tech-logos">
  <div className="image-tech-main-ceontainer">
  <motion.div  className="image-tech-container cont4">
    <img
      src={require('../InsuImages/php.png')}
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  
  <motion.div className="image-tech-container cont4">
    <img
      src={require('../InsuImages/net.png')}
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  <motion.div className="image-tech-container cont4">
    <img
      src="https://logowik.com/content/uploads/images/rails5177.logowik.com.webp"
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  <motion.div className="image-tech-container cont4">
    <img
      src="https://logos-world.net/wp-content/uploads/2021/10/Python-Symbol.png"
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  <motion.div className="image-tech-container cont4">
    <img
      src="https://static-00.iconduck.com/assets.00/node-js-icon-227x256-913nazt0.png"
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  <motion.div  className="image-tech-container cont4">
    <img
      src={require('../InsuImages/drupal.png')}
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  <motion.div className="image-tech-container cont4">
    <img
      src="https://logos-world.net/wp-content/uploads/2020/10/WordPress-Logo.png"
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  <motion.div className="image-tech-container cont4">
    <img
      src="https://www.andolasoft.com/blog/wp-content/uploads/2012/11/new-magento-logo.png"
      className="tech-name-logo"
      alt="tech-name-logo"
    />
  </motion.div>
  </div>
</div>
)
export default ListOfTechNames