import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import {Link} from "react-router-dom"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const HowWeWork=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })

  return(
    <>
    <Helmet>
    <title>Our Approach | Insu Creation - Empowered Teams Shaping a Fairer Financial System</title>
    <meta name="description" content="Insu Creation's Secret Sauce: We're like a jazz band of empowered teams, harmonizing to create a fairer financial masterpiece. Our mission? Crafting digital symphonies that echo our customers' voices in every note of excellence." />
    <meta name="keywords" content="Insu Creation, Collaboration, Empowered Teams, Fair Financial System, Customer-Centric Approach, Innovation, Excellence, Transparency, Trust, Digital Solutions, Web Development, App Development, Graphic Design, Content Writing, Digital Marketing" />

    </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head using-empowered-mob-head">Using empowered teams, we accomplish our goals</h1>
    <p className="transform-para transform-2-para">Insu Creation values collaboration. To shape a brighter future, we believe in empowered teams. Creating a fairer financial system that benefits real people is our mission, and we ensure our customers' voices are heard loudest. </p>
    <p className="transform-col-pa">Collaboration doesn't end there. As a team, we understand that teamwork can:</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Create a positive image of your brand</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Improve your relationships and connections</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Convey Messages and Convince Your Audience</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Influence an Outcome</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Show Our Area of Expertise </p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Reinforce Topics in Our Company Culture</p>
        </div>
      </li>
    </ul>
    <p className="transform-col-pa transform-col-pa-2">At Insu Creation, we're not just a team; we're a family working together to make a difference. Our commitment to collaboration drives our success, and we invite you to join us on this exciting journey towards a fairer and brighter future in the digital world.</p>
    <div className="no-transform-button-container">
    <Link to="/contactus"><button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting"><button type="button" className="no-transform-btn-2">Book an appointment now</button></Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706505687/c30tmfwyhs9qtrsomr49.gif" className="seo-route-img-main business-pre-img business-pre-img-2 how-we-work-mob-im how-2-we-work-mob-im how-2-we-work-mob-im-hww" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1 how-we-work-2-insu">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706506120/f0yjj6x8evvln7mqerfu.gif" className="how-we-work-in-insu-img how-2-we-work-mob-im-hww" alt="" />

    <div className="how-we-work-2-insu-3">
        <h1 className="react-native-business-dev how-we-work-2-insu-3-con">How We Work at Insu Creation</h1>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">Insu Creation's approach to work is unique and customer-centric. Our empowered teams are driven to solve urgent customer challenges, making us stand out in the industry. We believe in shared responsibility, where every team member contributes to our success.</p>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">We empower our Wisers to make decisions and take ownership of their work style, recognizing that different teams and individuals have varying needs. While we have company-wide principles, teams have the freedom to set their guidelines, ensuring a balance between consistency and autonomy.</p>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">Despite rapid growth, we embrace change and welcome new talents, making our journey dynamic and forward-looking. Join us as we redefine the digital landscape.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>
    </div>
 </div>


 <div className="react-native-business-dev-main" data-aos="zoom-in">
    <div className="react-native-business-dev-main-1 flutter-business-dev-main-1 how-we-work-2-insu">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706506218/uodda9udqiq8knlg6nh1.gif" className="react-native-mid-img social-media-content-img-n how-we-work-3-insu-img" alt="" />

    <div className="how-we-work-2-insu-3">
        <h1 className="react-native-business-dev how-we-work-2-insu-3-con">Customer-Centric Approach at Insu Creation</h1>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">At Insu Creation, our customers are at the core of everything we do. We're on a mission to create a fairer financial system for real people, and that starts with putting our customers first.</p>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">We prioritize customer feedback, invite them to test our products, and engage in meaningful conversations about their experiences. Listening and serving are our guiding principles — no egos, no exceptions.</p>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">Each of our teams is dedicated to making a positive impact on our customers' lives, with clear KPIs to measure success. We're proud to stand before our company and explain how our work benefits our customers.</p>
        <p className="react-native-business-dev-para how-we-work-2-insu-3-con-para">Join us in our customer-centric journey at Insu Creation.</p>

        <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Learn More<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </div>

    </div>
 </div>

 <div className="complete-flow-of-work-inner-container-main-con" data-aos="zoom-in">
 <span className="our-locations-contact-us our-wk web-work-w" style={{'fontSize':'20px'}}>How We Work</span>

        <h1 className="complete-flow-of-work-head">We follow a complete flow of work</h1>
        <div className="complete-div-con">
        <div className="complete-flow-of-work-inner-container-main">
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706506557/sv02oayhoxaq6j54fk2e.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Initially interacted with</h1>
                <p className="complete-flow-of-work-inner-para">The first step is to schedule our first interaction after we receive your interest in our organization. During this conversation, our team member will get to know your business and your needs.</p>
            </div>
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706509805/cgqio3aiqto9nul5gicb.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">A preliminary design</h1>
                <p className="complete-flow-of-work-inner-para">Once the solution has been approved, we will design the initial pages and show them to you. In the event that any changes need to be made, we make them. Upon your approval, we proceed with the rest of the changes.</p>
            </div>
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510007/kjhnxwotlhmryn4dms10.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Providing a solution</h1>
                <p className="complete-flow-of-work-inner-para">In the event that we come up with a solution and some suggestions for you, we will make sure that they are shared with you as soon as possible in order for you to benefit from them.</p>
            </div>
            <div className="complete-flow-of-work-inner-container fourth-img-con-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510137/nraxkone52zfpugjb10y.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Discuss as a team</h1>
                <p className="complete-flow-of-work-inner-para">As part of the process, our team takes the time to discuss your business and your requirements in order to come up with the best possible solution that is tailored to your business and your requirements.</p>
            </div>
            </div>
            
            
            
            
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706508476/ubayg0fqwfhnwvge28ar.png" className="curve-line-how-we-work" alt="" />
            <div className="complete-flow-of-work-inner-container-main">
            <div className="complete-flow-of-work-inner-container fourth-img-con-mobile-hide">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510137/nraxkone52zfpugjb10y.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Discuss as a team</h1>
                <p className="complete-flow-of-work-inner-para">As part of the process, our team takes the time to discuss your business and your requirements in order to come up with the best possible solution that is tailored to your business and your requirements.</p>
            </div>
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510218/o8ryvyq6fixeddhc4n7j.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Developing</h1>
                <p className="complete-flow-of-work-inner-para">For the purpose of creating a working website, we use the most efficient methods and layouts for design layouts. These layouts have been designed specifically for the purpose of creating a working website for the client.</p>
            </div>
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510284/u9tfiptsdr7fuan9gv2e.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Performing tests</h1>
                <p className="complete-flow-of-work-inner-para">For your convenience, we test the Website on a regular basis to ensure that there are no bugs or errors, and we fix them as soon as possible so that you can enjoy a hassle-free experience.</p>
            </div>
            </div>
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706508476/ubayg0fqwfhnwvge28ar.png" className="curve-line-how-we-work" alt="" />
            <div className="complete-flow-of-work-inner-container-main">
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510465/dph6hvpwbeauxfzo0ldo.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Review of final report</h1>
                <p className="complete-flow-of-work-inner-para">In order to ensure the quality of the document, once it has been created, the final version is shared with you for review. A final revision to the website will be carried out before it is made live on the internet for the public to view.</p>
            </div>
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510547/efob2auqu8hizn36gejc.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Launching</h1>
                <p className="complete-flow-of-work-inner-para">In order to make the website live on the Internet, it must be made live. There are other services that can be offered as well, such as digital marketing, search engine optimization, and so on. We will start working on it as soon as possible.</p>
            </div>
            <div className="complete-flow-of-work-inner-container">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706510604/poefksbrhkklyblu0vll.png" className="how-we-work-rote-img" alt="" />
                <h1 className="complete-flow-of-work-inner-head">Providing support</h1>
                <p className="complete-flow-of-work-inner-para">Depending on the type of support package you choose, you may be able to purchase a package for a period of time that may be extended or limited, while others may only be available for a specific amount of time.</p>
            </div>
            </div>
        </div>
 </div>
 <div className="complete-flow-of-work-inner-container-main-con sid-2">
 <span className="our-locations-contact-us our-wk web-work-w">SERVICE WE PROVIDE</span>
    <h1 className="portfolio-head-rote web-dev-how-we">Web Development Process</h1>
    <div className="complete-div-con">
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-mobile-v">
                <h1 className="preparation-brief-head">Preparation/Brief</h1>
                <p className="preparation-brief-para">Understand your business, your environment and your competition</p>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-mobile-v">
                <h1 className="preparation-brief-head">Planning</h1>
                <p className="preparation-brief-para">Identify what is needed in terms of content, features and design elements</p>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-mobile-v">
                <h1 className="preparation-brief-head">Production</h1>
                <p className="preparation-brief-para">The exciting bit – at the end of this process you have a completed website</p>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-mobile-v">
                <h1 className="preparation-brief-head">Launch(Put live)</h1>
                <p className="preparation-brief-para">Get the site operational, put it live, and setup the technology</p>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-mobile-v">
                <h1 className="preparation-brief-head">Post Launch-Marketing</h1>
                <p className="preparation-brief-para">Making your website “really” work for you in terms of traffic and sales</p>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
        </div>
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706514182/dhinev3ww13oyxqv4tgn.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Brief</h1>
                <p className="preparation-brief-para">• Site objectives</p>
                <p className="preparation-brief-para">• Target market</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517240/yopzemgn11bjfplrmzfq.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Planning</h1>
                <p className="preparation-brief-para">• Page structure</p>
                <p className="preparation-brief-para">• Site tools & features</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517317/ty2p3nw3wj0ui7burtzg.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Cut CSS</h1>
                <p className="preparation-brief-para">• Create framework</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517381/e8mt5fsngvtqupmglggv.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Lunch</h1>
                <p className="preparation-brief-para">• Create hosting</p>
                <p className="preparation-brief-para">• Create emails</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517443/xjkync83ukiyc7u9klat.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Analytics</h1>
                <p className="preparation-brief-para">• Generate rankings</p>
                <p className="preparation-brief-para">• PPC for traffic</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile preparation-brief-div-back-2-hide-1">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517723/zs1udu9b6lboynsfgn6e.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Research</h1>
                <p className="preparation-brief-para">• Competitor research</p>
                <p className="preparation-brief-para">• Environment research</p>
            </div>

        </div>




        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517723/zs1udu9b6lboynsfgn6e.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Research</h1>
                <p className="preparation-brief-para">• Competitor research</p>
                <p className="preparation-brief-para">• Environment research</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile preparation-brief-div-back-2-hide-1">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517723/zs1udu9b6lboynsfgn6e.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Research</h1>
                <p className="preparation-brief-para">• Competitor research</p>
                <p className="preparation-brief-para">• Environment research</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517762/guj0bbliultd9eavrolv.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Content</h1>
                <p className="preparation-brief-para">• Keyword research</p>
                <p className="preparation-brief-para">• Page & content plan</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517799/rwnexapxpx4d9tdfehje.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Construct</h1>
                <p className="preparation-brief-para">• Construct site</p>
                <p className="preparation-brief-para">• Install CMS</p>
            </div> 
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517839/tcdpypk3eqexahkfc2wb.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Analytics</h1>
                <p className="preparation-brief-para">• Add analytics</p>
                <p className="preparation-brief-para">• Add tools</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517888/cmryt2xu0sybkrgfiajs.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Eflyers</h1>
                <p className="preparation-brief-para">• Awareness</p>
                <p className="preparation-brief-para">• Keep top of mind</p>
            </div>
        </div>
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518519/q7rles901dn4f6c4g7le.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Strategy</h1>
                <p className="preparation-brief-para">• Sector site review</p>
                <p className="preparation-brief-para">• Site strategy</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517240/yopzemgn11bjfplrmzfq.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Design</h1>
                <p className="preparation-brief-para">• Design features</p>
                <p className="preparation-brief-para">• Design site</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518561/cl8rggnukmer6u6xilpf.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Optimise</h1>
                <p className="preparation-brief-para">• Enter text</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518615/xkxhezpkxxjmzvb0nsie.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Review</h1>
                <p className="preparation-brief-para">• Check statistics</p>
                <p className="preparation-brief-para">• Benchmark</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518650/r7vnykuszm4bbssznzux.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Social</h1>
                <p className="preparation-brief-para">• “Buzz” + branding</p>
                <p className="preparation-brief-para">• Traffic/cross sales</p>
            </div>
        </div>
        <p className="all-our-sites-para">All our sites automatically include statistics, css programming (for speed of load), a contact form (includes a google location map), subscribe facilities (if required). We ensure sites are search engine friendly. By focusing on functionality, usability and search engine optimization during the construction of your website, we maximise your business opportunities.</p>
        <div className="want-enquire-new-btn">
      <p className="want-enquire-new-btn-para black-para">Want to <span className="want-enquire-new-btn-span">Start</span> a New Project</p>
  
      <button type="button" className="new-want-btn new-want-btn-1">Enquire Now</button>
    </div>
    </div>
 </div>
 <div className="graphic-process-bg" data-aos="zoom-in">
  <h1 className="portfolio-head-rote web-dev-how-we">Graphic Designing Process</h1>
  <div className="graphic-arrow-main-container">
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">1. Creative brief</h1>
    <p>The creative brief sets the tone for the entire project. It's the first and arguably one of the most important steps in the visual design process.
A creative brief is a document that’s aimed to help the designer understand the scope of the project and what’s needed from them. You’ll want to include as much relevant information as possible to reduce any confusion, and as a result, back and forth.</p>
    <p>Be sure to include the following in your brief:</p>

    <ul className="how-we-arrow-list">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Budget</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Company details.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Brand guidelines.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Target audience.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">What the final product should be (I.e. brochure, eBook, etc).</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Timeline expectations and milestones</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">What you intend viewers to do when they see the final product.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Examples of similar work you like (and don’t).</p>
        </div>
      </li>
    </ul>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706520417/rvafe7npvvywaaptaudi.png" alt="" className="bulb-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521273/w6pkyfn8xtzdr6fejonu.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">2. Conduct graphic design research</h1>
    <p>Depending on the scope of the project, you’ll want to include as much valuable information in your creative brief to minimize the time spent in this step. However, it’s still extremely valuable for designers to go through the researching phase themselves to build a better understanding of the audience they’re designing for (and how to make it work for you).</p>
    <p>The research phase of the graphic design process often involves things like:</p>

    <ul className="how-we-arrow-list how-we-arrow-list-2">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Looking at competitor designs.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para right-how-we-para-2">Look at what the intended audience is engaging with on channels like social media.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Taking all of the knowledge they’ve learned and applying it to the project at hand.</p>
        </div>
      </li>
    </ul>
    <p className="designers-may-head">Designers may also want to create a mood board or collection of comparable designs at this stage in order to get their ideas flowing. More on that below!</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521966/fqbeff0tot8krma3jzu4.png" alt="" className="bulb-img-how research-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706522025/hi1y5vdkz8wva6gbzrsf.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2 ">
    <h1 className="portfolio-head-rote web-dev-how-we">3. Brainstorm your ideas</h1>
    <p>Before a designer goes full-throttle into a project, have them brainstorm some ideas and present them to you. This will minimize any frustration throughout the creative process. Don’t forget to outline this step in your creative brief!
If the project owner (who created the brief) has really strong ideas on how the final designs should look, it’s best for them to add in any relevant links or inspiration into the brief for optimal clarity.</p>
    <p>Once the designer presents 3-5 ideas from their brainstorming session, decide on which one you want to move forward with. This will make for a smooth graphic design workflow process that gets you the best results, fast.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706522181/xeeoqfohdasuvckbcplj.png" alt="" className="bulb-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521273/w6pkyfn8xtzdr6fejonu.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">4. Review at the 10/50/99 stage</h1>
    <p>When determining your milestones in your creative brief, be sure to check in with designers throughout the design process. The 10/50/99 feedback process ensures that you’re checking in at the most crucial parts of a project, allowing you to give the right feedback at the right time:</p>

    <ul className="how-we-arrow-list how-we-arrow-list-2">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Looking at competitor designs.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para right-how-we-para-2">Look at what the intended audience is engaging with on channels like social media.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Taking all of the knowledge they’ve learned and applying it to the project at hand.</p>
        </div>
      </li>
    </ul>
    <p className="designers-may-head">Often, people will break these review rules and start giving feedback on things like colour choice or fonts at a stage where the designer has only outlined a skeleton. This is not only frustrating for the designer, it’s unproductive! Make sure to follow the above design review stages to keep your projects moving along smoothly.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521966/fqbeff0tot8krma3jzu4.png" alt="" className="bulb-img-how research-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706522025/hi1y5vdkz8wva6gbzrsf.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">5. Present the final product</h1>
    <p>The design is complete. It’s time to get the final files (and invoice if working with an external design resource) and put the designs into action. If you want to go the extra mile, ask your designer for feedback on how they found the process, what they feel could be improved or tweaked and then iterate your process. Over time, you’ll be able to scale your design process effectively.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706522461/cpoctqyfjzuxidgnvhhe.png" alt="" className="bulb-img-how" />
    </div>
  </div>
  
 </div>
 <div className="complete-flow-of-work-inner-container-main-con sid-2">
    <h1 className="portfolio-head-rote web-dev-how-we">Mobile App Development Process</h1>
    <div className="complete-div-con">
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-3 preparation-mobile-v">
                <h1 className="preparation-brief-head">Idea Generation & Validation</h1>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-mobile-v">
                <h1 className="preparation-brief-head">UX/ UI Design</h1>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-mobile-v">
                <h1 className="preparation-brief-head">Development</h1>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-mobile-v">
                <h1 className="preparation-brief-head">Testing & QA</h1>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-mobile-v">
                <h1 className="preparation-brief-head">Launch & Deployment</h1>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-mobile-v">
                <h1 className="preparation-brief-head">Marketing & Maintenance</h1>
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706513651/colv9jvnao06udnwql4b.png" className="arrow-preap" alt="" />
            </div>
        </div>
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706593893/awfgqvnc0bqt1cc4up0z.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Brainstorming and Market Research</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706593947/mgaoubax3ra3ceh11jzr.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Information Architecture</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706593985/abz31pbym5oslv1vecs9.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Native/ Cross-platform/ Hybrids</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706594020/ellmrlacuwa6hddrvrii.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Test case preparation</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706594079/h1i9pxfekglhaxfsvrie.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Finalizing Build</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706594119/agtcdpisjizosnsgbduc.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• App marketing strategy</p>
            </div>
        </div>
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706594493/hkufuy6wljslylghormt.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Development strategy</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-3 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706594583/kkvkb5ha83g1diucofkv.png" alt="" className="img-preap-2" />
                <p className="preparation-brief-para">• Wireframing</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517723/zs1udu9b6lboynsfgn6e.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Research</h1>
                <p className="preparation-brief-para">• Competitor research</p>
                <p className="preparation-brief-para">• Environment research</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517799/rwnexapxpx4d9tdfehje.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Construct</h1>
                <p className="preparation-brief-para">• Construct site</p>
                <p className="preparation-brief-para">• Install CMS</p>
            </div> 
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517839/tcdpypk3eqexahkfc2wb.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Analytics</h1>
                <p className="preparation-brief-para">• Add analytics</p>
                <p className="preparation-brief-para">• Add tools</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-back-2 preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517888/cmryt2xu0sybkrgfiajs.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Eflyers</h1>
                <p className="preparation-brief-para">• Awareness</p>
                <p className="preparation-brief-para">• Keep top of mind</p>
            </div>
        </div>
        <div className="preparation-brief-div-main preparation-brief-div-main-mob-prep" data-aos="fade-up">
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518519/q7rles901dn4f6c4g7le.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Strategy</h1>
                <p className="preparation-brief-para">• Sector site review</p>
                <p className="preparation-brief-para">• Site strategy</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706517240/yopzemgn11bjfplrmzfq.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Design</h1>
                <p className="preparation-brief-para">• Design features</p>
                <p className="preparation-brief-para">• Design site</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518561/cl8rggnukmer6u6xilpf.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Optimise</h1>
                <p className="preparation-brief-para">• Enter text</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518615/xkxhezpkxxjmzvb0nsie.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Review</h1>
                <p className="preparation-brief-para">• Check statistics</p>
                <p className="preparation-brief-para">• Benchmark</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518561/cl8rggnukmer6u6xilpf.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Optimise</h1>
                <p className="preparation-brief-para">• Enter text</p>
            </div>
            <div className="preparation-brief-div preparation-brief-div-small-content-mobile">
                <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706518650/r7vnykuszm4bbssznzux.png" alt="" className="img-preap-2" />
                <h1 className="preparation-brief-head">Social</h1>
                <p className="preparation-brief-para">• “Buzz” + branding</p>
                <p className="preparation-brief-para">• Traffic/cross sales</p>
            </div>
        </div>
        <div className="want-enquire-new-btn want-black-div">
      <p className="want-enquire-new-btn-para black-para">Want to <span className="want-enquire-new-btn-span">Start</span> a New Project</p>
  
      <button type="button" className="new-want-btn new-want-btn-1">Enquire Now</button>
    </div>
    </div>
 </div>
 <div className="graphic-process-bg" data-aos="zoom-in">
  <h1 className="portfolio-head-rote web-dev-how-we">Digital Marketing Process</h1>
  <div className="graphic-arrow-main-container">
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">Step 1: Research</h1>
    <p>At this stage, you will collect all the information that will be required for decision making in the next stages. Information collected during the research will become your raw material to strategize & create your digital marketing campaign. This stage can also be called as Digital Marketing Research. At this stage, you will research 4 sets of information:</p>
    <p>Be sure to include the following in your brief:</p>

    <ul className="how-we-arrow-list">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">1. About Business</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">2. About Your Target Customers</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">3. About Online Competition</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">4. About The Product That You Want To Market</p>
        </div>
      </li>
    </ul>
    <p className="designers-may-head">Each set is unique & equally important. You will require multiple sources to collect the information.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706591277/u3hxtbgrhkldti4yvfeu.png" alt="" className="bulb-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521273/w6pkyfn8xtzdr6fejonu.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">Step 2: Conduct graphic design research</h1>
    <p>Depending on the scope of the project, you’ll want to include as much valuable information in your creative brief to minimize the time spent in this step. However, it’s still extremely valuable for designers to go through the researching phase themselves to build a better understanding of the audience they’re designing for (and how to make it work for you).</p>
    <p>The research phase of the graphic design process often involves things like:</p>

    <ul className="how-we-arrow-list how-we-arrow-list-2">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Looking at competitor designs.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para right-how-we-para-2">Look at what the intended audience is engaging with on channels like social media.</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">Taking all of the knowledge they’ve learned and applying it to the project at hand.</p>
        </div>
      </li>
    </ul>
    <p className="designers-may-head">Designers may also want to create a mood board or collection of comparable designs at this stage in order to get their ideas flowing. More on that below!</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521966/fqbeff0tot8krma3jzu4.png" alt="" className="bulb-img-how research-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706522025/hi1y5vdkz8wva6gbzrsf.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">Step 3: Promote</h1>
    <p>After your primary digital identities are fully ready, you will start promoting them. That means you want relevant people to start coming to your primary digital identities. This is also called as generating relevant traffic. Relevant traffic is an important word here.</p>
    <p>The more you get relevant traffic to your website, the more the conversion you can expect. Your options to promote your website/blog / app will be:</p>
    <ul className="how-we-arrow-list">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">1.Search Engines</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">2.Display Network</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">3.Ecommerce Portals</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">4.Social Media</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">5.Email</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">6.Messaging</p>
        </div>
      </li>
    </ul>
    <p className="designers-may-head">The above are also known as Digital Marketing Channels, which you need to promote your Primary Digital Identities (Website / Blog / App). There are sub-channels & networks within some of the channels mentioned above.
Which channels, subchannels, networks to go for & whether to do organic or inorganic promotions, these questions will already be answered at the Digital Marketing Strategy creation stage.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706591467/ksbhastisdjhi1kmordx.png" alt="" className="bulb-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706521273/w6pkyfn8xtzdr6fejonu.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">Step 4: Analyse</h1>
    <p>When determining your milestones in your creative brief, be sure to check in with designers throughout the design process. The 10/50/99 feedback process ensures that you’re checking in at the most crucial parts of a project, allowing you to give the right feedback at the right time:</p>

    <ul className="how-we-arrow-list how-we-arrow-list-2">
      <li className="how-we-arrow-list-item">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">1.Audiences</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para right-how-we-para-2">2.Acquisition</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">3.Behaviour</p>
        </div>
      </li>
      <li className="how-we-arrow-list-item">
        <div className="list-transform list-transform-2">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-how-we-img"
            alt="right-check"
          />
          <p className="right-how-we-para">4.Conversion</p>
        </div>
      </li>
    </ul>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706591693/m4is2ui1foslu2qqyws9.png" alt="" className="bulb-img-how research-img-how" />
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706522025/hi1y5vdkz8wva6gbzrsf.png" className="cross-arrow-2-si" alt="" />
    <div className="bulb-how-we-work-con">
      <div className="bulb-how-we-work-con-2">
    <h1 className="portfolio-head-rote web-dev-how-we">Step 5: Optimize</h1>
    <p>At this stage, based on analysis & observations, you start making changes (fine-tuning). The changes could be in your primary digital identities or digital marketing channels. Changes are also referred to as content & design of your identities & promotional communication.</p>
    </div>
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706591769/wpfhomm5gknnms8okrau.png" alt="" className="bulb-img-how" />
    </div>
  </div>
  
 </div>
 <Footer />
    </>
)}
AOS.init()
export default HowWeWork