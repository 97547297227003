import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import AOS from "aos"
import DetailsForm from "../DetailsForm";
import Navbar from "../Navbar";
import Header from "../Header"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Footer from "../Footer"

import "./index.css"

const Backend=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })

  return(
    <>
    <Helmet>
    <title>Insu Creation: Maximizing Your Application's Potential with Tailored Backend Development Services</title>
    <meta name="description" content="Unlock the full potential of your Insu Creation application with our specialized backend development services. From customized solutions to enhanced security measures, we ensure seamless scalability and improved performance, empowering your business to thrive in the digital landscape"/>
    <meta name="keywords" content="Insu Creation, website support, SEO, social media marketing, Custom Themes, Responsive Design, Plugin Development, eCommerce Solutions, Expert Optimization, online success" />

</Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Maximize Your Application's Potential with Our Insu Creation Backend Development Services</h1>
    <p className="transform-para transform-2-para">Whether you're creating a new application from scratch or aiming to enhance an existing one, our Insu Creation backend services are tailored to boost your application's performance and capabilities. By leveraging our expertise, you can optimize your application to handle increased traffic and seamlessly integrate new features as required.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Customized solutions: We tailor our services to precisely meet the unique requirements of your Insu Creation application, ensuring optimal functionality and performance.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Scalability: As your business expands, it's crucial that your Insu Creation application can scale accordingly. Our backend development ensures your application can grow alongside your business without compromising performance or user experience.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Improved performance: Experience faster load times, minimized errors, and an overall smoother user experience with our backend optimizations for Insu Creation.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Enhanced security:Security is paramount in today's digital landscape. Our backend development includes robust measures to safeguard your Insu Creation data and fortify the security of your application against potential threats.</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Expert guidance: Benefit from our expertise and guidance to align your backend development strategies with your Insu Creation business objectives effectively. We're here to support you in achieving your goals through efficient backend development practices tailored specifically for Insu Creation.</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button></Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
   <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708526854/gv80g8hgxpredjw5gclu.png" className="new-image-frontend-b respon-img-backend" alt="" />


 </div>
 </div>
 <div className="backend-technologies-con-route-main">
  <div className="backend-technologies-con-route">
    <h1 className="backend-technologies-we-use">TECHNOLOGIES WE USE FOR BACK-END DEVELOPMENT</h1>
    <hr className="backend-technologies-we-use-line" />
    <div className="backend-technologies-main-container">  
    
                <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705727320/dzlh5eim8oftucreuapn.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">PYTHON</h1>
    <p className="backend-route-para">Python is the worlds most popular programming language at the moment</p>
    </div>
  
                <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705728612/yoeedwxfjkidjmcvifi9.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">DJANGO</h1>
    <p className="backend-route-para">Django is a highly secure and hi-functional web framework made with python</p>
    </div>
  

   
                <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705728789/rr8spxi9liu7kli5qdwq.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">LARAVEL</h1>
    <p className="backend-route-para">Laravel is a highly secured and hi-functional web framework made with php</p>
    </div>
    

  
                <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705728848/ww7e6kt8ctbmvuhrokyd.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">PHP</h1>
    <p className="backend-route-para">Php is a server-side programming language and can be easily integrated with HTML</p>
    </div>
    

    
                <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705728907/iqmyvvsiis0bi48xdsi1.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">NODE JS</h1>
    <p className="backend-route-para">Node Js is the most demanded framework of Java Script because of its powerful features</p>
    </div>
  
    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729156/dyzuzikwf8slixqq6uqh.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">DOCKER</h1>
    <p className="backend-route-para">Multiple languages, frameworks, micro-services interfaces between tools for each lifecycle stage.</p>
    </div>

    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729830/c0grdfkqvcsa8iexluod.webp" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">MONGO DB</h1>
    <p className="backend-route-para">MongoDB is a document database, which means it stores data in JSON-like documents.</p>
    </div>

    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729905/qjscdkctyxkd3hjphenz.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">Bubble.io</h1>
    <p className="backend-route-para">This platform enables users to build web applications without writing code. The future, according to some, is "no code."</p>
    </div>

    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729362/emrr3rigfx9jfsvpscyp.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">WORDPRESS</h1>
    <p className="backend-route-para">WordPress is, by far, the most popular open source Content Management System (CMS).</p>
    </div>

    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729396/uiamprs6wac7r1msxsh0.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">POSTGREE</h1>
    <p className="backend-route-para">There are two APIs for communicating with a PostgreSQL database, and both are available as gems.</p>
    </div>

    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729462/vecgo1fllvc950llinvx.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">MYSQL</h1>
    <p className="backend-route-para">MySQL Database Service is a fully managed database service to deploy cloud-native applications.</p>
    </div>

    <div className="tech-use-img-container backend-development-img-container">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705729574/ckct6pmj2l06wi6lwmkm.png" className="tech-use-img backend-img-route" alt="" />
  <h1 className="backend-route-head">Webflow</h1>
    <p className="backend-route-para">It is a design and development platform that enables users to build responsive websites and web applications without writing code.</p>
    </div>

    </div>
  </div>
 </div>
 <Footer />
    </>
)}

export default Backend