import {motion} from "framer-motion"
import {Link} from "react-router-dom"
import './index.css'

const WhyChooseUs = () => (
  <div className="why-choose-us-container">
    <h1 className="why-choose-us-heading">WE DELIVER OUR BEST</h1>
    <p className="why-we-deliver why-we-deliver-mob-view">What makes Insu Creation different?</p>
    <p className="why-choose-us-description">We are the best at what we do.</p>
    <ul className="why-choose-us-list-container why-choose-us-list-container-mob-view">
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706687107/imfwimr1ggfszz2u56aa.png"
          className="services-img"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Innovative Design Philosophy:</h1>
          <p className="services-paragraph">
          Insu Creation's innovative design philosophy combines aesthetics with functionality seamlessly. The perfect combination of form and function is a hallmark of our creations.
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706689429/w50jmm362jctnszfqlnx.png"
          className="services-img"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Detailed Attention:</h1>
          <p className="services-paragraph">
          As a company, we pride ourselves on our meticulous attention to detail. We ensure the smallest of details are meticulously considered in every project we undertake.

          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706689606/pgjeiewezfllkuvdo9to.png"
          className="services-img services-img-i-2"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Customized Solutions:</h1>
          <p className="services-paragraph">
          As an organization, Insu Creation doesn't believe that there is one solution that fits all. To give our clients a truly personalized experience, we tailor our services based on their specific needs and preferences.

          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706689772/zeljaafmdqoi4tqwdvvn.png"
          className="services-img"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Tech-Leading:</h1>
          <p className="services-paragraph">
          In terms of technology, our team is at the forefront of the industry. With the use of the latest tools and techniques, we are able to deliver efficient and modern solutions that exceed client expectations.

          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706689900/xscmpuyzlsmpwwbncnxn.png"
          className="services-img services-img-2"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">A client-focused approach:</h1>
          <p className="services-paragraph">
          We place the satisfaction of our clients at the top of our priority list. We ensure our clients' vision is realized through our client-centric approach, which means that we engage with them throughout the project.
          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706689987/j1qw2ujapfyoj28yxdvd.png"
          className="services-img"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Dedicated Team:</h1>
          <p className="services-paragraph">
          A highly experienced and skilled team of professionals makes up our company. Whether it's designers or developers, our experts work seamlessly to deliver exceptional results.

          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706690122/bdei4agppbmeoqgztls2.png"
          className="services-img"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Track record of success:</h1>
          <p className="services-paragraph">
          Successful projects and satisfied clients have earned Insu Creation a reputation for excellence and reliability.

          </p>
        </div>
      </motion.li> 
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706690370/ccfu6czxd6lmjsycwzca.png"
          className="services-img"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Providing timely delivery:</h1>
          <p className="services-paragraph">
          As deadlines are of utmost importance, we understand their importance. As a result of our track record of delivering projects on time, we assure you that your project will be completed in a timely manner.

          </p>
        </div>
      </motion.li>
      <motion.li whileHover={{scale:1.09}} data-aos="fade-right" className="services-container services-container-mob-view">
        <img
          src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706690234/hijpdwaclf20bx7od5kj.png"
          className="services-img services-img-i-2"
          alt="services-img"
        />
        <div>
          <h1 className="services-heading">Solutions at a low cost:</h1>
          <p className="services-paragraph">
          Our competitive pricing ensures that our clients receive excellent value for their money while maintaining the highest standards of quality.

          </p>
        </div>
      </motion.li>
    </ul>
    <div className="want-enquire-new-btn">
      <p className="want-enquire-new-btn-para new-insu-btn-want-makes">Want to <span className="want-enquire-new-btn-span">Start</span> a New Project</p>
  
     <Link to="/contactus"> <button type="button" className="new-want-btn new-want-btn-1">Enquire Now</button></Link>
    </div>
  </div>
)

export default WhyChooseUs