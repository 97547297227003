import React,{useState} from "react";
import AOS from "aos"
import {Link} from "react-router-dom"
import "./index.css";



const ServicesDropdown=(props)=> {
  const [dropdown, setDropdown] = useState(false);
  const {newDr,newDr3}=props
  const changeCol=newDr?"new-dr-m":""
  const changeCol1=newDr3?"new-dr-m":""
  return (
  
    <div className="new-div-dp">
      <div
        className={dropdown ? `services-submenu services-submenu-2 servi-s-2 clicked ${changeCol} ${changeCol1}` : `services-submenu services-submenu-2 servi-s-2 ${changeCol} ${changeCol1}`}
        onClick={() => setDropdown(!dropdown)}
      >

        <div className="div-web-log-container">
          <div className="web-log-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706159529/r0dhmt7vhnfyu8f8ggfc.svg" className="web-log" alt="" />
          <h1 className="service-we-development-dd">Web Development</h1>
          </div>
          <ul className="service-we-development-dd-1">
            <li className="about-drop-2-list">
            <Link
              to="/frontend"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Frontend Web Development
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/wordpress"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              WordPress Development
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/backend"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Backend Web Development
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/php-development"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              PHP Development
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/shopify-developer"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Shopify Developer
            </Link>
            </li>
           </ul>
        </div>
        <hr className="hr-nav-n-line" />
        <div className="div-web-log-container">
          <div className="web-log-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706160875/ajiwssbyhwzjqbpkupmr.svg" className="web-log" alt="" />
          <h1 className="service-we-development-dd">Digital Marketing</h1>
          </div>
          <ul className="service-we-development-dd-1">
            <li className="about-drop-2-list">
            <Link
              to="/seo"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              SEO
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/social-media-marketing"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Social Media Marketing
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/digital-marketing"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Digital Marketing Funnel
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/local-seo-services"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Local SEO Services
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/technical-seo-services"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Technical SEO Services
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/google-analytics"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Google Analytics
            </Link>
            </li>
           </ul>
        </div>
        <hr className="hr-nav-n-line" />
        <div className="div-web-log-container">
          <div className="web-log-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706161117/j7eeihnlhqpeovlubyeo.svg" className="web-log" alt="" />
          <h1 className="service-we-development-dd">Mobile App Development</h1>
          </div>
          <ul className="service-we-development-dd-1">
            <li className="about-drop-2-list">
            <Link
              to="/react-native-development"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              React Native App Development
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/flutter-development"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Flutter App Development
            </Link>
            </li>
           </ul>
        </div>
        <hr className="hr-nav-n-line" />
        <div className="div-web-log-container">
          <div className="web-log-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706245585/fhaljnfskb56gzpke72p.svg" className="web-log" alt="" />
          <h1 className="service-we-development-dd">Content Development</h1>
          </div>
          <ul className="service-we-development-dd-1">
            <li className="about-drop-2-list">
            <Link
              to="/landing-page-development"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Landing Page Development
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/article-writing"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Article Writing
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/social-media-content"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Social Media Content
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/video-ads-for-ecommerce"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Video Ads For E-commerce
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/product-description-writing"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Product Description Writing
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/blog-content-writing"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Blog Content Writing
            </Link>
            </li>
           </ul>
        </div>
        <hr className="hr-nav-n-line" />
        <div className="div-web-log-container">
          <div className="web-log-container">
            <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706245585/fhaljnfskb56gzpke72p.svg" className="web-log" alt="" />
          <h1 className="service-we-development-dd">Graphics Design</h1>
          </div>
          <ul className="service-we-development-dd-1">
            <li className="about-drop-2-list">
            <Link
              to="/graphic-design"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Graphics Design
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/complete-branding"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Complete Branding
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/logo-designing"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Logo Designing
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/product-packaging"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Product Packaging Design
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/business-presentation"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              Business Presentation
            </Link>
            </li>
            <li className="about-drop-2-list">
            <Link
              to="/animation-vedio"
              onClick={() => setDropdown(false)}
              className="about-drop about-drop-2"
            >
              2D & 3D animated videos
            </Link>
            </li>
           </ul>
        </div>
        <div>
        </div>
      </div>
    </div>
  );
}
AOS.init()
export default ServicesDropdown;