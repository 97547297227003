import './index.css'

const Header = () => (
  <nav className="nav-container-head">
    <div className="google-logo-rating-container">
      <img
        src="https://cdn1.iconfinder.com/data/icons/logos-brands-in-colors/272/Google_2015_logo-512.png"
        alt="google-logo"
        className="google-img"
      />
      <img
        src="https://www.pngmart.com/files/7/Rating-Star-PNG-Clipart.png"
        className="star-img"
        alt="star-img"
      />
      <p className="rating-paragraph">Excellent 4.8 out of 5</p>
    </div>
    <div className="request-call-container">
      <p className="rating-paragraph">
        <span className='talk-to-space'>Talk to our Executive{' '} </span> <span className="number-glow-span">(+91) 9547361869 {' '}</span> or
      </p>
      <a href="tel:9547361869">
      <div className="call-back-container">
        <img src={require('../InsuImages/telephone.png')} alt="" className='telephone-img' />

        <p className="request-call-para">Request a Call Back</p> 
      </div> 
      </a>
    </div>   
  </nav>
)

export default Header