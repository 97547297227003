import { useState } from "react"
import Slider from 'react-slick'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useMediaQuery } from 'react-responsive'
import "./index.css";

const TestimonialsContainer = () => {
	// const [readMore1, setReadMore1] = useState(false);
	// const [readMore2, setReadMore2] = useState(false);
	// const [readMore3, setReadMore3] = useState(false);
	// const [readMore4, setReadMore4] = useState(false);
	const [readMore5, setReadMore5] = useState(false);
	const [readMore6, setReadMore6] = useState(false);
	const [readMore7, setReadMore7] = useState(false);
	const [readMore8, setReadMore8] = useState(false);

	const settings = {
		dots: false,
		speed: 800,
		autoplay: true,
		arrows: false,
		slidesToShow: 3,
		slidesToScroll: 1,
	}
	const settingsMob = {
		dots: false,
		speed: 800,
		autoplay: true,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
	}
	const mediaQueryCondition = useMediaQuery({ query: '(max-width:767px)' })
	const mediaQueryConditionT = mediaQueryCondition ? settingsMob : settings
	return (
		<div className='testmonials-container-web'>
			<h1 className='text-radius text-light text-animation bg-b services-testimonials-head'>SERVICE TESTIMONIALS</h1>
			<p className='client-testimonals-para'>Client Testimonials</p>
			<p className='check-out-what-para'>Check out what our happy customers say.</p>
			<div className='slider-con'>
				<Slider {...mediaQueryConditionT}>
					{/* Testimonials 1 */}
					{/* <div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690583/v8106anbsnhar5pwgkyb.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'>I recently had the pleasure of working with Insu Creation for my website design, and the experience exceeded all expectations. From the initial consultation to the final launch, their team {readMore1 && "demonstrated unparalleled professionalism, creativity, and technical expertise..."}<span className='testimonals-span-para' onClick={() => setReadMore1(!readMore1)}>{readMore1 ? "Read Less" : "Read more"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Prasant Singh</h1>
								<p className='testimonals-role-para'>Senior Manager, Autonext</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div> */}
					{/* Testimonials 2 */}
					{/* <div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704692606/cdyp2u7wakrhmmheucbn.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'>I highly recommend Insu Creation to anyone in need of a website designer. Their creativity, technical proficiency, and customer-centric approach make them a standout choice in the industry...{readMore2 && "Thank you, Insu Creation, for bringing my vision to life!!"}<span className='testimonals-span-para' onClick={() => setReadMore2(!readMore2)}>{readMore2 ? "Read Less" : "Read More"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>M. Roy</h1>
								<p className='testimonals-role-para'>Convener, DRDO</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div> */}
					{/* Testimonials 3 */}
					{/* <div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704692832/lr28p78yb4jixpzdih4z.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'>What truly sets Insu Creation apart is their dedication to customer satisfaction. They were responsive to my feedback, making revisions promptly and ensuring that every aspect{!readMore3 && "..."}{readMore3 && " of the website met my requirements..."}<span className='testimonals-span-para' onClick={() => setReadMore3(!readMore3)}>{readMore3 ? "Read Less" : "Read more"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Sujit Jha</h1>
								<p className='testimonals-role-para'>Director, Legalsalah</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div> */}
					{/* Testimonials 4 */}
					<div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								{/* <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704693103/tkpqjhqezatsurrputzm.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" /> */}
							</div>
							<p className='testimonals-card-para'>
								Very fast and accurate work done... We are very much satisfied...
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Rumela Ghosh</h1>
								<p className='testimonals-role-para'>Ivory Dental Clinic</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div>
					{/* Testimonials 5 */}
					<div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								{/* <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690583/v8106anbsnhar5pwgkyb.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" /> */}
							</div>
							<p className='testimonals-card-para'> I've been working with Insu Creation for a long time, and I'm thoroughly impressed. Their digital marketing strategies have significantly increased our online visibility and conversions. The team is{!readMore5 && "..."}  {readMore5 && " professional, responsive, and genuinely committed to our success. Thank you so much for you support!..."}<span className='testimonals-span-para' onClick={() => setReadMore5(!readMore5)}>{readMore5 ? "Read Less" : "Read more"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Lakshman Ruidas</h1>
								<p className='testimonals-role-para'>Ok Laxman</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div>
					{/* Testimonials 6 */}
					<div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://whisperinghotelbolpur.in/help/images/logo.png" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'> We recently completed our projects with Insu Creation, and we're thrilled with their top-tier web development, app development, and graphics design services. Their expertise{!readMore6 && "..."}  {readMore6 && "  and commitment to quality have made them a valuable partner, delivering exceptional results every time."}<span className='testimonals-span-para' onClick={() => setReadMore6(!readMore6)}>{readMore6 ? "Read Less" : "Read more"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Sujay Das</h1>
								<p className='testimonals-role-para'>Nishat Group Of Company</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div>
					{/* Testimonials 7 */}
					<div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://www.etampl.in/assets/img/logo.png" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'> I'm the CEO of East Trading and Marketing Private Limited, I was given him a contract for international website designing. He has designed a unique website{!readMore7 && "..."}  {readMore7 && " that I need. I am impressed with his efficient web designing."}<span className='testimonals-span-para' onClick={() => setReadMore7(!readMore7)}>{readMore7 ? "Read Less" : "Read more"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Chinmoy Sinha</h1>
								<p className='testimonals-role-para'>CEO, East Trading and Marketing Private Limited</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div>

					{/* Testimonials 8 */}
					<div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://www.touryatras.com/wp-content/uploads/2024/01/Untitled-design-22.png" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'> I recently had the pleasure of working with Insu Creation for my website design, and the experience exceeded all expectations. From the initial consultation to the final launch, their team{!readMore8 && "..."}  {readMore8 && " demonstrated unparalleled professionalism, creativity, and technical expertise.Insu Creation took the time to understand my business goals and vision, and they translated that into a visually stunning and highly functional website.What truly sets Insu Creation apart is their dedication to customer satisfaction. They were responsive to my feedback, making revisions promptly and ensuring that every aspect of the website met my requirements.I highly recommend Insu Creation to anyone in need of a website designer. Their creativity, technical proficiency, and customer-centric approach make them a standout choice in the industry. Thank you, Insu Creation, for bringing my vision to life"} <span className='testimonals-span-para' onClick={() => setReadMore8(!readMore8)}>{readMore8 ? "Read Less" : "Read more"}</span>
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Santu Chakraborty</h1>
								<p className='testimonals-role-para'>CEO, Tour Yatras</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div>

					{/* Testimonials 9 */}
					<div>
						<div className='testimonals-con-main'>
							<div className='testimonals-logo-cont'>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690548/hgwogfrir1bv9mzs93id.jpg" className='testimonals-logo-con' alt="testimonals-logo-con" />
								<img src="https://themonstergym.com/wp-content/uploads/2023/09/cropped-cropped-Black_Simple_Body_Building_Modern_Gym_Logo_WHITE-removebg-preview.png" className='testimonals-logo-con' alt="testimonals-logo-con" />
							</div>
							<p className='testimonals-card-para'> This is an helpful creation.... You just what you need insu creation solved your problem. Artificial intelligence is awesome... Helpful company...
							</p>
							<div className='testimonals-name-div-cont'>
								<h1 className='testimonals-name-head'>Argha Sinha Ray</h1>
								<p className='testimonals-role-para'>Monster Gym</p>
								<img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704690631/ad27wufxuzywefamvbgo.jpg" className='testimonals-star-con' alt="testimonals-star-con" />
							</div>
						</div>
					</div>


				</Slider>
			</div>
		</div>
	)
}

export default TestimonialsContainer