
import "./index.css"

const TechnologiesContainer=()=>(
    <>
 <section class="technologies-sec">
    <section class="container">
      <h3 class="home-heading home-head-2 technologies-web-in-head"><span class="home-heading__text home-heading__text--our-tech technologies-web-in-head">Technologies</span></h3>
      <div class="tabs">
        <input class="tabs__input" type="radio" name="tabs" id="mob_tab" checked="checked" />
        <label class="tabs__label" for="mob_tab">Mobile App Development</label>
        <div class="tabs__tab">
          <div class="img-card-new">
            <img src={require('../InsuImages/apple.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>IOS</p>
            </div>
          </div>
         <div class="img-card-new">
            <img src={require('../InsuImages/android.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>ANDROID</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/flutter.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>FLUTTER</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/atom.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>REACT NATIVE</p>
            </div>
          </div>
        </div>
        <input class="tabs__input" type="radio" name="tabs" id="web_tab" />
        <label class="tabs__label" for="web_tab">Web Development</label>
        <div class="tabs__tab">
        <div class="img-card-new">
            <img src={require('../InsuImages/angular.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>ANGULAR</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/react.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>REACT</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/node.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>NODEJS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/rubyrails.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>RUBY ON RAILS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/elixir.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>Elixir</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/mysql.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>MYSQL</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/aws.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>AWS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/bootstrap.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>BOOTSTRAP</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/emberjs.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>EMBERJS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/graphql.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>GRAPHQL</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/pheonix.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>PHEONIX</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/elasticsearch.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>ELASTIC SEARCH</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/redis.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>REDIS</p>
            </div>
          </div>
        </div>
        <input class="tabs__input" type="radio" name="tabs" id="digital_tab" />
        <label class="tabs__label" for="digital_tab">Digital Marketing</label>
        <div class="tabs__tab">
         
        <div class="img-card-new">
            <img src={require('../InsuImages/ahrefs.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>AHREFS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/semrush.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>SEMRUSH</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/gkp.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>GKP</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/googleanalytics.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>ANALYTICS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/adwords.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>GOOGLE ADS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/buzzsumo.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>BUZZSUMO</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/hubspot.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>HUBSPOT</p>
            </div>
          </div>
         
        </div>
        <input class="tabs__input" type="radio" name="tabs" id="graphic_tab" />
        <label class="tabs__label" for="graphic_tab">Graphics Design</label>
        <div class="tabs__tab">
         
        <div class="img-card-new">
            <img src={require('../InsuImages/adobeillustrator.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>ILLUSTRATOR</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/sketch.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>SKETCH</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/canva.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>CANVA</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/figma.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>FIGMA</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/adobephotoshop.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>PHOTOSHOP</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/gimp.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>GIMP</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/inkscape.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>INKSCAPE</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/affinitydesigner.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>DESIGNER</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/proofhub.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>PROOFHUB</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/unsplash.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>UNSPLASH</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/adobleexpress.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>ADOBLE EXPRESS</p>
            </div>
          </div>
          <div class="img-card-new">
            <img src={require('../InsuImages/krita.png')} className="technologoes-name-logos" alt="iOS" />
            <div class="img-card-new-btn">
            <p>KRITA</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>

</>
  
)

export default TechnologiesContainer;