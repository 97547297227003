import { useMediaQuery } from "react-responsive"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Header from "../Header"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer"
import "./index.css"

const ProductPackagingPortfolio=()=>{
  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
return(
    <>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
   
<div className="container-product-main-con">
<span className="our-locations-contact-us our-wk web-work-w new-cha-ppp">WE DELIVER OUR BEST</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Product Packaging Portfolio</h1>
    <p className="portfolio-para-rote web-des-w-page mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
  <div className="container-product-main">
 <div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342868/hq71vvhxneyk8vjru8hz.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342958/ot7d9gjcxaacoam7xf3e.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342912/dza1b3jtg7ej1g8roja8.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342819/hejwhaixemfmymq18qxs.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342767/lzejfyvj4jf6lahr7xjo.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342700/zdwx2nqvdhqlfp9uwfyp.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342663/xcnlfc7iflzyumaecafq.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342624/ow6jtgnsg3utmrec5pzr.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342578/tiq8lxgqpjoabdwlklt2.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342531/a8rxqetqznymqwarttnh.jpg" className="product-pack-route-img" alt="Omar" />
</div>
<div id="container-product">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1706342404/z4f9gjohgfz7aobio3a0.jpg" className="product-pack-route-img" alt="Omar" />
</div>
</div>
</div>
<Footer />
</>
)
}
export default ProductPackagingPortfolio
