import React, { useEffect, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import {Link} from "react-router-dom"
import {Helmet} from "react-helmet"
import Header from "../Header"
import Navbar from "../Navbar";
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const DigitalMarketing=()=>{
    const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
     <Helmet>
     <title>Illuminate Your Path to Success: Explore Insu Creation's Digital Innovations</title>
     <meta name="description" content="Embark on a journey to success with Insu Creation's cutting-edge digital solutions. From web development to marketing strategies, we empower businesses to thrive in the digital landscape."/>
     <meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />


     </Helmet>
    <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className='transform-main-cont-2'>
  <div className='transform-main-cont'>
  <div>
    <h1 className="transform-head transform-2-head">Discover Excellence: Insu Creation, Your Top Choice for IT and Outsourcing Solutions in India</h1>
    <p className="transform-para transform-2-para">Insu Creation's proficient team of IT professionals offers comprehensive web application, e-commerce, content development, and digital marketing solutions tailored to your requirements.</p>
    <ul className="transform-right-list-container">
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Innovative Web and E-commerce Solutions by Insu Creation</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para"> Insu Creation: Your Destination for Results-Driven Digital Marketing</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para"> Insu Creation: Leading the Way as the Best SEO Experts in India</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Seamless Onboarding and Flexible Work Hours at Insu Creation</p>
        </div>
      </li>
      <li className="transform-right-list-container-items">
        <div className="list-transform">
        <img
            src="https://img.freepik.com/free-icon/check_318-749749.jpg"
            className="right-check"
            alt="right-check"
          />
          <p className="transform-right-para transform-right-2-para">Insu Creation: Agile Development and Unmatched Customer Support</p>
        </div>
      </li>
    </ul>
    <div className="no-transform-button-container">
    <Link to="/contactus">
    <button type="button" className="our-technologies-stack-btn"><p class="btn-main btn-main-m bg-btn2 lnk bg-btn2-m">Request a Quote<i class="fas fa-chevron-right fa-icon"></i><span class="circle"></span></p>
    </button>
    </Link>
    <Link to="/schedule-meeting">
      <button type="button" className="no-transform-btn-2">Book an appointment now</button>
      </Link>
    </div>
  </div>
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705914676/yl2n8yuca2mscdtjiemk.gif" className="seo-route-img-main digital-mar-img-rote digital-mar-img-rote-mobile" alt="seo-route-img-main" />
 </div>
 </div>
 <div className="digital-marketing-main-container-ro-2">
    <div className="digital-marketing-main-container-ro-1">
        <h1 className="digital-mar-head-name">PROMOTIONAL AD CONTENT</h1>
        <p className="digital-mar-head-para">Insu Creation's promotional ad content strategically showcases products and services, aimed at engaging existing followers as customers while attracting new ones, amplifying brand reach and conversion potential.

</p>
    <div className="new-digi-con">
        <h1 className="digital-mar-head-name digital-mar-head-name-2">CONTENTS WE PRODUCE FOR PROMOTIONS AND ADS</h1>
        <div className="digital-marketing-main-container-ro">
        <div className="wordPress-new-tech-con digital-marget-con-div-main-rote">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705915359/i14mjbayuvao6jjuy0ho.png" className="tech-use-img tech-use-img-trransform digital-market-our-ser-img" alt="" />
    <h1 className="video-content-img-head">VIDEO CONTENT</h1>
    </div>
    </div>

    <div className="wordPress-new-tech-con digital-marget-con-div-main-rote">
                <div className="tech-use-img-container wordpress-our-serv-con digital-marget-con-div-main-rote-mob">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705915886/qwqyjj2dtegdgt4pjgmn.png" className="tech-use-img tech-use-img-trransform digital-market-our-ser-img" alt="" />
    <h1 className="video-content-img-head">TEXT CONTENT</h1>
    </div>
    </div>

    <div className="wordPress-new-tech-con digital-marget-con-div-main-rote">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705915930/wdr4kr7cthvwqpvn0mdw.png" className="tech-use-img tech-use-img-trransform digital-market-our-ser-img" alt="" />
    <h1 className="video-content-img-head">GRAPHIC CONTENT</h1>
    </div>
    </div>

    <div className="wordPress-new-tech-con digital-marget-con-div-main-rote">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705915967/mrf1hwaczysk0do5kpic.png" className="tech-use-img tech-use-img-trransform digital-market-our-ser-img" alt="" />
    <h1 className="video-content-img-head">IMAGE PRODUCTION</h1>
    </div>
    </div>


    <div className="wordPress-new-tech-con digital-marget-con-div-main-rote">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705915997/vksqcsssb4jz5lfubzcs.png" className="tech-use-img tech-use-img-trransform digital-market-our-ser-img" alt="" />
    <h1 className="video-content-img-head">GIF</h1>
    </div>
    </div>

    <div className="wordPress-new-tech-con digital-marget-con-div-main-rote">
                <div className="tech-use-img-container wordpress-our-serv-con">
  <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1705916063/xeqjj3trdra64265z8r7.png" className="tech-use-img tech-use-img-trransform digital-market-our-ser-img" alt="" />
    <h1 className="video-content-img-head">TOTAL CONTENT PACKAGE FOR BRANDING</h1>
    </div>
    </div>

        </div>
    </div>
    </div>
 </div>
 <Footer />
    </>
)}
AOS.init()
export default DigitalMarketing