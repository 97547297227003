import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import Navbar from "../Navbar";
import AOS from "aos";
import {Helmet} from "react-helmet"
import Footer from "../Footer"
import Header from "../Header"
import NavbarInOtherPage from "../NavbarInOtherPage"
import "./index.css"

const TermsAndConditions=()=>{
    useEffect(() => {
        AOS.init({
          
          duration : 2000
        });
      }, []);
    
      const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })
    
    return(
        <>
        <Helmet>
        <title>Insu Creation: Empowering Your Digital Presence with Innovative Solutions</title>
<meta name="description" content="Discover Insu Creation's innovative solutions for enhancing your digital presence. From website design to maintenance and beyond, trust us to elevate your online platform. Explore our services today!"/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

        </Helmet>
       <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
        <div className="payment-policy-route-main-con"> 
            <h1 className="payment-policy-route-heading">Terms and Conditions</h1>
        </div>
        <div className="payment-policy-route-main-con-2">
            <h1 className="payment-policy-route-heading-2">Terms and Conditions</h1>

            <div className="payment-policy-route-con-2">
            <h1 className="payment-policy-route-heading-3 privacy-policy-h">Insu Creation Project Terms:</h1>
                <p className="payment-policy-route-heading-para">➢ Understanding and Quotes: All estimates and quotes provided by Insu Creation are based on our understanding of your requirements within the specified timeframe. Any changes to functionality, including micro-improvements, may result in additional costs. It is essential to clarify our understanding in a face-to-face meeting to ensure alignment.</p>
                <p className="payment-policy-route-heading-para">➢ Acceptance of Quote: By accepting a quote from Insu Creation, you agree to and accept the terms and conditions outlined. Acceptance can be verbal, via email, initiation payment, or signing a quote.</p>
                <p className="payment-policy-route-heading-para">➢ Client Responsibilities: Clients must ensure that all requirements are included in quotes/proposals/estimates and provide clear guidelines. Failure to do so may lead to additional costs if discrepancies arise later.</p>
                <p className="payment-policy-route-heading-para">➢ Task Complexity and Disclosure: Any complexity associated with specific tasks must be disclosed in advance for accurate costing. We rely on clients' full disclosure during the quotation stage and cannot be held responsible for unclear requirements.</p>
                <p className="payment-policy-route-heading-para">➢ Project Timeline: While Insu Creation endeavors to adhere to project timelines, reasonable delays may occur due to redefined or modified functionalities.</p>
                <p className="payment-policy-route-heading-para">➢ Cancellation Policy: Cancellation of orders may incur charges based on the stage of work completion. Refunds, if applicable, are subject to our cancellation policy.</p>
                <p className="payment-policy-route-heading-para">➢ Client Delays: Delays at the client's end may impact project timelines and incur additional costs.</p>
                <p className="payment-policy-route-heading-para">➢ Support and Rework: Bugs reported during the support period or after development completion are addressed at no extra charge. However, rework or design changes post-approval may attract additional fees.</p>
                <p className="payment-policy-route-heading-para">➢ Content Submission and Testing: Timely submission of website/application content is crucial to avoid project delays. Our testing is primarily conducted on specified browsers and devices unless otherwise requested.</p>
                <p className="payment-policy-route-heading-para">➢ Hosting and Server Issues: Additional man-hours required due to server or network-related issues, if the website/application is not hosted by Insu Creation, may be charged separately.</p>
                <p className="payment-policy-route-heading-para">➢ Project Hold and Administration Costs: Stalling a project for over two months may incur administration costs. Clients must inform us in advance to avoid additional charges.</p>
                <p className="payment-policy-route-heading-para">➢ Intellectual Property: Insu Creation retains copyright of software codes. Transfer of codes is possible upon settlement of invoices and for specified client use only.</p>
                <p className="payment-policy-route-heading-para">➢ Third-Party Components and Responsibilities: Unforeseen limitations of third-party components are beyond our control. Costs related to third-party components are not included in quotes.</p>
                <p className="payment-policy-route-heading-para">➢ Client Acknowledgment: Clients acknowledge potential delays due to unforeseen circumstances and integration of third-party software.
Domain and Hosting Charges: Domain registration/renewal and hosting charges are not included unless specified. Separate quotes will be provided for these services.</p>

                <p className="payment-policy-route-heading-para">➢ Client Communication and Updates: Clients are responsible for providing updated email addresses for communication.</p>
                <p className="payment-policy-route-heading-para">➢ SMS API Integration: Client-provided SMS API guidelines are required for timely integration. Bulk SMS integration charges are separate.</p>
                <p className="payment-policy-route-heading-para">➢ Project Timeline Adjustments: Project timelines may be extended due to natural disasters or UI changes, with client approval.</p>
                <p className="payment-policy-route-heading-para">➢ Refund Policy: Payments are non-refundable under any circumstances.
Third-Party API Charges: Charges for third-party API implementation are billed separately.</p>
                <p className="payment-policy-route-heading-para">➢ These terms outline the mutual responsibilities and understandings between Insu Creation and our clients for the successful execution of projects.</p>
               
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Insu Creation Covid-19 Terms and Conditions:</h1>

                <p className="payment-policy-route-heading-para">➢ Member Health: If any Insu Creation team member is impacted by Covid-19, whether infected or having a family member affected, resulting in the inability to work, the client is expected to understand the challenges arising from such unavoidable circumstances.</p>
                <p className="payment-policy-route-heading-para">➢ Third-Party Delays: Delays caused by third parties due to Covid-19 will not be considered as a delay from Insu Creation's end.</p>
                <p className="payment-policy-route-heading-para">➢ Work from Home Scenario: In light of the Work from Home situation, if there are any timeline failures under any circumstances, the client is requested to understand the situation and cooperate with us.</p>
                <p className="payment-policy-route-heading-para">➢ Client Communication: Due to the Work from Home arrangement, the client may be contacted multiple times for seamless communication.</p>

                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Digital Marketing/Strategy Terms</h1>

                <p className="payment-policy-route-heading-para">➢ Minimum Duration: All digital marketing/strategy packages have a minimum duration of 6 months. Subsequently, cancellation is permitted with one month's clear email notice.</p>
                <p className="payment-policy-route-heading-para">➢ Billing and Payment: Packages are billed monthly and payments are due by the 1st of each month. Specific man-hours are allocated monthly, and any unused hours do not carry over. Third-party software or marketing expenses are not included.</p>
                <p className="payment-policy-route-heading-para">➢ Fair Use Policy: Insu Creation operates on a fair use policy, allowing flexibility in good faith. Unused man-hours are not formally rolled over, but adjustments can be made based on changing needs.</p>
                <p className="payment-policy-route-heading-para">➢ Additional Hours and Urgent Tasks: Any extra hours used beyond the allocated monthly quota may be billed separately. Urgent tasks will incur separate charges.
Support Requests: Support requests should be communicated via email to support@insucreation.in.</p>

                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Payment terms:</h1>

                <p className="payment-policy-route-heading-para">➢ Payment Deadline: Payments are due by the 1st of every month. Clients are encouraged to set up direct debit for timely payments.</p>
                <p className="payment-policy-route-heading-para">➢ Late Payment Fee: An administrative fee of Rs. 1500 will be charged for late payments or declined credit card charges.</p>
                <p className="payment-policy-route-heading-para">➢ No Refunds: There are no refunds for any fees related to the digital marketing/strategy package.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Termination:</h1>

<p className="payment-policy-route-heading-para">➢ Minimum Duration: All digital marketing/strategy packages require a minimum commitment of 6 months. Afterward, cancellation is permitted with one month's clear email notice.</p>
<p className="payment-policy-route-heading-para">➢ Termination Rights: Insu Creation reserves the right to terminate the agreement at any time with written notice to the client.</p>
               <h1 className="annual-maintanance-terms-heading">Insu Creation Annual Maintenance Terms:</h1>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Basic Annual Maintenance Package Terms:</h1>
                <p className="payment-policy-route-heading-para">➢ Minimum Duration: All maintenance packages with Insu Creation are for a minimum period of 12 months.</p>
                <p className="payment-policy-route-heading-para">➢ Billing and Payment: Maintenance packages are billed annually but paid monthly via direct debit on the 1st of every month.</p>
                <p className="payment-policy-route-heading-para">➢ Package Inclusions: Packages may include 12 months of basic website hosting, email hosting, and DNS hosting, specifically catering to website maintenance tasks. They do not cover Digital Strategy, Business Analysis, Solution Architecture, SEO, or Digital Marketing work.</p>
                <p className="payment-policy-route-heading-para">➢ Work-Hour Usage: Work-hours are tracked in 15-minute blocks. Support time is utilized monthly for a full website backup, followed by updating CMS and plugin versions and addressing any resulting website fixes. Remaining hours can be utilized for website changes. Additional work hours beyond the package quota will be billed separately.</p>
                <p className="payment-policy-route-heading-para">➢ Fair Use Policy: Insu Creation operates on a fair use policy, allowing flexibility based on changing needs, though unused time is not formally carried over.</p>
                <p className="payment-policy-route-heading-para">➢ Support Requests: Support requests must be emailed to support@insucreation.in. Urgent tasks will be charged separately.</p>
                <p className="payment-policy-route-heading-para">➢ Auto Renewal: The package is auto-renewed annually on its anniversary date.</p>
               

                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Payment terms:</h1>

                <p className="payment-policy-route-heading-para">➢ Credit Card Payments: Monthly maintenance package fees are payable via credit card only. No other payment methods are accepted for monthly maintenance.</p>
                
                <p className="payment-policy-route-heading-para">➢ Billing Schedule: Fees are charged to the client's credit card on file on the 1st day of every month.</p>
                <p className="payment-policy-route-heading-para">➢ Late Payment Fee: An administrative fee of Rs. 2000 will be charged for late payments or declined credit card charges.</p>
                <p className="payment-policy-route-heading-para">➢ Termination for Missed Payments: More than three missed payments in a year may result in termination of the package, with the client required to pay the entire remaining amount immediately.</p>
                <p className="payment-policy-route-heading-para">➢ No Refunds: There are no refunds for fees related to the monthly maintenance package.</p>
                <h1 className="payment-policy-route-heading-3 privacy-policy-h">Termination:</h1>

<p className="payment-policy-route-heading-para">➢ Cancellation of Auto-Renewal: To cancel the auto annual renewal, clients must inform Insu Creation in writing (via email) at least one month prior to the renewal date.</p>
<p className="payment-policy-route-heading-para">➢ Ongoing Term Termination: Clients terminating an ongoing maintenance package must pay the balance amount for the remaining months.</p>
<p className="payment-policy-route-heading-para">➢ Termination Rights: Insu Creation reserves the right to terminate annual maintenance at any time with written notice to the client.</p>
<h1 className="payment-policy-route-heading-3 privacy-policy-h">General Approach</h1>
                <p className="payment-policy-route-heading-para">For general website design and development projects, Insu Creation follows these steps:</p>
                <p className="payment-policy-route-heading-para">➢ Project Initiation: Upon quote acceptance/signoff, an initiation payment is processed to commence the project. A Scoping Workshop may be held to discuss requirements in detail.</p>
                <p className="payment-policy-route-heading-para">➢ Design Phase: Upon receiving brand details, a mock-up is provided for client review within 5 to 10 working days. Design modifications, typically up to 3 to 5, are accommodated within estimates.</p>
                <p className="payment-policy-route-heading-para">➢ HTML Development: Once design is finalized and approved, HTML development commences, followed by setting up the website on the selected CMS if included.</p>
                <p className="payment-policy-route-heading-para">➢ Content Transfer: Content is transferred, and remaining content can be transferred via CMS by client staff with provided instructions.</p>
                <p className="payment-policy-route-heading-para">➢ Client Review and Go-Live: The website is handed over to the client for review, testing, and content changes. A Go-Live date is determined for website launch.</p>
                <p className="payment-policy-route-heading-para">➢ Website Transfer and Payment: After client approval, the website is transferred to the client's domain upon full payment.</p>
                <p className="payment-policy-route-heading-para">➢ Project Timeline and Communication: Timelines are based on allocated man-hours, with regular updates provided via email and/or telephone. Efficient provision of project details upfront is encouraged to minimize additional costs.</p>













<h1 className="payment-policy-route-heading-3 privacy-policy-h">Payment/Refund Terms</h1>
<p className="payment-policy-route-heading-para">➢ Currency and GST: All prices are quoted in Australian dollars and inclusive of GST unless specified.</p>
                <p className="payment-policy-route-heading-para">➢ Invoice Processing: Invoices are processed as per schedule via the client's preferred payment method.</p>
                <p className="payment-policy-route-heading-para">➢ Credit Card Surcharge: A 4% surcharge is applicable for credit card payments.</p>
                <p className="payment-policy-route-heading-para">➢ Milestone Payments: Milestone payments must be made within 5 working days, with final payment prior to website/application/project going live.</p>
                <p className="payment-policy-route-heading-para">➢ Refund Policy: Refunds are subject to the cancellation policy, with refunds applicable before work commencement.</p>
                <p className="payment-policy-route-heading-para">➢ Late Fees and Debt Collection: Late payments incur fees and may result in debt collection if unpaid after 7 days.</p>
                <p className="payment-policy-route-heading-para">➢ Client Communication: Communication is primarily via email, with clients responsible for providing updated contact details.</p>
                 <p className="payment-policy-route-heading-para">➢ Domain and Hosting Payments: Payments for domain name registration renewal, hosting, and third-party products/services are non-refundable and must be paid in full.</p>
                <p className="payment-policy-route-heading-para">➢ Website/Application Transfer: The website/application is transferred upon full payment of invoices and dues to the nominated third-party server.</p>
                <p className="payment-policy-route-heading-para">➢ These terms outline Insu Creation's approach, payment structure, and responsibilities for maintaining transparency and smooth project execution.</p>








<h1 className="payment-policy-route-heading-3 privacy-policy-h">Insu Creation Dispute Resolution and Liability:</h1>

<p className="payment-policy-route-heading-para">➢ Dispute Resolution: In any dispute, Insu Creation reserves the right to charge the client for incurred work and resources. This includes the full cost, without any previously honored discounts. Insu Creation is not liable for damages resulting from misrepresentation or misinformation. The company may refuse service if clients do not align with its principles.</p>
<p className="payment-policy-route-heading-para">➢ Service Guarantees: Insu Creation offers services without guarantees on security or issues affecting data, sales, or reputation. While efforts are made to protect systems and servers, the company is not liable for disruptions due to unforeseen circumstances. Relationships with suppliers and partners are independent.</p>
<p className="payment-policy-route-heading-para">➢ Indemnification: Clients must indemnify and hold Insu Creation harmless from breaches of terms or unauthorized service use. This includes liability for resulting losses, damages, and legal expenses.</p>
<p className="payment-policy-route-heading-para">➢ Force Majeure: Insu Creation is not liable for breaches caused by events beyond its control, such as natural disasters or government actions.</p>

<h1 className="payment-policy-route-heading-3 privacy-policy-h">Hosting</h1>
<p className="payment-policy-route-heading-para">➢ Annual Charges: Hosting fees must be paid annually in advance, unless covered under a maintenance package.</p>
<p className="payment-policy-route-heading-para">➢ Renewal Responsibility: Clients must renew hosting before the expiry date to avoid disruptions. Insu Creation is not liable for issues due to delayed payments.</p>
<p className="payment-policy-route-heading-para">➢ Server Security: While efforts are made to protect servers, Insu Creation is not liable for disruptions caused by external factors.</p>
<p className="payment-policy-route-heading-para">➢ Client Responsibility: Clients are responsible for hosting content and security, including ensuring scripts are free from malicious content.</p>


<h1 className="payment-policy-route-heading-3 privacy-policy-h">Cancellation/Termination:</h1>
<p className="payment-policy-route-heading-para">➢ Cancellation Process: Hosting cancellation requests must be submitted in writing at least one month before the expiry date.</p>
<p className="payment-policy-route-heading-para">➢ Disabling for Non-Payment: Failure to renew hosting may result in service disabling, with additional charges for reactivation.</p>
<p className="payment-policy-route-heading-para">➢ Data Deletion: Cancelled hosting may lead to data deletion without prior notice.</p>

<h1 className="payment-policy-route-heading-3 privacy-policy-h">Emails:</h1>
<p className="payment-policy-route-heading-para">➢ Client Responsibility: Clients must download emails periodically and adhere to email box quotas.</p>
<p className="payment-policy-route-heading-para">➢ Content Compliance: Inappropriate content or spamming via email hosting may result in immediate action, including data deletion.</p>



<h1 className="payment-policy-route-heading-3 privacy-policy-h">Domains:</h1>
<p className="payment-policy-route-heading-para">➢ Payment and Renewal: Domain registrations and renewals must be paid in advance to prevent service disruptions.</p>
<p className="payment-policy-route-heading-para">➢ Expiration Responsibility: Insu Creation is not responsible for domain expiry due to non-payment.</p>


<h1 className="payment-policy-route-heading-3 privacy-policy-h">Intellectual Property:</h1>
<p className="payment-policy-route-heading-para">➢ Ownership: Insu Creation owns website content unless specified. Clients may request project-related copyrights at an additional cost after full payment.</p>
<p className="payment-policy-route-heading-para">➢ Restrictions: Copyrights do not permit code reuse for other websites or commercial purposes without permission. Insu Creation's CMS and trademarks are proprietary and cannot be transferred or used without written permission.</p>


            </div>
        </div>
        <Footer />
        </>
    )
}

export default TermsAndConditions