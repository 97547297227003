import React, { useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import AOS from "aos"
import Header from "../Header"
import Navbar from "../Navbar";
import {Helmet} from "react-helmet"
import NavbarInOtherPage from "../NavbarInOtherPage"
import DetailsForm from "../DetailsForm";

import Footer from "../Footer"

import "./index.css"

const OurPartners=()=>{

  useEffect(() => {
    AOS.init({
      
      duration : 2000
    });
  }, []);

  const mediaQueryCondition=useMediaQuery({ query: '(max-width:1024px)' })


  return(
    <>
    <Helmet>
    <title>Insu Creation: Crafting Tailored Solutions for Your Unique Needs</title>
<meta name="description" content="Discover comprehensive insurance solutions tailored to your needs with Insu Creation. Our expertise ensures peace of mind while safeguarding your assets. Partner with us for personalized service and reliable protection."/>
<meta name="keywords" content="Insu Creation, SEO, digital solutions, web development, marketing strategies, branding, SEO, social media marketing, e-commerce, content creation, online presence, business growth, digital innovation, customer engagement, website optimization, digital transformation, digital agency, online marketing, digital strategy, digital consultancy, digital services, digital expertise" />

    </Helmet>
     <Header />
    {mediaQueryCondition?<Navbar />:<NavbarInOtherPage />}
    <DetailsForm />
    <div className="results-partnersship-container-main">
    <div className="results-partnersship-container-1">
        <div className="results-partnersship-container">
            <h1 className="results-partnersship-heading">Results & Partnerships</h1>
            <p className="results-partnersship-para">At Insu Creation, our commitment to excellence is bolstered by strategic partnerships, resulting in exceptional outcomes. By fostering strong alliances, we deliver tailored solutions and unparalleled service to our clients. Together with our trusted partners, we strive for excellence in every project, ensuring mutual success and satisfaction.</p>
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708842653/i6sjk6eyz74aenfbjruo.gif" className="our-partners-image" alt="" />
    </div>
    </div>
    <div className="results-partnersship-container-main">
    <div className="results-partnersship-container-1">
        <div className="results-partnersship-container">
            <hr className="hori-line" />
            <h1 className="results-partnersship-heading">Benefits of working with us</h1>
            <p className="results-partnersship-para">Partnering with Insu Creation offers a myriad of benefits. From expert guidance to seamless collaboration, we provide tailored solutions to meet your needs. Enjoy access to cutting-edge technology, unparalleled support, and a dedicated team committed to your success. With us, you're not just a client, you're a valued partner.</p>
        </div>
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708842973/jccdc9gu0iyolgen8pmd.jpg" className="our-partners-image-1" alt="" />
    </div>
    </div>
        <div className="information-partners-container">
       <h1 className="information-partners-heading">Information partners</h1>
       <p className="information-partners-para">While we are at the forefront of and specialize in design-build, we are very familiar with a number of delivery methods and are confident we can find the process that will best help you meet your goals.</p>
       <div className="information-partners-container-list">
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708843836/m4dqct4t1xawjqxza6ig.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844118/mszj1sjw0wb1zflnt2zm.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844159/gulbiebwxjjzqesst6i6.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844200/vgjlmo3srcjcqlpaaid0.svg" className="partners-img" alt="" />

        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844331/fgbqqdymq1yg7yz04erp.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844367/fdgoorsfj1n6ymrnfhtc.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844159/gulbiebwxjjzqesst6i6.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844417/vz5b56r4zipt8mbm3ijk.svg" className="partners-img" alt="" />

        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844470/wq9kehqc3ktpwqzeflru.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844512/kzdvgr3c7dvztnx0h1ve.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844553/izdtyuasmcrbl44ukzb1.svg" className="partners-img" alt="" />
        <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1708844585/dcb2fljv5eqlqgwtahjh.svg" className="partners-img" alt="" />

       </div>
       </div>
    <Footer />
    </>
  )
}

export default OurPartners