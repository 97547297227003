import "./index.css"

const ContactUsForm=()=>(
    <div className="contact-us-main-details-container-1">
        <div className="contact-us-main-details-container">
            <div className="contact-us-route-container">
                <h1 className="contact-us-route-heading hd-2-c">Contact us</h1>
                <div className="contact-us-left-con">
                    <p className="contact-us-route-add-para"><span className="contact-us-route-address-heading">Address:</span>
                    Bohichberia Bus Stand,Bohichberia, Tamluk, West Bengal 721649</p>
                        <p className="contact-us-route-add-para"><span className="contact-us-route-address-heading">Phone:</span>
                        (+91) 9547361869</p>
                    <p className="contact-us-route-address-heading hd-3-c"><span className="contact-us-route-address-heading">Email:</span>
                        insucreation@gmail.com</p>
                    <p className="contact-us-route-add-para"><span className="contact-us-route-address-heading">Website</span>
                    <a href="https://insucreation.in" className="contact-us-route-add-para" target="blanck">www.insucreation.in</a></p>
                </div>
            </div>
            <div className="get-in-touch-contact-us-container">
                <h1 className="get-in-touch-contact-route hd-2-c">Get in touch</h1>
                <p className="contact-us-route-add-para hd-c-4 contact-para-m">We will catch you as early as we receive the message</p>
                <form>
                    <div className="input-contact-us-container">
                    <input type="text" placeholder="Enter name" className="contact-us-enter-name" />
                    <input type="email" placeholder="Enter email" className="contact-us-enter-name" />
                    </div>
                    <div className="input-contact-us-container">
                    <input type="number" placeholder="Mobile number" className="contact-us-enter-name" />
                    <select type="select" name="Domain" className="contact-us-enter-name contact-us-enter-name-sel">
                        <option value="" disabled selected hidden>Select Requirement</option>
                        <option value="Logo Design" class="select-req-item">Frontend Web Development</option>
                        <option value="WordPress Web Development" class="select-req-item">WordPress Web Development</option>
                        <option value="Backend Web Development" class="select-req-item">Backend Web Development</option>
                        <option value="SEo" class="select-req-item">SEO</option>
                        <option value="Social Media Marketing" class="select-req-item">Social Media Marketing</option>
                        <option value="Digital Marketing Funnel" class="select-req-item">Digital Marketing Funnel</option>
                        <option value="Local SEO Services" class="select-req-item">Local SEO Services</option>
                        <option value="Technical SEO Services" class="select-req-item">Technical SEO Services</option>
                        <option value="Google Analytics" class="select-req-item">Google Analytics</option>
                        <option value="React Native" class="select-req-item">React Native App Development</option>
                        <option value="Flutter App Development" class="select-req-item">Flutter App Development</option>
                        <option value="Landing Page Development" class="select-req-item">Landing Page Development</option>
                        <option value="Article Writing" class="select-req-item">Article Writing</option>
                        <option value="Social Media Content" class="select-req-item">Social Media Content</option>
                        <option value="Video Ads for Ecommerce" class="select-req-item">Video Ads for Ecommerce</option>
                        <option value="Product Description Writing" class="select-req-item">Product Description Writing</option>
                        <option value="Digital Marketing Funnel" class="select-req-item">Digital Marketing Funnel</option>
                        <option value="Local SEO Services" class="select-req-item">Local SEO Services</option>
                        <option value="Technical SEO Services" class="select-req-item">Technical SEO Services</option>
                        <option value="Blog Content Writing" class="select-req-item">Blog Content Writing</option>
                        <option value="Graphic Design" class="select-req-item">Graphic Design</option>
                        <option value="Complete Branding" class="select-req-item">Complete Branding</option>
                        <option value="Logo Designing" class="select-req-item">Logo Designing</option>
                        <option value="Business Presentation" class="select-req-item">Business Presentation</option>
                        <option value="2D & 3D Animated Videos" class="select-req-item">2D & 3D Animated Videos</option>
                    </select>
                    </div>
                    <textarea placeholder="Enter your message" className="text-area-msg" />
                    <div className="checkbox-i-agree-container">
                        <input type="checkbox" className="contact-us-checkbx" />
                        <p className="i-agree-terms-para">I agree to the <span className="terms-conditions-span">Terms & Conditions</span> of Business Name.</p>
                    </div>
                    <button type="submit" className="contact-us-btn-form">Submit</button>
                    <p className="we-hate-spam-para">We hate spam, and we respect your privacy.</p>
                </form>
            </div>
        </div>
    </div>
)

export default ContactUsForm