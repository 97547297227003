import { useMediaQuery } from "react-responsive"
import Navbar from "../Navbar"
import NavbarInOtherPage from "../NavbarInOtherPage"
import Header from "../Header"
import DetailsForm from "../DetailsForm"
import Footer from "../Footer";
import Projects from "../Projects/Projects"
import "./indexwebsite.css"

const WebsiteDesignPortfolio = () => {
  const mediaQueryCondition = useMediaQuery({ query: '(max-width:1024px)' })
  return (
    <>
      <Header />
      {mediaQueryCondition ? <Navbar /> : <NavbarInOtherPage />}
      <DetailsForm />
      {/* <div className="website-portfolio-chn-container-mm">
    <span className="our-locations-contact-us our-wk web-work-w">OUR WORK</span>
    <h1 className="portfolio-head-rote mob-web-port-v">Website Portfolio</h1>
    <p className="portfolio-para-rote web-des-w-page mob-web-port-v-para">There are 6 different types of logos and Insu Creation provides unique designing in any logo type based on your requirements.</p>
       
    <div className="website-portfolio-chn-container">
    <div class="container-chn-n">
  <img src={require("../InsuImages/bitm.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
    <div class="text-chn-nn">
      <h1 className="bitm-headi">Bitm</h1>
      <p className="bitm-headi-web">College</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/cps budbud.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Cps Budbud</h1>
      <p className="bitm-headi-web">School</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/cps santiniketan.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Cps Santiniketan</h1>
      <p className="bitm-headi-web">School</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/design trivo.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Design Trivo</h1>
      <p className="bitm-headi-web">Interior</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/hotel dc star.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Hotel Dc Star</h1>
      <p className="bitm-headi-web">Hotel</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/prospect education.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Prospect Education</h1>
      <p className="bitm-headi-web">Education Consultant</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/swarna interior.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Swarna Interior</h1>
      <p className="bitm-headi-web">Interior</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/tihs.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Tihs</h1>
      <p className="bitm-headi-web">Education Consultant</p>
    </div>
  </div>
</div>
<div class="container-chn-n">
  <img src={require("../InsuImages/tour yatra.jpg")} alt="Avatar" class="image-chn-n" />
  <div class="overlay-chn-n">
  <div class="text-chn-nn">
      <h1 className="bitm-headi">Tour Yatra</h1>
      <p className="bitm-headi-web">Travel Blogging</p>
    </div>
  </div>
</div>
    </div>
    </div> */}
      <Projects />
      <Footer />
    </>
  )
}

export default WebsiteDesignPortfolio