import {motion} from "framer-motion"
import "./index.css"

const WeWorkWith=()=>(
	<div className="industries-main-cont-work-main">
	<div className="industries-main-cont-work">
		<div className="industries-cont-left-cont">
			<h1 className="industries-we-work-head">INDUSTRIES WE WORKED FOR</h1>
			<p className="helping-business-para">Helping Businesses in All Domains</p>
			<p className="successfully-delivered-para">Successfully delivered digital products and solutions to a wide variety of industries.</p>
		</div>
		<div className="industries-work-right-cont">
		<motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704696502/za6ndyfs88fwncff5fz3.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Social Media</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704865923/h89dstxnurtsrt9lqtj9.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Manufacturing Industry</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866011/by2nfi20joxc1vhktth4.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Online Shopping</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866075/bez1gcsciyddcboozsh0.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Dental Health Care</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866134/som7tjtxhobnz1ln2adb.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Hospitality</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866199/nralkv207hqvoubpu3un.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Enterprise Sercvice</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866276/m7i9hqhxtfgh2ea98yea.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Educational Industry</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866330/pq64ltbagbfacer8xxpv.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Carporate</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866401/f7wcwnaxdaqj7h4od1db.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Health Care</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866464/hkx43zuijnrcg7mjvgvz.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Management</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866530/jzmxn3negutjanhzbpqi.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Food & Restaurant</p>
	  </motion.div>
	  <motion.div whileHover={{scale:1.05}} data-aos="fade-right" className="cont-indus">
			<div className="cont-industries-cont">
    <img src="https://res.cloudinary.com/dlj7gj1po/image/upload/v1704866581/tuyfrayiuqkhzvawn5im.png" alt="Industries" className="image-indus" />
	</div>
	<p className="social-media-para">Consultancy</p>
	  </motion.div>
	  </div>
	</div>
	</div>
)

export default WeWorkWith